// @/dateAndTimeParsing.js

export const formatDate = (iso_date) => {
  return new Date(iso_date).toLocaleDateString('de-AT');
};

export const formatTime = (iso_date) => {
    return new Date(iso_date).toLocaleTimeString('de-AT');
};

export const formatDateTime = (iso_date) => {
    return new Date(iso_date).toLocaleString('de-AT');
}

export const formatDateTimeForInput = (iso_date) => {
    return new Date(iso_date).toISOString();
}

export default { 
    formatDate, 
    formatTime, 
    formatDateTime, 
    formatDateTimeForInput,
};
<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12 col-xxl-8 mx-auto d-block">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center" style="height: 100%;">
              <h6 class="mb-0">Statements of cost</h6>
              <button class="btn btn-sm bg-gradient-primary my-auto ms-auto" type="button" name="button"
                @click="$router.push('/statements-of-cost/new')">Add new</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 col-xxl-8 mx-auto d-block">
        <div class="col-12">
          <div class="mb-4 card">
            <div class="pb-0 card-header">
              <h6>Statements of Cost</h6>
            </div>
            <div class="px-0 pt-0 pb-2 card-body">
              <div class="p-0 table-responsive">
                <table class="table mb-0 align-items-center">
                  <thead>
                    <tr>
                      <th v-for="(header, index) of headers" :key="index" :class="index >= 1 ? 'text-center ps-2' : ''"
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        {{ header }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(
                { id, name, amount, created_at, status }, index
              ) in lists" :key="index" class="cursor-pointer" @click="rowClicked(id)">
                      <td>
                        <div class="px-3 py-1 d-flex">
                          <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-sm">{{ name }}</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p class="text-center mb-0 text-sm font-weight-bold">
                          € {{ amount / 100 }}
                        </p>
                      </td>
                      <td class="text-sm align-middle">
                        <p class="text-center mb-0 text-sm font-weight-bold">
                          {{ formatDateTime(created_at) }}
                        </p>
                      </td>
                      <td class="text-sm align-middle">
                        <p class="text-center mb-0 text-sm font-weight-bold">
                          <span v-if="status == 'accepted'" class="badge badge-sm badge-success">ACCEPTED</span>
                          <span v-if="status == 'pending'" class="badge badge-sm badge-warning">PENDING</span>
                          <span v-if="status == 'rejected'" class="badge badge-sm badge-danger">REJECTED</span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateTime } from "@/dateAndTimeParsing";

export default {
  name: "StatementOfCostList",
  components: {
  },
  methods: {
    formatDateTime(dateTime) {
      return formatDateTime(dateTime);
    },
    rowClicked(id) {
      this.$router.push({ name: 'StatementOfCostDetails', params: { id: id } });
    }
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    lists: {
      type: Array,
      default: () => [],
      id: String,
      name: String,
      amount: Number,
      created_at: String,
      status: String,
    },
  },
};
</script>
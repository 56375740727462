<template>
  <div class="mt-4 card cursor-pointer" @click="$router.push('/profile/' + user.id)">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <user-avatar :id="user.avatar" class="me-3 shadow border-radius-lg w-100" alt="avatar image"
            border-radius="lg" style="max-width: 100%; height: auto;" />
        </div>
      </div>
      <div class="row">
        <div class="my-auto col-12">
          <h6 class="mb-0 text-sm">{{ user.displayname }}</h6>
          <div class="d-flex align-items-center">
            <span class="text-secondary text-xs">{{ user.firstname }} {{ user.lastname }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/UserAvatar.vue";

export default {
  name: "ProfileCard",
  components: {
    UserAvatar,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="overflow-scroll card">
          <div class="card-body d-flex">
            <OrganiserList v-for="({ displayname, avatar, as }, index) of apiData.organisers" :key="index" :id="avatar"
              :name="displayname" :role="as" />
            <div class="text-center col-lg-1 col-md-2 col-sm-3 col-4" @click="editOrganisers()">
              <a href="javascript:;" class="avatar avatar-lg border-1 rounded-circle bg-gradient-success">
                <i class="text-white fas fa-edit"></i>
              </a>
              <p class="mb-0 text-sm" style="margin-top: 6px">Edit organisers</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-12 col-md-6 col-xl-5 mt-md-0">
        <event-info-card title="Event Details" :description=apiData.description :starts_at=apiData.starts_at
          :ends_at=apiData.ends_at :presale_starts_at=apiData.presale_starts_at :presale_ends_at=apiData.presale_ends_at
          :cancellation_details=apiData.cancellation_details :action="{
              route: 'javascript:;',
              tooltip: 'Edit event details'
            }" />
      </div>
      <div class="mt-4 col-12 col-md-6 col-xl-3 mt-md-0">
        <SocialInclusionCard title="Social Inclusion"
          description="What matters is the people who are sparked by it. And the people who are liked."
          :badge="{ text: 'FULLY INCLUSIVE', color: 'success' }" />
        <LocationCard :event_id="id" :event_name="apiData.name" title="Locations and Weather"
          description="Uni Wien Office, Universitätsring 1, 1010 Wien, Austria"
          :badge="{ text: 'BOOKED', color: 'success' }" :items=apiData.locations @update="getEvent()" />
        <ItemsCard :event_id="this.id" />
        <BannerCard title="Banner move to Details > Media"
          description="What matters is the people who are sparked by it. And the people who are liked."
          :badge="{ text: 'Generate Banner', color: 'warning' }" />
      </div>
      <div class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
        <default-statistics-card title="Attendees" count="59/60" :percentage="{
              color: 'success',
              value: '+55',
              label: 'since yesterday'
            }" menu="6 May - 7 May" :dropdown="[
              {
                label: 'Last 7 days',
                route: 'https://creative-tim.com/'
              },
              {
                label: 'Last week',
                route: '/pages/widgets'
              },
              {
                label: 'Last 30 days',
                route: '/'
              }
            ]" />
        <default-statistics-card title="Income" count="€ 493,00" :percentage="{
              color: 'success',
              value: '+55%',
              label: 'since yesterday'
            }" menu="6 May - 7 May" :dropdown="[
              {
                label: 'Last 7 days',
                route: 'https://creative-tim.com/'
              },
              {
                label: 'Last week',
                route: '/pages/widgets'
              },
              {
                label: 'Last 30 days',
                route: '/'
              }
            ]" />
        <StatementOfCostCard :event_id="id" />
        <OptionsCard :event_id="id" />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-8">
        <div class="mt-4 card">
          <div class="p-3 card-header">
            <div class="row">
              <div class="col-md-6">
                <h6 class="mb-0">To do list</h6>
              </div>
              <div class="col-md-6 d-flex justify-content-end align-items-center">
                <button type="button" class="btn btn-outline-primary btn-sm">Add TODO</button>
              </div>
            </div>
            <hr class="mb-0 horizontal dark" />
          </div>
          <div class="p-3 pt-0 card-body">
            <ul class="list-group list-group-flush" data-toggle="checklist">
              <todo-item title="Check status" checkbox-id="flexCheckDefault" dropdown-table-id="dropdownTable2"
                date="24 March 2019" project-id="2414_VR4sf3#" company="Creative Tim" :action="[
              {
                label: 'Assign to me',
                route: 'javascript:;',
              },
              {
                label: 'Edit',
                route: '/',
              },
              {
                label: 'Delete',
                route: 'javascript:;',
              },

            ]" />

              <todo-item title="Management discussion" checkbox-id="flexCheckDefault1"
                dropdown-table-id="dropdownTable3" date="24 March 2019" project-id="4411_8sIsdd23" company="Apple"
                :action="[
              {
                label: 'Assign to me',
                route: 'javascript:;',
              },
              {
                label: 'Edit',
                route: '/',
              },
              {
                label: 'Delete',
                route: 'javascript:;',
              },
            ]" checked />

              <todo-item title="New channel distribution" checkbox-id="flexCheckDefault2"
                dropdown-table-id="dropdownTable" date="25 March 2019" project-id="827d_kdl33D1s" company="Slack"
                :action="[
              {
                label: 'Assign to me',
                route: 'javascript:;',
              },
              {
                label: 'Edit',
                route: '/',
              },
              {
                label: 'Delete',
                route: 'javascript:;',
              },
            ]" checked />

              <todo-item title="IOS App development" checkbox-id="flexCheckDefault3" dropdown-table-id="dropdownTable1"
                date="26 March 2019" project-id="88s1_349DA2sa" :action="[
              {
                label: 'Assign to me',
                route: 'javascript:;',
              },
              {
                label: 'Edit',
                route: '/',
              },
              {
                label: 'Delete',
                route: 'javascript:;',
              },
            ]" company="Facebook" />
            </ul>
          </div>
        </div>
      </div>
      <!--       <div class="col-12 col-lg-8">
        <post-card />
      </div> -->
      <div class="mt-4 col-12 col-lg-4">
        <timeline-list title="Timeline">
          <timeline-item color="success" icon="bell-55" title="$2400 Design changes" date-time="22 DEC 7:20 PM"
            description="People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of."
            :badges="['design']" />
          <TimelineItem color="danger" icon="html5" title="New order #1832412" date-time="21 DEC 11 PM"
            description="People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of."
            :badges="['order', '#1832412']" />
          <TimelineItem color="info" icon="cart" title="Server payments for April" date-time="21 DEC 9:34 PM"
            description="People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of."
            :badges="['server', 'payments']" />
          <TimelineItem color="warning" icon="credit-card" title="New card added for order #4395133"
            date-time="20 DEC 2:20 AM"
            description="People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of."
            :badges="['Card', '#4395133', 'Priority']" />
          <TimelineItem color="primary" icon="key-25" title="Unlock packages for development" date-time="18 DEC 4:54 AM"
            description="People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of."
            :badges="['Development']" />
          <TimelineItem color="success" icon="archive-2" title="New message unread" date-time="16 DEC"
            description="People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of."
            :badges="['Message']" />
          <TimelineItem color="info" icon="check-bold" title="Notifications unread" date-time="15 DEC"
            description="People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of." />
          <TimelineItem color="warning" icon="box-2" title="New request" date-time="14 DEC"
            description="People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of."
            :badges="['Request', 'Priority']" />
          <TimelineItem color="dark" icon="box-2" title="Controller issues" date-time="13 DEC"
            description="People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of."
            :badges="['Controller']" />
        </timeline-list>
      </div>
    </div>

  </div>
</template>

<script>
import EventInfoCard from "./EventInfoCard.vue";
//import PostCard from "./PostCard.vue";
import OrganiserList from "./OrganiserList.vue";
//import TeamProfileCard from "./TeamProfileCard.vue";
//import EventCard from "./EventCard.vue";
import TodoItem from "./TodoItem.vue";
//import InfoCard from "./InfoCard.vue";
import SocialInclusionCard from "./SocialInclusionCard.vue";
import LocationCard from "./LocationCard.vue";
import ItemsCard from "./ItemsCard.vue";
import StatementOfCostCard from "./StatementOfCostCard.vue";
import DefaultStatisticsCard from "./DefaultStatisticsCard.vue";
import TimelineList from "./TimelineList.vue";
import TimelineItem from "./TimelineItem.vue";
import OptionsCard from "./OptionsCard.vue";
import BannerCard from "./BannerCard.vue";

import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";

import { makeRequest } from '@/api';
//import {  readEventSchema  } from "../../../shared/schema/index.js";

export default {
  name: "OverviewTab",
  components: {
    //   TeamProfileCard,
    //    PostCard,
    OrganiserList,
    //   EventCard,
    TodoItem,
    EventInfoCard,
    //   InfoCard,
    ItemsCard,
    StatementOfCostCard,
    DefaultStatisticsCard,
    TimelineList,
    TimelineItem,
    LocationCard,
    OptionsCard,
    BannerCard,
    SocialInclusionCard
  },
  data() {
    return {
      apiData: [],
      showMenu: false,

    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    this.getEvent();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    async getEvent() {
      try {
        const response = await makeRequest("GET", `/events/${this.id}`);
        console.log(response);
        this.apiData = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    editOrganisers() {
      this.$router.push({
        name: "EditOrganisers",
        params: { id: this.id, event_name: this.apiData.name },
      });
    },
  },
};
</script>

// @/api.js
import axios from 'axios';
import dotenv from 'dotenv';
dotenv.config({ path: '../.env' });

export const baseURL = process.env.VUE_APP_API_URL;
export const oidcURL = process.env.VUE_APP_BACKEND_OIDC_URL;
export const CSSOAllowedURLs = process.env.VUE_APP_CSSO_ALLOWED_URLS ? process.env.VUE_APP_CSSO_ALLOWED_URLS.split(',') : [];

export const makeRequest = async (method = 'get', endpoint, data = undefined, content = 'application/json', blob = false) => {
  try {
    const axiosConfig = {
      baseURL,
      headers: {
        'Content-Type': content,
        'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
      },
    };
    if (blob) {
      axiosConfig.responseType = 'blob';
    } 
    const api = axios.create(axiosConfig);

    console.log(baseURL + endpoint, method, data)
    const response = await api({
      method,
      url: baseURL + endpoint,
      data,
    });

    if (response.status === 401) {
      localStorage.removeItem('jwtToken');
      this.$router.push('/auth/signin');
    }

    return response;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem('jwtToken');
      this.$router.push('/auth/signin');
      //window.location.href = "/auth/signin";
    }
    //throw error; // Re-throw the error to handle it at the component level if needed
  }
};

export default {makeRequest};
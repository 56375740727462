<template>
  <div class="container-fluid">
    <div class="mt-4 page-header min-height-300 border-radius-xl" :style="{
      backgroundImage:
        'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
      backgroundPositionY: '50%',
    }">
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
  </div>
  <div class="py-4 container-fluid">
    <section class="py-3">
      <div class="row">
        <div class="text-left col-md-8 me-auto">
          <h5>Stacks of ESNcards</h5>
          <p>
            The stacks need to stay in order, for the system to correctly assign the number of the card to the user.
            <i>Please note: This feature is still under development. Therefore, you can only modify stacks via the
              API.</i>
          </p>
        </div>
      </div>
      <div class="mt-2 row mt-lg-4">
        <div v-for="(stack, index) of this.stacks" :key="index" class="mb-4 col-lg-4 col-md-6">
          <StackCard :id="stack.id" :title="stack.name" :description="stack.description"
            :date-time="this.formatDate(stack.created_at)" :total="stack.total_items" :used="stack.used_items"
            :shipping="stack.shipping" :pickup="stack.pickup" @update="this.fetchStacks()" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import StackCard from "./components/StackCard.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import { formatDate } from "@/dateAndTimeParsing";
import { makeRequest } from "@/api";

export default {
  name: "ESNcardShopStacks",
  components: {
    StackCard,
  },
  data() {
    return {
      showMenu: false,
      stacks: [],
    };
  },
  methods: {
    formatDate,
    async fetchStacks() {
      const response = await makeRequest("GET", "/office/stacks");
      this.stacks = response.data;
    }
  },
  created() {
    this.fetchStacks();
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>

<template>
  <!-- changelog -->
  <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.1861 2.1389C10.5395 2.35495 10.6508 2.81658 10.4348 3.16999C10.278 3.42644 10.3173 3.7569 10.5298 3.96944L10.6277 4.06732C11.2164 4.65598 11.4334 5.52076 11.1926 6.31763C11.0727 6.71413 10.6541 6.93839 10.2576 6.81854C9.86114 6.69869 9.63688 6.28011 9.75673 5.88361C9.83761 5.61603 9.76472 5.32565 9.56706 5.12798L9.46918 5.0301C8.76666 4.32758 8.63678 3.23528 9.15499 2.38761C9.37104 2.03421 9.83267 1.92285 10.1861 2.1389Z" fill="#1C274C"/>
<path d="M17.6891 4.72137C18.0952 4.8026 18.3586 5.19772 18.2774 5.60389L18.1334 6.32383C17.9353 7.31464 17.2214 8.12331 16.2628 8.44284C15.8149 8.59215 15.4813 8.97002 15.3887 9.433L15.2447 10.1529C15.1635 10.5591 14.7684 10.8225 14.3622 10.7413C13.956 10.6601 13.6926 10.2649 13.7738 9.85876L13.9178 9.13883C14.116 8.14801 14.8299 7.33934 15.7885 7.01981C16.2364 6.87051 16.57 6.49264 16.6626 6.02965L16.8065 5.30972C16.8878 4.90355 17.2829 4.64014 17.6891 4.72137Z" fill="#1C274C"/>
<path d="M21.4085 13.5592C21.0468 13.4011 20.6262 13.4675 20.3308 13.7294C19.5215 14.447 18.3439 14.5663 17.4072 14.0255L17.1943 13.9026C16.8356 13.6955 16.7127 13.2368 16.9198 12.8781C17.1269 12.5194 17.5856 12.3965 17.9443 12.6036L18.1572 12.7265C18.5347 12.9444 19.0094 12.8964 19.3356 12.6071C20.0684 11.9573 21.112 11.7925 22.0094 12.1849L22.3009 12.3123C22.6804 12.4783 22.8536 12.9204 22.6876 13.3C22.5217 13.6795 22.0795 13.8526 21.7 13.6867L21.4085 13.5592Z" fill="#1C274C"/>
<path d="M6.92761 3.94057C7.13708 3.7311 7.47669 3.7311 7.68616 3.94057C7.89563 4.15004 7.89563 4.48965 7.68616 4.69912C7.47669 4.90859 7.13708 4.90859 6.92761 4.69912C6.71814 4.48965 6.71814 4.15004 6.92761 3.94057Z" fill="#1C274C"/>
<path d="M12.9157 7.15688C12.7062 6.94741 12.3666 6.94741 12.1571 7.15688C11.9476 7.36634 11.9476 7.70596 12.1571 7.91543C12.3666 8.1249 12.7062 8.1249 12.9157 7.91543C13.1251 7.70596 13.1251 7.36634 12.9157 7.15688Z" fill="#1C274C"/>
<path d="M17.1571 10.1569C17.3666 9.94741 17.7062 9.94741 17.9157 10.1569C18.1251 10.3663 18.1251 10.706 17.9157 10.9154C17.7062 11.1249 17.3666 11.1249 17.1571 10.9154C16.9476 10.706 16.9476 10.3663 17.1571 10.1569Z" fill="#1C274C"/>
<path d="M19.8165 15.3131C19.6071 15.1037 19.2674 15.1037 19.058 15.3131C18.8485 15.5226 18.8485 15.8622 19.058 16.0717C19.2674 16.2811 19.6071 16.2811 19.8165 16.0717C20.026 15.8622 20.026 15.5226 19.8165 15.3131Z" fill="#1C274C"/>
<path d="M7.47151 20.2412C5.20995 20.9868 4.01839 21.2988 3.35924 20.6397C2.62936 19.9098 3.09026 18.5271 4.01207 15.7617L5.70156 10.6932C6.33358 8.79714 6.70486 7.68328 7.32442 7.23145L7.31921 7.25726C7.31199 7.29317 7.30156 7.34564 7.28842 7.41315C7.26215 7.54813 7.22504 7.74334 7.18122 7.98651C7.09363 8.47253 6.97894 9.15173 6.87035 9.92562C6.65521 11.4588 6.45595 13.4178 6.5586 14.9832C6.62072 15.9305 6.81779 17.1055 6.99367 18.0237C7.08243 18.4871 7.16741 18.8932 7.23023 19.1837C7.26166 19.329 7.2876 19.4456 7.30576 19.5263L7.3269 19.6194L7.47151 20.2412Z" fill="#1C274C"/>
<path d="M13.0389 18.3863L13.3057 18.2974C15.6038 17.5313 16.7528 17.1483 16.9667 16.2421C17.1807 15.3358 16.3242 14.4794 14.6114 12.7665L12.9221 11.0773L12.9174 11.0921C12.8978 11.1539 12.8694 11.2449 12.8352 11.3593C12.7666 11.5885 12.6751 11.9094 12.5837 12.2752C12.3967 13.024 12.227 13.8971 12.227 14.5551C12.227 15.213 12.3967 16.0861 12.5837 16.835C12.6751 17.2008 12.7666 17.5217 12.8352 17.7509C12.8694 17.8653 12.8978 17.9563 12.9174 18.0181C12.9272 18.049 12.9348 18.0726 12.9398 18.088L12.9454 18.1051L12.9467 18.1089L13.0389 18.3863Z" fill="#1C274C"/>
<path d="M8.79967 7.50414L8.85034 7.25885C9.46308 7.61822 10.1888 8.34391 11.2324 9.38753L11.7352 9.89037L11.5225 10.5304L11.5134 10.5583C11.5073 10.5769 11.4986 10.6039 11.4877 10.6383C11.4658 10.7071 11.435 10.8061 11.3982 10.9291C11.3246 11.1749 11.2266 11.5186 11.1284 11.9118C10.9363 12.681 10.727 13.7033 10.727 14.5551C10.727 15.4069 10.9363 16.4292 11.1284 17.1984C11.2266 17.5916 11.3246 17.9353 11.3982 18.181C11.435 18.3041 11.4658 18.403 11.4877 18.4719C11.4986 18.5063 11.5073 18.5332 11.5134 18.5519L11.6159 18.8606L8.90092 19.7656L8.7941 19.3065L8.78901 19.2844L8.7691 19.1967C8.75178 19.1198 8.72677 19.0074 8.69633 18.8666C8.63541 18.5849 8.55293 18.1907 8.46689 17.7415C8.29314 16.8345 8.11112 15.735 8.05539 14.8851C7.96334 13.4813 8.14317 11.6494 8.35579 10.1341C8.46109 9.38366 8.57244 8.72416 8.65744 8.25255C8.6999 8.0169 8.73572 7.82856 8.7608 7.6997C8.77333 7.63528 8.78318 7.58576 8.78983 7.55266L8.79734 7.51552L8.79917 7.50655L8.79967 7.50414Z" fill="#1C274C"/>
<path d="M14.9535 2.2102C15.1052 2.19008 15.3817 2.18042 15.6015 2.40018C15.8212 2.61995 15.8116 2.89644 15.7915 3.04811C15.7724 3.19146 15.7236 3.36319 15.677 3.5268L15.6412 3.6528L15.7059 3.75526C15.7934 3.89383 15.8874 4.0427 15.945 4.17511C16.0099 4.32448 16.0837 4.5766 15.9445 4.84149C15.808 5.10132 15.5635 5.18683 15.4079 5.2222C15.2664 5.25438 15.0893 5.26813 14.921 5.28121L14.7935 5.29116L14.7829 5.292L14.691 5.40356C14.5814 5.53686 14.4676 5.6752 14.3603 5.7733C14.2467 5.87723 14.0332 6.03746 13.736 5.99676C13.4286 5.95467 13.2692 5.73208 13.192 5.59298C13.1222 5.46703 13.061 5.30122 13.004 5.14672L12.9638 5.03785L12.8549 4.99764C12.7004 4.94063 12.5346 4.87947 12.4087 4.80961C12.2696 4.73247 12.047 4.57309 12.0049 4.26569C11.9642 3.96845 12.1244 3.75498 12.2284 3.64136C12.3265 3.5341 12.4648 3.42029 12.5981 3.31062L12.7097 3.21877L12.7105 3.20815L12.7204 3.08064C12.7335 2.9123 12.7473 2.73528 12.7795 2.59373C12.8148 2.43817 12.9003 2.1937 13.1602 2.05713C13.4251 1.91791 13.6772 1.99178 13.8265 2.05669C13.959 2.11425 14.1078 2.20828 14.2464 2.29581L14.3489 2.36045L14.4748 2.32464C14.6385 2.27808 14.8102 2.22921 14.9535 2.2102Z" fill="#1C274C"/>
<path d="M22.1397 10.4921C22.6068 10.0249 22.7795 9.39608 22.5236 8.84217C22.3295 8.422 21.9368 8.14871 21.4545 8.04578C21.3516 7.56349 21.0783 7.17071 20.6581 6.97663C20.1042 6.72078 19.4753 6.89345 19.0082 7.36058C18.7373 7.63146 18.6146 7.99447 18.5571 8.31774C18.4983 8.64844 18.4972 8.99801 18.5168 9.30425C18.5366 9.6135 18.5788 9.89707 18.6157 10.1021C18.6342 10.2051 18.6516 10.2897 18.6645 10.3492C18.671 10.3791 18.6764 10.4027 18.6803 10.4194C18.7249 10.5995 18.8723 10.7686 19.0523 10.8131L19.0535 10.8134L19.0809 10.8199C19.0975 10.8238 19.1212 10.8292 19.151 10.8357C19.2106 10.8487 19.2951 10.8661 19.3982 10.8846C19.6032 10.9214 19.8868 10.9637 20.196 10.9835C20.5023 11.0031 20.8518 11.0019 21.1825 10.9431C21.5058 10.8856 21.8688 10.7629 22.1397 10.4921Z" fill="#1C274C"/>
</svg>
</template>
<script></script>

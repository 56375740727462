<template>
  <div class="mb-4 card">
    <div class="pb-0 card-header">
      <h6>Locations</h6>
    </div>
    <div class="px-0 pt-0 pb-2 card-body">
      <div class="p-0 table-responsive">
        <table class="table mb-0 align-items-center">
          <thead>
            <tr>
              <th
                v-if="headers.name"
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>
              <th
                v-if="headers.coordinates"
                :class="'text-center ps-2'"
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Coordinates
              </th>
              <th
                v-if="headers.description"
                :class="'text-center ps-2'"
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Description
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(
                { id, name, street, zipcode, city, country, latitude, longitude, description }, index
              ) in lists"
              :key="index"
              class="cursor-pointer"
              @click="this.select_location(id,name)"
              >
              <!-- @click="rowClicked(id)" -->
              <td
                v-if="headers.name"
              >
                <div class="px-3 py-1 d-flex">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ name }}</h6>
                    <p class="mb-0 text-sm font-weight-bold text-secondary">{{ street }}, {{ zipcode }}, {{ city }}, {{ country }}</p>
                  </div>
                </div>
              </td>
              <td
                v-if="headers.coordinates"
              >
                <p class="text-center mb-0 text-sm font-weight-bold">
                  {{ latitude }}, {{ longitude }}
                </p>
              </td>
              <td
                class="text-sm align-middle"
                v-if="headers.description"
              >
                <p class="text-center mb-0 text-sm font-weight-bold">
                  {{ description }}
                </p>
              </td>
            </tr>
            <tr
              v-if="show_create"
              :key="index"
              class="cursor-pointer"
              @click="this.create_location()"
              >
              <td>
                <div class="px-3 py-1 d-flex">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">CREATE NEW LOCATION</h6>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: "LocationList",
    components: {
    },
    methods: {
      // Currently using callbacks to parent...
    },
    props: {
      headers: {
        name: true,
        coordinates: false,
        description: false,
      },
      lists: {
        type: Array,
        default: () => [],
        id: String,
        name: String,
        street: String,
        zipcode: String,
        city: String,
        country: String,
        latitude: String,
        longitude: String,
        description: String,
      },
      show_create: {
        type: Boolean,
        default: false,
      },
      create_location: Function,
      select_location: Function,
    },
  };
  </script>
  
<template>
  <table class="table mb-0 align-items-center">
    <thead>
      <tr>
        <th v-for="(header, index) of headers" :key="index" :class="index >= 1 ? 'text-center ps-2' : ''"
          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          {{ header }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="({ id, name, amount, created_at, created_by, status, event_id }, index) in data" :key="index"
        class="cursor-pointer" @click="rowClicked(id)">
        <td>
          <div class="px-3 py-1 d-flex">
            <div class="d-flex flex-column justify-content-center">
              <h6 class="mb-0 text-sm">{{ name }}</h6>
            </div>
          </div>
        </td>
        <td>
          <p class="text-center mb-0 text-sm font-weight-bold">
            € {{ amount / 100 }}
          </p>
        </td>
        <td>
          <p class="text-center mb-0 text-sm font-weight-bold">
            {{ (event_id) ? 'Yes' : 'No' }}
          </p>
        </td>
        <td>
          <p class="text-center mb-0 text-sm font-weight-bold">
            {{ formatDateTime(created_at) }}
          </p>
        </td>
        <td class="text-sm align-middle">
          <p class="text-center mb-0 text-sm font-weight-bold">
            {{ created_by.displayname }}
          </p>
        </td>
        <td class="text-sm align-middle">
          <p class="text-center mb-0 text-sm font-weight-bold">
            <span v-if="status == 'accepted'" class="badge badge-sm badge-success">ACCEPTED</span>
            <span v-if="status == 'pending'" class="badge badge-sm badge-warning">PENDING</span>
            <span v-if="status == 'rejected'" class="badge badge-sm badge-danger">REJECTED</span>
          </p>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { formatDateTime } from "@/dateAndTimeParsing";

export default {
  name: "FinancialDocumentsList",
  components: {
  },
  methods: {
    formatDateTime(dateTime) {
      return formatDateTime(dateTime);
    },
    rowClicked(id) {
      this.$router.push({ name: this.detailsComponentName, params: { id: id } });
    }
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
      id: String,
      name: String,
      amount: Number,
      status: String,
      related: Boolean,
      created_at: String,
      created_by: Object,
    },
    detailsComponentName: {
      type: String,
      default: '',
    },
  },
};
</script>
<template>
    <div class="mt-4 mb-4 card">
    <div class="p-3 pb-0 card-header d-flex">
      <h6 class="mb-0">{{ title }}</h6>
      <div class="ms-auto">
        <button
         type="button"
         class="mb-0 btn btn-icon-only btn-outline-secondary btn-rounded btn-sm text-sm fas fa-edit d-flex align-items-center justify-content-center"
         title="Edit locations for event"
         @click="editLocations"
        >
       </button>
      </div>
    </div>
    <div class="p-3 card-body">
      <ul class="list-group">
        <li
          v-for="(
            location,
            index
          ) of items"
          :key="index"
          class="mb-2 border-0 list-group-item d-flex justify-content-between border-radius-lg ps-0"
        >
          <div class="d-flex align-items-center">
          <div v-if="false"
              class="text-center shadow icon icon-shape icon-sm me-3"
              :class="[`bg-gradient-${background}`]">
              <font-awesome-icon
                class="mt-2 fa-sm"
                :icon="component"
                :style="{ color: 'white' }"
              />
            </div>
            <div class="d-flex flex-column">
              <h6 class="mb-1 text-sm text-dark">{{ location.name }} <span class="badge" :class="location.is_booked ? 'bg-gradient-success' : 'bg-gradient-warning'">{{ location.is_booked ? 'BOOKED' : 'NOT BOOKED' }}</span>
                <span v-if="location.meeting_point" class="badge bg-gradient-info ms-1">MP</span>
                <span v-if="location.main_location" class="badge bg-gradient-info ms-1">ML</span></h6>
              <!-- eslint-disable vue/no-v-html -->
              <span class="text-xs" v-html="location.description" />
              <div class="collapse row" :id="'collapse'+ index">
          <div class="card-body text-sm">
            <b>{{ location.name }}</b><br>
            {{ location.street }}<br>
            {{ location.zipcode }} {{ location.city }}<br>
            {{ location.country }}
            <span v-if="location.location_details"><br><br>Details:<br>
            {{ location.location_details }}</span>
            <span v-if="location.booked_by"><br><br>Booked by: {{ location.booked_by }}</span>
            <hr>
            <div class="d-flex align-items-center ms-auto">
              <a v-if="!location.is_booked" class="mb-0 btn btn-primary ms-2 btn-sm ps-3 pe-3" href="javascript:;" @click="markBooked(location.id)">Mark Booked</a>
              <a v-if="location.is_booked" class="mb-0 btn btn-secondary ms-2 btn-sm ps-3 pe-3" href="javascript:;" @click="cancelLocation(location.id)">Cancel</a>
              <a class="mb-0 btn btn-danger ms-2 btn-sm ps-3 pe-3" href="javascript:;" @click="removeLocation(location.id)">Remove</a>
            </div>
            <div class="d-flex align-items-center ms-auto mt-2">
              <a class="mb-0 btn btn-info ms-2 btn-sm ps-3 pe-3" href="javascript:;" @click="makeMeetingPoint(location.id)">Meeting point</a>
              <a class="mb-0 btn btn-info ms-2 btn-sm ps-3 pe-3" href="javascript:;" @click="makeMainLocation(location.id)">Main location</a>
            </div>
          </div>
          </div>
            </div>
          </div>
          <div class="d-flex">
            <a data-bs-toggle="collapse" :href="'#collapse'+ index" role="button" aria-expanded="false" :aria-controls="'collapse'+ index">
            <button class="my-auto btn btn-link btn-icon-only btn-rounded btn-sm text-dark">
              <i class="ni ni-bold-left" aria-hidden="true"></i>
            </button>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
//import SoftBadge from "@/components/SoftBadge.vue";
import { mapState } from "vuex";
import { makeRequest } from "@/api";

export default {
  name: "LocationCard",
  components: {
//    SoftBadge,
  },
  mounted() {
    console.log(this.items);
  },
  data() {
  },
  props: {
    event_id: {
      type: String,
      required: true,
    },
    event_name: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => {},
      name: String,
      description: String,
    },
    badge: {
      type: Object,
      default: () => ({
        color: "success",
        text: "Moderate",
      }),
      text: String,
      color: String,
    },
  },
  computed: {
  },
  methods: {
    editLocations() {
      this.$router.push({ name: 'EditLocations', params: { id: this.event_id, event_name: this.event_name } });
    },
    markBooked(location_id) {
      this.$swal({
          title: "Are you sure?",
          text: "Do you want to mark this location as booked?",
          showCancelButton: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          reverseButtons: false,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            makeRequest("put", "/events/" + this.event_id + "/locations/" + location_id, {is_booked: true}).then((response) => {
              this.$swal({
                title: "Location marked as booked!",
                icon: "success",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
              });
              this.$emit("update");
            });
          }});
    },
    cancelLocation(location_id) {
      this.$swal({
          title: "Are you sure?",
          text: "Do you want to cancel this location?",
          showCancelButton: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          reverseButtons: false,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            makeRequest("put", "/events/" + this.event_id + "/locations/" + location_id, {is_booked: false}).then((response) => {
              this.$swal({
                title: "Location cancelled!",
                icon: "success",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
              });
              this.$emit("update");
            });
          }});
    },
    removeLocation(location_id) {
      this.$swal({
          title: "Are you sure?",
          text: "Do you want to remove this location?",
          showCancelButton: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          reverseButtons: false,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            makeRequest("delete", "/events/" + this.event_id + "/locations/" + location_id, {}).then((response) => {
              this.$swal({
                title: "Location removed!",
                icon: "success",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
              });
              this.$emit("update");
            });
          }});
    },
    makeMeetingPoint(location_id) {
      this.$swal({
          title: "Are you sure?",
          text: "Do you want to set this location as meeting point?",
          showCancelButton: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          reverseButtons: false,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            makeRequest("put", "/events/" + this.event_id + "/locations/" + location_id, {meeting_point: true}).then((response) => {
              this.$swal({
                title: "Location set as meeting point!",
                icon: "success",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
              });
              this.$emit("update");
            });
          }});
    },
    makeMainLocation(location_id) {
      this.$swal({
          title: "Are you sure?",
          text: "Do you want to set this location as main location?",
          showCancelButton: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          reverseButtons: false,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            makeRequest("put", "/events/" + this.event_id + "/locations/" + location_id, {main_location: true}).then((response) => {
              this.$swal({
                title: "Location set as main location!",
                icon: "success",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
              });
              this.$emit("update");
            });
          }});
    },
  },
};
</script>

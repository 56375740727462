<template>
  <div class="card">
    <div class="p-3 pb-0 card-header">
      <div class="row">
        <div class="col-md-8 d-flex align-items-center">
          <h6 class="mb-0">Event details</h6>
        </div>
        <div class="col-md-4 text-end">
          <a :href="action.route">
            <i
              class="text-sm fas fa-edit text-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :title="action.tooltip"
            ></i>
          </a>
        </div>
      </div>
    </div>
    <div class="p-3 card-body">
      <p class="text-sm">
        {{ description }}
      </p>
      <hr class="my-4 horizontal gray-light" />
      <ul class="list-group">
        <li class="pt-0 text-sm border-0 list-group-item ps-0" v-if="starts_at">
          <strong class="text-dark">Start:</strong> &nbsp;
          {{ formatTimestamp(starts_at) }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0" v-if="starts_at">
          <strong class="text-dark">End:</strong> &nbsp; {{ formatTimestamp(ends_at) || "open end"}}
        </li>
        <li class="text-sm border-0 list-group-item ps-0" v-if="presale_starts_at">
          <strong class="text-dark">Presale starts:</strong> &nbsp; Tuesday, 12/12/2023, 2 hours and 17 minutes{{ formatTimestamp(presale_starts_at) }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0" v-if="presale_ends_at">
          <strong class="text-dark">Presale ends:</strong> &nbsp; Tuesday, 12/12/2023, 2 hours and 17 minutes{{ presale_ends_at }}
          
        </li>
        <li class="pb-0 text-sm border-0 list-group-item ps-0" v-if="cancellation_details">
          <strong class="text-sm text-dark">Cancellation Details:</strong> &nbsp; {{ cancellation_details }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: "EventInfoCard",
  components: {
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    starts_at: {
      type: String,
      default: "",
    },
    ends_at: {
      type: String,
      default: "",
    },
    presale_starts_at: {
      type: String,
      default: "",
    },
    presale_ends_at: {
      type: String,
      default: "",
    },
    cancellation_details: {
      type: String,
      default: "",
    },
    action: {
      type: Object,
      route: String,
      tooltip: String,
      default: () => ({
        route: "javascript:;",
      }),
    },
  },
  methods: {
    formatTimestamp(timestamp) {
      const eventDate = new Date(timestamp);
      const currentDate = new Date();

      const daysDifference = Math.floor((eventDate - currentDate) / (24 * 60 * 60 * 1000));
      const hoursDifference = Math.floor((eventDate - currentDate) / (60 * 60 * 1000)) % 24;
      const minutesDifference = Math.floor((eventDate - currentDate) / (60 * 1000)) % 60;

      const dayOfWeek = eventDate.toLocaleDateString('en-AT', { weekday: 'long' });
      const formattedDate = eventDate.toLocaleDateString('en-AT', { month: 'numeric', day: 'numeric', year: 'numeric' });
      const formattedTime = eventDate.toLocaleTimeString('en-AT', { hour: 'numeric', minute: 'numeric' });

      return `${dayOfWeek}, ${formattedDate}, from ${formattedTime} o'clock ` +
        `(in ${daysDifference} days, ${hoursDifference} hours and ${minutesDifference} minutes)`;
    },
  },
};
</script>

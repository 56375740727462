<template>
  <li class="nav-item">
    <router-link class="nav-link" :to="to">
      <span class="sidenav-mini-icon"> {{ miniIcon }} </span>
      <span class="sidenav-normal"> {{ text }} </span>
      <span v-if="badgeNumber && badgeNumber != 0" class="badge">{{ badgeNumber }}</span>
    </router-link>
  </li>
</template>
<script>
export default {
  name: "SidenavItem",
  props: {
    to: {
      type: [Object, String],
      required: true,
    },
    miniIcon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    badgeNumber: {
      type: Number,
      default: null,
    },
  },
};
</script>
<style>
.badge {
  background-color: rgb(0, 153, 255);
  color: white;
  border-radius: 50%;
  padding: 2px 8px;
  font-size: 12px;
  margin-left: 10px;
  /* Additional styling as needed */
}
</style>
<template>
  <div class="mt-0 card card-body">
    <h6 class="mb-0">New Location</h6>
    <p class="mb-0 text-sm">Create new location</p>
    <hr class="my-3 horizontal dark" />
    <div class="row">
      <div class="form-group">                
        <div class="multisteps-form__content">
          <div class="row">
            <div class="col">
              <label>Location Name</label>
              <soft-input
                id="name"
                class="multisteps-form__input"
                type="text"
                placeholder=""
                :value="name"
                @input="name = $event.target.value"
              />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <label>Address (street and number)</label>
              <soft-input
                id="street"
                class="multisteps-form__input"
                type="text"
                placeholder="eg. Street 111"
                :value="street"
                @input="street = $event.target.value"
              />
            </div>
          </div>
          <div class="row mt-3 d-flex align-items-center">
            <div class="col-3">
              <label>ZIP</label>
              <soft-input
                id="zipCode"
                class="multisteps-form__input"
                type="text"
                placeholder="eg. 1010"
                :value="zipCode"
                @input="zipCode = $event.target.value"
              />
            </div>
            <div class="col">
              <label>City</label>
              <soft-input
                id="city"
                class="multisteps-form__input"
                type="text"
                placeholder="eg. Vienna"
                :value="city"
                @input="city = $event.target.value"

              />
            </div>
            <div class="col">
              <label>Country</label>
              <soft-input
                id="country"
                class="multisteps-form__input"
                type="text"
                placeholder="eg. Austria"
                :value="country"
                @input="country = $event.target.value"
                />
              </div>
            </div>
          </div>
          <div class="row mt-3 d-flex align-items-center">
            <div class="col">
              <label>Latitude</label>
              <soft-input
                id="latitude"
                class="multisteps-form__input"
                type="text"
                placeholder="eg. 48.20820"
                :value="latitude"
                @input="latitude = $event.target.value"
              />
            </div> 
            <div class="col">
              <label>Longitude</label>
              <soft-input
                id="longitude"
                class="multisteps-form__input"
                type="text"
                placeholder="eg. 16.37380"
                :value="longitude"
                @input="longitude = $event.target.value"
              />
            </div>
            <div class="col">
              <button
                type="button"
                name="button_geocode"
                class="btn btn-light ms-2 mt-4"
                @click="geocode"
                >
                  Get coordinates from address
              </button>
            </div>
          </div>
          <div class="col">
            <label>Location Description</label>
            <p class="text-xs form-text text-muted ms-1">
              Any additional information about the location (eg. alternative name or details about the location)
            </p>
            <soft-input
              id="description"
              class="multisteps-form__input"
              type="text"
              placeholder=""
              :value="description"
              @input="description = $event.target.value"
            />
          </div>
        </div>
      </div>
      <div class="row mx-1">
        <soft-alert
          :color="alertColor"
          :icon="alertIcon + ' ni-lg'"
          v-if="message"
          >
            <strong>{{ message }}</strong>
        </soft-alert>
      </div>
      <div class="d-flex justify-content-end">
        <button type="button" name="cancel" class="m-0 btn btn-light" @click="hide_create">
          Cancel
        </button>
        <button
          type="button"
          name="submit"
          class="m-0 btn bg-gradient-success ms-2"
          @click="submit"
        >
          Create Location
        </button>
      </div>
    </div>
</template>

<script>
  import SoftInput from "@/components/SoftInput.vue";
  import SoftAlert from "@/components/SoftAlert.vue";
  import { makeRequest } from "@/api";
  
  export default {
    name: "NewLocation",
    components: {
      SoftInput,
      SoftAlert,
    },
    data() {
      return {
        name: '',
        street: '',
        zipCode: '',
        city: '',
        country: '',
        latitude: '',
        longitude: '',
        message: '',
        alertColor: '',
        alertIcon: '',
        id: null,
      };
    },
    props: {
      hide_create: Function,
    },
    methods: {
      async geocode() {
        const address = `${this.street}, ${this.zipCode} ${this.city}, ${this.country}`;
        // console.log('address:', address);
        const url = `https://nominatim.openstreetmap.org/search?q=${address}&format=json&limit=1`;
        const response = await fetch(url);
        const data = await response.json();
        if (data.length === 0) {
          this.message = 'No coordinates found';        
          this.alertColor = 'warning';
          this.alertIcon = 'ni ni-world-2';
          return;
        }
        this.latitude = data[0].lat;
        this.longitude = data[0].lon;
        this.message = null;
      },
      async submit() {
        const location = {
          name: this.name,
          street: this.street,
          zipcode: this.zipCode,
          city: this.city,
          country: this.country,
          latitude: this.latitude,
          longitude: this.longitude,
          description: this.description,
        };
        
        // console.log('location:', location);
        try {
          const response = await makeRequest("POST", "/locations/", location);
          this.message = 'Location created successfully';
          this.alertColor = 'success';
          this.alertIcon = 'ni ni-check-bold';
          this.id = response.data.id;
          // this.hide_create(this.id, this.name);
        } catch (error) {
          console.error('Creating location failed:', error);
          this.message = 'Creating location failed';
          this.alertColor = 'danger';
          this.alertIcon = 'ni ni-fat-remove';
        }
      },
    },
  };
</script>
  
<template>
  <div class="card multisteps-form__panel p-3 border-radius-xl bg-white" data-animation="FadeIn">
    <h5 class="font-weight-bolder mb-0">Add API key</h5>
    <p class="mb-0 text-sm">Add the API Key from {{ section }} to access information</p>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-12 mb-4">
          <label>Newly generated API Key for {{ section }} to access your section</label>
          <input id="api_key_other_section" type="text" class="form-control" placeholder="emp/###############" aria-label="Section API URL" aria-describedby="button-addon2"
            v-model="api_key" readonly>
          <small class="text-muted">This key will be used to access your section. Keep it safe and only share it with people you trust! <b>You can only see this key once.</b></small>
          <hr>
          <label>Enter API Key of {{ section }} to access their section</label>
          <div class="d-flex">
            <input id="api_key_other_section" type="password" class="form-control me-2" placeholder="emp/###############" aria-label="Section API URL" aria-describedby="button-addon2"
            :value="otherSection.apikey_out" @input="otherSection.apikey_out = $event.target.value; updateValues()">
            <button class="btn btn-outline-primary mb-0" type="button" id="button-addon2" @click="checkConnection()">Check connection</button>
          </div>
        </div>
      </div>
      <soft-alert
                :color="alertColor"
                :icon="alertIcon + ' ni-lg'"
                 v-if="message"
                >
                  <strong>{{ message }}</strong>
              </soft-alert>
      <div class="button-row d-flex mt-4">
        <soft-button
          type="button"
          color="primary"
          variant="gradient"
          class="ms-auto js-btn-next"
          :disabled="!validKey"
          @click="this.$parent.nextStep"
        >Connect section</soft-button>
      </div>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftAlert from "@/components/SoftAlert.vue";
import { makeRequest } from "@/api";

export default {
  name: "Add API Key",
  components: {
    SoftButton,
    SoftAlert,
  },
  props: {
    section: {
      type: String,
      required: true,
    },
    apikey: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  computed: {
    api_key() {
      return this.apikey;
    },
    backend_url() {
      return this.url;
    },
  },
  data() {
    return {
      otherSection: {
        apikey: '',
      },
      message: '',
      alertColor: '',
      alertIcon: '',
      validKey: false,
    };
  },
  methods: {
    checkConnection() {
      makeRequest('get', '/sections/proxy?url=' + this.backend_url + '&check_connection=' + this.otherSection.apikey_out)
        .then((response) => {
          this.message = 'Connection successful';
          this.alertColor = 'success';
          this.alertIcon = 'ni ni-check-bold';
          this.validKey = true;
        })
        .catch((error) => {
          this.message = 'Connection failed';
          this.alertColor = 'danger';
          this.alertIcon = 'ni ni-fat-remove';
          this.validKey = false;
        });
    },
    updateValues() {
      this.$emit("values-updated", this.otherSection);
    }
  }
};
</script>

<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12 col-xxl-8 mx-auto d-block">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center" style="height: 100%;">
              <h6 class="mb-0">ESNcard Shop Requests</h6>
              <!-- TODO add this button again with dynamic link to shop
              <button class="btn btn-sm bg-gradient-primary my-auto ms-auto" type="button" name="button"
                @click="$router.push('/')">Go to shop</button>
                 -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 col-xxl-8 mx-auto d-block">
        <div class="col-12">
          <div class="mb-4 card">
            <div class="pb-0 card-header">
              <h6>Requests</h6>
            </div>
            <div class="px-0 pt-0 pb-2 card-body">
              <div class="p-0 table-responsive">
                <table class="table mb-0 align-items-center">
                  <thead>
                    <tr>
                      <!-- eslint-disable-next-line -->
                      <th v-for="(header, index)
                        of
                        [...['Image', 'Name', 'Country'],
                          'Destination University', 'Created at',
                          'File', 'Actions']" :key="index"
                        class="text-center ps-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        {{ header }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(
                request, index
              ) in requests" :key="index">
                      <td>
                        <div class="px-3 py-1 d-flex">
                          <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-sm">{{ name }}</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p class="text-center mb-0 text-sm font-weight-bold">
                          {{ request.first_name }} {{ request.last_name }}
                        </p>
                      </td>
                      <td class="text-sm align-middle">
                        <p class="text-center mb-0 text-sm font-weight-bold">
                          {{ countryCodeToFlag(request.nationality) }}
                        </p>
                      </td>
                      <td class="text-sm align-middle">
                        <p class="text-center mb-0 text-sm font-weight-bold">
                          {{ request.destination_university }}
                        </p>
                      </td>
                      <td class="text-sm align-middle">
                        <p class="text-center mb-0 text-sm font-weight-bold">
                          {{ formatDateTime(request.created_at) }}
                        </p>
                      </td>

                      <td class="text-sm align-middle">
                        <p class="text-center mb-0 text-sm font-weight-bold">
                          <button class="btn btn-link text-dark text-sm mb-0 px-0 ms-4"
                            @click="downloadFile(request.doc_mobility_status)">
                            <i class="fas fa-file text-lg me-1" aria-hidden="true"></i>
                            FILE
                          </button>
                        </p>
                      </td>
                      <td class="text-sm align-middle">
                        <p class="text-center mb-0 text-sm font-weight-bold">
                          <button class="btn btn-success text-dark btn-link text-sm mb-0 px-2 mx-4 me-1 pb-2 pt-2"
                            @click="approveRequest(request.code)">
                            <i class="fas fa-thumbs-up text-lg me-1" aria-hidden="true"></i>
                          </button>
                          <button class="btn btn-danger text-dark btn-link text-sm mb-0 px-2 ms-1 pb-2 pt-2"
                            @click="rejectRequest(request.code)">
                            <i class="fas fa-thumbs-down text-lg me-1" aria-hidden="true"></i>
                          </button>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="alert alert-info mx-4 mt-4" role="alert" v-if="requests == null || requests.length == 0">
                  <strong>So empty here!</strong> There are currently no requests in the database!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateTime } from "@/dateAndTimeParsing";
import { makeRequest } from "@/api";

export default {
  name: "ESNcardShopRequests",
  components: {
  },
  methods: {
    formatDateTime(dateTime) {
      return formatDateTime(dateTime);
    },
    rowClicked() {
    },
    async fetchRequests() {
      const response = await makeRequest("GET", "/office/esncard-shop/requests");
      this.requests = response.data;
    },
    async approveRequest(requestId) {
      this.$swal({
        title: "Approve request?",
        text: "After approving the request the user has 7 days to pay for the ESNcard.",
        showCancelButton: true,
        confirmButtonText: "Approve",
        cancelButtonText: "Cancel",
        reverseButtons: false,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const res = makeRequest("PUT", `/office/esncard-shop/requests/${requestId}/evaluate`, {
            status: "accepted",
          });
          this.$swal({
            title: "Approved!",
            text: "The order was approved.",
            icon: "success",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          });
          this.fetchRequests();
        }
      });

    },
    async rejectRequest(requestId) {
      this.$swal({
        title: "Reject request",
        text: "Please provide the reason for rejecting the request:",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Reject request",
        showLoaderOnConfirm: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
        allowOutsideClick: () => !this.$swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          const res = makeRequest("PUT", `/office/esncard-shop/requests/${requestId}/evaluate`, {
            status: "rejected",
            reason: result.value,
          });
          this.$swal({
            title: "Rejected!",
            text: "The order was rejected.",
            icon: "info",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          });
          this.fetchRequests();
        }
      });


      await makeRequest("POST", `/office/esncard-shop/requests/${requestId}/reject`);
      this.fetchRequests();
    },
    countryCodeToFlag(countryCode) {
      if (!countryCode) {
        return "";
      }
      return countryCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397));
    },
    async downloadFile(pretixFile) {
      const file = await makeRequest("GET", `/p/view?slug=${pretixFile.event}&op=${pretixFile.op}&a=${pretixFile.a}`, null, 'application/json', true);
      console.log(file);

      const url = window.URL.createObjectURL(new Blob([file.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", 'file.' + file.headers["content-type"].split("/")[1]);
      document.body.appendChild(link);
      link.click();

      // cleanup
      window.URL.revokeObjectURL(url);

      return false;


    },
  },
  data() {
    return {
      requests: []
    };
  },
  created() {
    this.fetchRequests();
  },
};
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="mx-auto col-lg-9 col-12">
        <div class="mt-0 mb-4 card card-body">
          <h6 class="mb-0">Event</h6>
          <p class="mb-0 text-sm"> {{ event_name }}</p>
        </div>
        <div class="mt-0 card card-body">
          <OrganisersSelect :id="id" @ocs="updateOrganisers" />
          <div class="d-flex justify-content-end mt-4">
            <button type="button" name="cancel" class="m-0 btn btn-light" @click="cancel()">
              Cancel
            </button>
            <button type="button" name="submit" class="m-0 btn bg-gradient-success ms-2" @click="sumbit">
              Save changes
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { makeRequest } from "@/api";
import OrganisersSelect from "./components/OrganisersSelect.vue";

export default {
  name: "EditOrganisers",
  components: {
    OrganisersSelect,
  },
  mounted() {
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  },
  data() {
    return {
      windowWidth: 0,
      message: '',
      alertColor: '',
      alertIcon: '',
      currentOrganisers: [],
      fullView: false,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    event_name: {
      type: String,
      required: true,
    },
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    async sumbit() {
      const request = {
        organisers: this.currentOrganisers.map(({ id, as }) => ({ id, as })),
      };
      const response = await makeRequest("PUT", `/events/${this.id}`, request);

      this.$router.go(-1);
    },
    updateOrganisers(organisers) {
      this.currentOrganisers = organisers;
    },
  },
};
</script>
<template>
  <nav aria-label="Page navigation" v-if="this.pages.length > 1">
    <ul class="pagination justify-content-center">
      <li class="page-item" :class="this.active == 1 ? 'disabled' : ''">
        <a class="page-link" href="javascript:;" tabindex="-1" @click="changePage(this.active - 1)">
          <i class="fa fa-angle-left"></i>
          <span class="sr-only">Previous</span>
        </a>
      </li>
      <li v-for="(pageNumber, index) in visiblePages" :key="index" class="page-item"
        :class="this.active == this.menu[index] ? 'active' : ''"><a class="page-link"
          v-if="this.menu[index] <= this.pages.length" href="javascript:;" @click="changePage(this.menu[index])">{{
    this.menu[index] }}</a></li>
      <li class="page-item" :class="this.active == this.pages.length ? 'disabled' : ''">
        <a class="page-link" href="javascript:;" @click="changePage(this.active + 1)">
          <i class="fa fa-angle-right"></i>
          <span class="sr-only">Next</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { makeRequest } from '@/api';

export default {
  name: "PaginationNav",
  props: {
    endpoint: {
      type: String,
    },
    itemsPerPage: {
      type: Number,
      default: 5,
    },
    hideIfOnlyOnePage: {
      type: Boolean,
      default: true,
    },
    visiblePages: {
      type: Number,
      default: 3,
    },
    lazyLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: [],
      pages: [],
      active: 1,
      pagination: {
        limit: this.itemsPerPage,
        offset: 0,
      },
      menu: [],
    };
  },

  created() {
    this.initalizeNav();
  },
  methods: {
    getURL() {
      let url = this.endpoint;
      if (url.includes("?")) {
        url += "&limit=" + this.pagination.limit + "&offset=" + this.pagination.offset;
      } else {
        url += "?limit=" + this.pagination.limit + "&offset=" + this.pagination.offset;
      }
      return url;
    },
    async initalizeNav() {
      try {
        let url = this.getURL();
        let response = await makeRequest("GET", url);
        this.pages.push(response.data);
        this.changePage(1);
        while (response.data.length == this.itemsPerPage && !(this.lazyLoading && this.pages.length < this.visiblePages)) {
          this.pagination.offset += this.itemsPerPage;
          url = this.getURL();
          response = await makeRequest("GET", url);
          this.pages.push(response.data);
        }
      } catch (error) {
        console.log(error);
      }

      this.menu = [];
      if (this.visiblePages < this.pages.length) {
        for (let i = 0; i < this.visiblePages; i++) {
          this.menu.push(i + 1);
        }
      } else {
        this.menu = this.pages.map((_, i) => i + 1);
      }
    },
    changePage(page) {
      this.currentPage = this.pages[page - 1];
      this.active = page;
      this.pagination.offset = (page - 1) * this.itemsPerPage;
      const neighbours = Math.floor(this.visiblePages / 2);
      if (page - neighbours < 1) {
        this.menu = [];
        for (let i = 1; i <= this.visiblePages; i++) {
          if (i > this.pages.length) {
            break;
          }
          this.menu.push(i);
        }
        this.$emit('page-changed', this.currentPage);
        return;
      }
      if (page + neighbours > this.pages.length) {
        this.menu = [];
        for (let i = this.pages.length - this.visiblePages + 1; i <= this.pages.length; i++) {
          if (i < 1) {
            continue;
          }
          this.menu.push(i);
        }
        this.$emit('page-changed', this.currentPage);
        return;
      }
      this.menu = [];
      for (let i = page - neighbours; i <= page + neighbours; i++) {
        if (i < 1 || i > this.pages.length) {
          continue;
        }
        this.menu.push(i);
      }
      this.$emit('page-changed', this.currentPage);
    },

  },
};
</script>

<template>
  <div class="card">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex align-items-center">
        <h6 class="mb-0">Items</h6>
        <button type="button"
          class="mb-0 btn btn-icon-only btn-rounded ms-2 btn-sm d-flex align-items-center justify-content-center ms-auto"
          :class="editMode ? 'btn-outline-primary' : 'btn-outline-secondary'" data-bs-toggle="tooltip"
          data-bs-placement="top" title="Edit items for event">
          <a href="javascript:;" @click="this.itemWizard()">
            <i class="text-sm fas fa-edit" :class="editMode ? 'text-primary' : 'text-secondary'"></i>
          </a>
        </button>
        <button type="button"
          class="mb-0 btn btn-icon-only btn-rounded ms-2 btn-sm d-flex align-items-center justify-content-center"
          :class="editMode ? 'btn-outline-primary' : 'btn-outline-secondary'" data-bs-toggle="tooltip"
          data-bs-placement="top" title="Edit items for event">
          <a href="javascript:;" @click="this.toggleEditMode()">
            <i class="text-sm fas fa-edit" :class="editMode ? 'text-primary' : 'text-secondary'"></i>
          </a>
        </button>
      </div>
    </div>
    <div class="p-3 card-body">
      <ul class="list-group">
        <div v-for="(category, index) of apiData" :key="index">
          <h6><a v-if="this.editMode" class="mb-0 btn btn-danger btn-sm ps-3 pe-3" href="javascript:;"
              @click="this.deleteCategory(category.id, category.name)">X</a>&nbsp;{{ category.name }}</h6>
          <li v-for="(item, index2) of category.items" :key="index2"
            class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
            <div class="w-100">
              <div class="mb-2 d-flex align-items-center">
                <span class="text-sm me-2 font-weight-bold text-capitalize ms-2">
                  <a v-if="this.editMode" class="mb-0 btn btn-danger btn-sm ps-3 pe-3" href="javascript:;"
                    @click="this.deleteItem(category.id, item.id, item.name)">X</a>&nbsp;{{ item.name }} (€ {{
            item.price / 100 }})
                </span>
                <span class="text-sm ms-auto font-weight-bold">
                  <i class="ni ni-bullet-list-67 me-1" title="Waiting list enabled" v-if="item.allow_waitinglist"></i>
                  XX%
                </span>
              </div>
              <div>
                <div class="progress progress-md">
                  <div class="progress-bar bg-gradient-dark" :class="`w-${37 + index * 5}`" role="progressbar"
                    aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>
          </li>
          <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center" v-if="this.editMode">
            <div class="w-100">
              <div class="mb-2 d-flex align-items-center">
                <span class="text-sm me-2 font-weight-bold text-capitalize ms-2">
                  <soft-button color="primary" size="sm" variant="gradient">Add item</soft-button>
                </span>
              </div>
            </div>
          </li>
          <hr v-if="this.editMode">
        </div>
        <div v-if="this.editMode">
          <h6><soft-button color="primary" size="sm" variant="gradient">Add category</soft-button></h6>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import { makeRequest } from "@/api";

export default {
  name: "ItemsCard",
  components: {
    SoftButton,
  },
  props: {
    event_id: {
      type: String,
    },
  },
  data() {
    return {
      apiData: [],
      editMode: false,
    };
  },
  mounted() {
    this.getItems();
  },
  methods: {
    async getItems() {
      try {
        const response = await makeRequest("GET", "/events/" + this.event_id + "/categories?includeItems=true");
        console.log(response.data);
        this.apiData = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    toggleEditMode() {
      this.editMode = !this.editMode;
    },
    async itemWizard() {
      this.$swal({
        title: "Use item wizard?",
        text: "Do you want to automatically generate items for this event? For this, the statements of costs (approved and pending) are used.",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        reverseButtons: false,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {

          this.$swal({
            title: "Number of participants",
            text: "Please enter the number of possible participation spots (This number of participants includes every person present including the organisers):",
            input: "number",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Approve",
            showLoaderOnConfirm: true,
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
            buttonsStyling: false,
            allowOutsideClick: () => !this.$swal.isLoading(),
          }).then((result) => {
            if (result.isConfirmed) {
              makeRequest("post", "/events/" + this.event_id + "/items/wizard/", { participants: result.value }).then((response) => {
                this.apiData = response.data;
                this.$swal({
                  title: "Items created!",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn bg-gradient-success",
                  },
                });
              }).catch((error) => {
                this.$swal({
                  title: "Task failed successfully!",
                  text: error.response.data.message,
                  icon: "error",
                  customClass: {
                    confirmButton: "btn bg-gradient-success",
                  },
                });
              });
            }
          });
        }
      });
    },
    deleteItem(category_id, item_id, item_name) {
      try {
        this.$swal({
          title: "Delete item?",
          text: "Do you really want to delete \"" + item_name + "\"? This action cannot be undone.",
          showCancelButton: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          reverseButtons: false,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            makeRequest("delete", "/events/" + this.event_id + "/items/" + item_id).then(() => {
              this.getItems();
            });
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    deleteCategory(category_id, category_name) {
      try {
        this.$swal({
          title: "Delete category?",
          text: "Do you really want to delete \"" + category_name + "\" and all of its items? This action cannot be undone.",
          showCancelButton: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          reverseButtons: false,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            makeRequest("delete", "/events/" + this.event_id + "/categories/" + category_id).then(() => {
              this.getItems();
            });
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

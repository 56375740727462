<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="mx-auto col-lg-9 col-12">
        <div class="mt-4 card card-body">
          <h6 class="mb-0">New Invoice</h6>
          <p class="mb-0 text-sm">Create new Invoice</p>
          <hr class="my-3 horizontal dark" />
          <label for="socName" class="form-label">Name (or short description)</label>
          <input id="socName" type="text" class="form-control" v-model="invoice.name" />
          <div><br></div>
          <label for="socDescription" class="form-label">Detailed description (optional)</label>
          <textarea id="socDescription" class="form-control" v-model="invoice.invoice"></textarea>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label class="mt-4 form-label">Actual expense</label>
                <div class="input-group">
                  <span class="input-group-text">€</span>
                  <input type="text" class="form-control" aria-label="Amount" v-model="invoice.amount">
                </div>
              </div>
            </div>
            <div class="col mt-3">
              <label for="accountforpayout" class="form-label">Account for payout</label>
              <!-- TODO: add event lookup  -->
              <input id="accountforpayout" type="text" class="form-control" />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="events">Related to event (optional)</label>
                <select v-model="invoice.event_id" class="form-control" id="events">
                  <option>Not related to any event</option>
                  <option v-for="(event, index) in events" :key="index" :selected="event.id == $route.query.event_id"
                    :value="event.id">{{
            event.starts_at ? formatDate(event.starts_at) :
              "Draft" }} -
                    {{ event.name }}</option>
                </select>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="socs">Related to statement of cost (optional)</label>
                <select v-model="invoice.statement_of_cost_id" class="form-control" id="socs">
                  <option>Not related to any statement of cost</option>
                  <option v-for="(soc, index) in socs" :key="index"
                    :selected="soc.id == $route.query.statement_of_cost_id" :value="soc.id">{{ soc.name }} (€ {{
            soc.amount / 100 }})</option>
                </select>
              </div>
            </div>
          </div>
          <label class="mt-4 form-label">Invoice</label>
          <form id="dropzone" action="/file-upload" class="form-control dropzone">
            <div class="fallback">
              <input name="file" type="file" multiple />
            </div>
          </form>
          <div class="row mt-3 mx-1">
            <soft-alert :color="alertColor" :icon="alertIcon + ' ni-lg'" v-if="message">
              <strong>{{ message }}</strong>
            </soft-alert>
          </div>
          <div class="mt-4 d-flex justify-content-end">
            <button v-if="$route.query.event_id" type="button" name="button" class="m-0 btn btn-light"
              @click="$router.push('/statements-of-cost/' + $route.query.statement_of_cost_id)">
              Cancel
            </button>
            <button type="button" name="button" class="m-0 btn bg-gradient-primary ms-2" @click="submit">
              Submit Invoice
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import flatPickr from "vue-flatpickr-component";
import Dropzone from "dropzone";
import Choices from "choices.js";
import Quill from "quill";
import SoftAlert from "@/components/SoftAlert.vue";
import { formatDate } from "@/dateAndTimeParsing";
import { makeRequest } from "@/api";

export default {
  name: "NewInvoice",
  components: {
    SoftAlert,
    //flatPickr,
  },
  props: {
    forinvoiceOfCost: String,
  },
  data() {
    return {
      invoice: {
        name: '',
        details: '',
        amount: '',
        event_id: this.$route.query.event_id,
        statement_of_cost_id: this.$route.query.statement_of_cost_id,
        // attachment: '',
      },
      message: '',
      alertColor: '',
      alertIcon: '',
      config: {
        allowInput: true,
      },
      events: [],
      socs: [],
    };
  },
  watch: {
    'invoice.event_id': function (newValue, oldValue) {
      this.getSocs();
    }
  },
  methods: {
    async submit() {
      try {
        const response = await makeRequest("POST", "/statements-of-cost/", this.invoice);
        this.message = 'invoice of cost submitted successfully'
        this.alertColor = 'success';
        this.alertIcon = 'ni ni-check-bold';
      } catch (error) {
        console.log('Posting SoC failed:', error);
        this.message = 'Submission failed';
        this.alertColor = 'danger';
        this.alertIcon = 'ni ni-fat-remove';
      }
    },
    async getEvents() {
      try {
        const response = await makeRequest("GET", "/events/");
        this.events = response.data;
      } catch (error) {
        console.log('Fetching events failed:', error);
      }
    },
    async getSocs() {
      try {
        const response = await makeRequest("GET", "/events/" + this.invoice.event_id + "/statements-of-cost/");
        this.socs = response.data;
        console.log(this.socs)
      } catch (error) {
        console.log('Fetching SoCs failed:', error);
      }
    },
    formatDate(dateTime) {
      return formatDate(dateTime);
    },
  },
  mounted() {
    this.getEvents();
    this.getSocs();
    if (document.getElementById("editor")) {
      new Quill("#editor", {
        theme: "snow", // Specify theme in configuration
      });
    }

    if (document.getElementById("choices-multiple-remove-button")) {
      var element = document.getElementById("choices-multiple-remove-button");
      const example = new Choices(element, {
        removeItemButton: true,
      });

      example.setChoices(
        [
          {
            value: "One",
            label: "Label One",
            disabled: true,
          },
          {
            value: "Two",
            label: "Label Two",
            selected: true,
          },
          {
            value: "Three",
            label: "Label Three",
          },
        ],
        "value",
        "label",
        false
      );
    }

    Dropzone.autoDiscover = false;
    var drop = document.getElementById("dropzone");
    new Dropzone(drop, {
      url: "/file/post",
      addRemoveLinks: true,
    });
  },
};
</script>

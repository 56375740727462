<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="mx-auto col-lg-9 col-12">
        <div class="mt-0 mb-4 card card-body">
          <h6 class="mb-0">Group</h6>
          <div>
            <label>Group Name</label>
            <soft-input id="displayname" class="multisteps-form__input" type="text" placeholder="" :value="displayname"
              @input="displayname = $event.target.value" />
            <label>Group Description</label>
            <soft-input id="description" class="multisteps-form__input" type="text" placeholder="" :value="description"
              @input="description = $event.target.value" />
            <label>Visibility of group</label>
            <div class="form-check ms-2">
              <input class="form-check-input" type="checkbox" value="" id="fcustomCheck1" :checked="hidden_group"
                @input="hidden_group = $event.target.checked">
              <label class="custom-control-label" for="customCheck1">Hide group</label>
            </div>
          </div>
        </div>
        <div class="mt-0 card card-body">
          <h6 class="mb-0">Permissions ({{ currentPermissions.length }}/{{ permissions.length }})</h6>
          <p class="mb-0 text-sm">Set permissions for group</p>
          <hr class="my-3 horizontal dark" />
          <div class="row">
            <div class="form-group">
              <input type="text" class="form-control" aria-label="Search for permissions"
                aria-describedby="inputGroup-sizing-default" v-model="searchQuery" @input="search"
                placeholder="Search for permissions">
            </div>
            <div class="col mb-4">
              <p class="mb-2 text-sm">Search result</p>
              <ul class="list-group">
                <li class="px-3 mb-2 list-group-item d-flex align-items-center" v-if="currentSearch.length === 0">
                  <i>Type in the search bar for permissions to appear</i>
                </li>
                <li class="px-3 mb-2 border-3 list-group-item d-flex align-items-center"
                  v-for="(permission, index) of currentSearch" :key="index">
                  <div class="d-flex align-items-start flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ permission.name }}</h6>
                    <p class="mb-0 text-xs">{{ permission.description }}</p>
                  </div>
                  <div class="d-flex align-items-center ms-auto">
                    <a class="mb-0 btn btn-success" :class="{ 'btn-sm ps-3 pe-3': isSmallScreen }" href="javascript:;"
                      v-if="!currentPermissions.some(p => p.name === permission.name)"
                      @click="currentPermissions.push(permission)">{{ isSmallScreen ? '+' : 'Add' }}</a>
                    <a class="mb-0 btn btn-link pe-3 ps-0" :class="{ 'btn-sm ps-3': isSmallScreen }" href="javascript:;"
                      v-if="currentPermissions.some(p => p.name === permission.name)">{{ isSmallScreen ? 'Added' :
                      'Already added'}}</a>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col">
              <p class="mb-2 text-sm">Permission list</p>
              <ul class="list-group">
                <li class="px-3 mb-2 border-3 list-group-item d-flex align-items-center"
                  v-for="(permission, index) of currentPermissions" :key="index">
                  <div class="d-flex align-items-start flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ permission.name }} <span class="badge bg-gradient-primary"
                        v-if="false">New</span></h6>
                    <p class="mb-0 text-xs">{{ permission.description }}</p>
                  </div>
                  <div class="d-flex align-items-center ms-auto" v-if="true">
                    <a class="mb-0 btn btn-danger ms-2" :class="{ 'btn-sm ps-3 pe-3': isSmallScreen }"
                      href="javascript:;"
                      @click="this.currentPermissions.splice(this.currentPermissions.findIndex(p => p.name == permission.name), 1)">{{
                        isSmallScreen ? 'X' : 'Remove' }}</a>
                  </div>
                </li>
              </ul>
            </div>

          </div>
          <div class="row mx-1">
            <soft-alert :color="alertColor" :icon="alertIcon + ' ni-lg'" v-if="message">
              <strong>{{ message }}</strong>
            </soft-alert>
          </div>
          <div class="d-flex justify-content-end mt-4">
            <button type="button" name="cancel" class="m-0 btn btn-light" @click="cancel()">
              Cancel
            </button>
            <button type="button" name="submit" class="m-0 btn bg-gradient-success ms-2" @click="sumbit">
              Save changes
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import SoftAlert from "@/components/SoftAlert.vue";
import SoftInput from "@/components/SoftInput.vue";
import { makeRequest } from "@/api";

export default {
  name: "Create or edit group",
  components: {
    SoftAlert,
    SoftInput,
  },
  props: {
    mode: String,
    id: {
      type: String,
      required: false,
    },
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    if (this.mode === 'edit') {
      this.getGroup()
    }

    this.loadPermissions();

    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  },
  data() {
    return {
      displayname: '',
      description: '',
      hidden_group: false,
      windowWidth: 0,
      message: '',
      alertColor: '',
      alertIcon: '',
      //currentPermissionsCount: 0,
      currentPermissions: [],
      fullView: false,
      currentSearch: [],
      permissions: [],
    };
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    async getGroup() {
      try {
        const response = await makeRequest("GET", `/groups/${this.id}`);
        console.log(response)
        this.displayname = response.data.displayname;
        this.description = response.data.description;
        this.hidden_group = response.data.hidden_group;
        for (const permission of response.data.permissions) {
          const permissionObject = this.permissions.find(p => p.name === permission);
          this.currentPermissions.push(permissionObject);
        }
      } catch (error) {
        console.log(error);
      }
    },
    search() {
      if (this.searchQuery.length > 2) {
        this.getPermissions(this.searchQuery);
      } else {
        this.currentSearch = Array.from(this.permissions);
      }
    },
    async loadPermissions() {
      const response = await makeRequest("GET", "/settings/permissions");
      this.permissions = response.data;
      this.currentSearch = Array.from(this.permissions);
      console.log(this.currentSearch)
    },
    async getPermissions(query) {
      if (query.length == 0) {
        this.currentSearch = this.permissions;
        return;
      }
      const response = await makeRequest("GET", "/settings/permissions?filter=name:" + query + "&limit=20");
      this.currentSearch = response.data;
    },
    async sumbit() {
      console.log(this.currentPermissions, this.currentSearch)
      console.log(this.name, this.description, this.hidden)
      if (this.mode === 'edit') {
        const request = {
          displayname: this.displayname,
          description: this.description,
          hidden_group: this.hidden_group,
          permissions: this.currentPermissions.map(item => item.name),
        };
        const response = await makeRequest("PUT", `/groups/${this.id}`, request);
      } else {
        const request = {
          displayname: this.displayname,
          description: this.description,
          hidden_group: this.hidden_group,
          permissions: this.currentPermissions.map(item => item.name),
        };
        const response = await makeRequest("POST", "/groups/", request);
        console.log(response)
      }

      this.$router.go(-1);
    },
  },
  computed: {
    isSmallScreen() {
      return this.windowWidth < 768;
    }
  },
};
</script>
<template>
  <h6 class="mb-0">Organisers</h6>
  <p class="mb-0 text-sm">Edit organisers for event</p>
  <hr class="my-3 horizontal dark" />
  <div class="row">
    <div class="form-group">
      <input type="text" class="form-control" aria-label="Search for users" aria-describedby="inputGroup-sizing-default"
        v-model="searchQuery" @input="search" placeholder="Search for team member">
    </div>
    <div class="col mb-4">
      <p class="mb-2 text-sm">Add yourself</p>
      <ul class="list-group">
        <li class="px-3 mb-2 border-3 list-group-item d-flex align-items-center">
          <user-avatar class="me-3" :id="self_user.avatar" alt="kal" border-radius="lg" shadow="regular" />
          <div class="d-flex align-items-start flex-column justify-content-center">
            <h6 class="mb-0 text-sm">{{ self_user.displayname }}</h6>
            <p class="mb-0 text-xs">{{ self_user.firstname }} {{ self_user.lastname }}</p>
          </div>
          <div class="d-flex align-items-center ms-auto">
            <a class="mb-0 btn btn-success" :class="{ 'btn-sm ps-3 pe-3': isSmallScreen }" href="javascript:;"
              v-if="!currentOrganisers.some(organiser => organiser.id === self_user.id)"
              @click="self_user.as = 'oc'; currentOrganisers.push(self_user)">{{ isSmallScreen ? '+' : 'Add' }}</a>
            <a class="mb-0 btn btn-primary ms-2" :class="{ 'btn-sm ps-3 pe-3': isSmallScreen }" href="javascript:;"
              v-if="!currentOrganisers.some(organiser => organiser.id === self_user.id)"
              @click="self_user.as = 'mainoc'; currentOrganisers.push(self_user)">
              {{ isSmallScreen ? '+' : 'Main OC' }}</a>
            <a class="mb-0 btn btn-link pe-3 ps-0" :class="{ 'btn-sm ps-3': isSmallScreen }" href="javascript:;"
              v-if="currentOrganisers.some(organiser => organiser.id === self_user.id)">
              {{ isSmallScreen ? 'Added' : 'Already added' }}</a>
          </div>
        </li>
      </ul>
      <p class="mb-2 text-sm">Search in the team</p>
      <ul class="list-group">
        <li class="px-3 mb-2 list-group-item d-flex align-items-center" v-if="currentSearch.length === 0">
          <i>Type in the search bar for users to appear</i>
        </li>
        <li class="px-3 mb-2 border-3 list-group-item d-flex align-items-center" v-for="(user, index) of currentSearch"
          :key="index">
          <user-avatar class="me-3" :id="user.avatar" alt="kal" border-radius="lg" shadow="regular" />
          <div class="d-flex align-items-start flex-column justify-content-center">
            <h6 class="mb-0 text-sm">{{ user.displayname }}</h6>
            <p class="mb-0 text-xs">{{ user.firstname }} {{ user.lastname }}</p>
          </div>
          <div class="d-flex align-items-center ms-auto">
            <a class="mb-0 btn btn-success" :class="{ 'btn-sm ps-3 pe-3': isSmallScreen }" href="javascript:;"
              v-if="!currentOrganisers.some(organiser => organiser.id === user.id)"
              @click="user.as = 'oc'; currentOrganisers.push(user)">{{ isSmallScreen ? '+' : 'Add' }}</a>
            <a class="mb-0 btn btn-link pe-3 ps-0" :class="{ 'btn-sm ps-3': isSmallScreen }" href="javascript:;"
              v-if="currentOrganisers.some(organiser => organiser.id === user.id)">
              {{ isSmallScreen ? 'Added' : 'Already added' }}</a>
          </div>
        </li>
      </ul>
    </div>
    <div class="col">
      <p class="mb-2 text-sm">Organiser list</p>
      <ul class="list-group">
        <li class="px-3 mb-2 border-3 list-group-item d-flex align-items-center"
          :class="organiser.as == 'mainoc' ? 'border-primary' : ''" v-for="(organiser, index) of currentOrganisers"
          :key="index">
          <div><user-avatar class="me-3" :id="organiser.avatar" alt="kal" border-radius="lg" shadow="regular" /></div>
          <div class="d-flex align-items-start flex-column justify-content-center">
            <h6 class="mb-0 text-sm">{{ organiser.displayname }} <span class="badge bg-gradient-primary"
                v-if="organiser.as == 'mainoc'">MAIN OC</span></h6>
            <p class="mb-0 text-xs">{{ organiser.firstname }} {{ organiser.lastname }}</p>
          </div>
          <div class="d-flex align-items-center ms-auto" v-if="organiser.as != 'mainoc'">
            <a class="mb-0 btn btn-primary ms-2" :class="{ 'btn-sm ps-3 pe-3 btn-inner--icon': isSmallScreen }"
              href="javascript:;" @click="changeOC(organiser)"><i class="ni ni-bold-up" v-if="isSmallScreen"></i>{{
          !isSmallScreen ? 'Main OC' : '' }}</a>
            <a class="mb-0 btn btn-danger ms-2" :class="{ 'btn-sm ps-3 pe-3': isSmallScreen }" href="javascript:;"
              @click="this.currentOrganisers.splice(this.currentOrganisers.findIndex(user => user.id == organiser.id), 1)">{{
          isSmallScreen ? 'X' : 'Remove' }}</a>
          </div>
        </li>
      </ul>
    </div>

  </div>
  <div class="row mx-1">
    <soft-alert :color="alertColor" :icon="alertIcon + ' ni-lg'" v-if="message">
      <strong>{{ message }}</strong>
    </soft-alert>
  </div>
</template>

<script>
import UserAvatar from "@/components/UserAvatar.vue";
import SoftAlert from "@/components/SoftAlert.vue";
import { makeRequest } from "@/api";

export default {
  name: "EditOrganisers",
  components: {
    UserAvatar,
    SoftAlert,
  },
  mounted() {
    console.log(this.id)
    this.getOrganisers();
    this.getUsers();
    this.getSelf();
    this.windowWidth = window.innerWidth;

    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  },
  data() {
    return {
      windowWidth: 0,
      message: '',
      alertColor: '',
      alertIcon: '',
      //currentOrganisersCount: 0,
      currentOrganisers: [],
      fullView: false,
      currentSearch: [],
      self_user: {},
    };
  },
  watch: {
    currentOrganisers: {
      handler: 'handleChange',
      deep: true,
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    async getSelf() {
      try {
        const response = await makeRequest("GET", `/users/me`);
        this.self_user = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getOrganisers() {
      if (this.id === null) return;
      try {
        const response = await makeRequest("GET", `/events/${this.id}/organisers`);
        this.currentOrganisers = response.data;
        this.fullView = this.currentOrganisers.some(user => user.change !== undefined && user.change == true)
        console.log(this.currentOrganisers, this.fullView)
      } catch (error) {
        console.log(error);
      }
    },
    search() {
      if (this.searchQuery.length > 2) {
        this.getUsers(this.searchQuery);
      } else {
        this.currentSearch = [];
      }
    },
    async getUsers(query) {
      const response = await makeRequest("GET", "/users/lookup?query=" + query + "&limit=8");
      this.currentSearch = response.data;
    },
    changeOC(organiser) {
      console.log(organiser);
      this.$swal({
        title: "Change Main OC?",
        text: "Once saved you cannot undo this action unless you have special permission!",
        showCancelButton: true,
        confirmButtonText: "Yes, change Main OC!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.currentOrganisers.forEach((user) => {
            if (user.as == "mainoc") {
              user.as = "oc";
            }
            if (user.id == organiser.id) {
              user.as = "mainoc";
            }
          });
          this.currentOrganisers.sort((a, b) => {
            if (a.displayname < b.displayname) return -1;
            if (a.displayname > b.displayname) return 1;
            return 0;
          });
          this.currentOrganisers.sort((a, b) => {
            if (a.as < b.as) return -1;
            if (a.as > b.as) return 1;
            return 0;
          });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal({
            title: "Cancelled!",
            text: "No changes were made.",
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    handleChange() {
      this.$emit('ocs', this.currentOrganisers);
    }
  },
  computed: {
    isSmallScreen() {
      return this.windowWidth < 768;
    }
  },
};
</script>
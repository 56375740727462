<template>
  <div class="container-fluid py-4 justify-content-center">
    <div class="row mt-4">
      <div class="col-12 col-xxl-8 mx-auto d-block">
        <div class="card mt-4" id="accounts">
          <div class="card-header">
            <h5>API Keys</h5>
            <p class="text-sm">Here you can setup and manage the API Keys of your section.</p>
          </div>
          <div class="card-body pt-0">
            <div class="d-flex mb-4">
              <h6 class="mb-0">API Keys</h6>
              <button class="btn btn-sm bg-gradient-primary my-auto ms-auto" type="button" name="button"
                @click="$router.push('api-keys/create')">Add new</button>
            </div>
            <div v-for="(key, index) of apiData" :key="index">
              <div class="d-flex">
                <!--<img class="width-48-px" src="../../../assets/img/small-logos/logo-slack.svg" alt="logo_slack">-->
                <div class="my-auto ms-3">
                  <div class="h-100">
                    <h5 class="mb-0">{{ key.description }}</h5>
                    <a class="text-sm text-body" href="javascript:;">Show less <i class="fas fa-chevron-up text-xs ms-1"
                        aria-hidden="true"></i></a>
                  </div>
                </div>
                <!--
                <p class="text-sm text-secondary ms-auto me-3 my-auto">{{ key.active ? 'Enabled' : 'Disabled' }} (TODO)
                </p>
                <div class="form-check form-switch my-auto">
                  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" :checked="key.active">
                </div>-->
              </div>
              <div class="ps-5 pt-3 ms-3">
                <p class="mb-0 text-sm">Please note: You cannot read API keys again, once created.</p>
                <div class="d-sm-flex bg-gray-100 border-radius-lg p-2 my-4">
                  <p class="text-sm font-weight-bold my-auto ps-sm-2">API Key</p>
                  <input class="form-control form-control-sm ms-sm-auto mt-sm-0 mt-2 w-sm-60 w-40" type="text"
                    value="emp/###############" data-bs-toggle="tooltip" data-bs-placement="top" title="Copy!">
                </div>
                <!--
                <div class="d-sm-flex bg-gray-100 border-radius-lg p-2 my-4">
                  <p class="text-sm font-weight-bold my-auto ps-sm-2">Assigned section</p>
                  <h6 class="text-sm ms-auto me-3 my-auto">ESN Uni Wien</h6>
                  <button class="btn btn-sm bg-gradient-danger my-sm-auto mt-2 mb-0" type="button"
                    name="button">Delete</button>
                </div>-->
                <div class="d-sm-flex bg-gray-100 border-radius-lg p-2 my-4">
                  <p class="text-sm font-weight-bold my-auto ps-sm-2">Valid until</p>
                  <h6 class="text-sm ms-auto me-3 my-auto">{{ this.formatDateTime(key.valid_until) }}</h6>
                </div>
              </div>
              <hr class="horizontal dark">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import Table from "./components/UsersTable.vue";
import { formatDateTime } from "@/dateAndTimeParsing";
import { makeRequest } from "@/api";

export default {
  name: "API Keys",
  components: {

  },
  data() {
    return {
      apiData: [],
    };
  },
  mounted() {
    this.getApiKeys();
  },
  methods: {
    async getApiKeys() {
      try {
        const response = await makeRequest("GET", "/auth/api-keys");
        this.apiData = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    formatDateTime(dateTime) {
      return formatDateTime(dateTime);
    }
  },
};
</script>
<template>
  <div class="card mb-4">
    <div class="p-3 card-body position-relative">
      <div class="row">
        <div class="col-12 text-start">
          <p class="mb-1 text-sm text-capitalize font-weight-bold">
            Event Status
          </p>
          <ul class="mx-auto list-unstyled">
            <li>
              <hr class="horizontal dark" />
            </li>
            <li class="d-flex">
              <p class="mb-0">Portal</p>
              <div class="ms-auto d-flex">
                <button type="button"
                  class="mb-0 btn btn-icon-only btn-rounded btn-sm d-flex align-items-center justify-content-center me-2"
                  v-if="this.status?.portal?.url">
                  <a :href="this.status?.portal?.url" target="_blank">
                    <i class="text-sm fas fa-link" :class="editMode ? 'text-primary' : 'text-secondary'"></i>
                  </a>
                </button>
                <span class="badge ms-auto cursor-pointer" :class="'badge-' + this.status?.portal?.displaycolour"
                  @click="showPortalStatus">{{
                    this.status?.portal?.stage
                  }}</span>
              </div>
            </li>
            <li>
              <hr class="horizontal dark" />
            </li>
            <li class="d-flex">
              <p class="mb-0">Pretix</p>
              <div class="ms-auto d-flex">
                <button type="button"
                  class="mb-0 btn btn-icon-only btn-rounded btn-sm d-flex align-items-center justify-content-center me-2"
                  v-if="this.status?.pretix?.url">
                  <a :href="this.status?.pretix?.url" target="_blank">
                    <i class="text-sm fas fa-link" :class="editMode ? 'text-primary' : 'text-secondary'"></i>
                  </a>
                </button>
                <span class="badge ms-auto cursor-pointer" :class="'badge-' + this.status?.pretix?.displaycolour"
                  @click="showPretixStatus">{{ this.status?.pretix?.stage
                  }}</span>
              </div>
            </li>
            <li>
              <hr class="horizontal dark" />
            </li>
            <li class="d-flex">
              <p class="mb-0">ESN Activities</p>
              <div class="ms-auto d-flex">
                <button type="button"
                  class="mb-0 btn btn-icon-only btn-rounded btn-sm d-flex align-items-center justify-content-center me-2"
                  v-if="this.status?.activities?.url">
                  <a :href="this.status?.activities?.url" target="_blank">
                    <i class="text-sm fas fa-link" :class="editMode ? 'text-primary' : 'text-secondary'"></i>
                  </a>
                </button>
                <span class="badge ms-auto cursor-pointer" :class="'badge-' + this.status?.activities?.displaycolour"
                  @click="showActivitiesStatus">{{
                    this.status?.activities?.stage }}</span>
              </div>
            </li>
            <li>
              <hr class="horizontal dark" />
            </li>
          </ul>
          <div v-if="this.actions.allow_evaluation">
            <button type="button" class="btn btn-success me-2" @click="evaluateEvent(true)">Accept event</button>
            <button type="button" class="btn btn-danger" @click="evaluateEvent(false)">Reject event</button>
          </div>
          <button class="btn btn-icon btn-3 btn-success me-2" type="button" v-if="this.actions.allow_submit"
            @click="submitEvent">
            <span class="btn-inner--icon"><i class="ni ni-button-play"></i></span>
            <span class="btn-inner--text"> Submit event</span>
          </button>
          <button class="btn btn-icon btn-3 btn-primary me-2" type="button" v-if="this.actions.allow_publish"
            @click="publishEvent()">
            <span class="btn-inner--icon"><i class="ni ni-button-play"></i></span>
            <span class="btn-inner--text"> Publish event</span>
          </button>
          <button type="button" class="btn btn-danger me-2" v-if="this.actions.allow_cancel">Cancel
            event</button>
          <button type="button" class="btn btn-danger me-2" v-if="this.actions.allow_delete"
            @click="deleteEvent()">Delete
            event</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { makeRequest } from "@/api";

export default {
  name: "StatusCard",
  props: {
    event_id: {
      type: String,
    },
  },
  data() {
    return {
      showMenu: false,
      apiData: {},
      status: {
        portal: {
          stage: "unknown",
          displaytext: "unknown",
          displaycolour: "secondary",
          displaydialogtext: ""
        },
        pretix: {
          stage: "unknown",
          displaytext: "unknown",
          displaycolour: "secondary",
          displaydialogtext: ""
        },
        activities: {
          stage: "unknown",
          displaytext: "unknown",
          displaycolour: "secondary",
          displaydialogtext: ""
        }
      },
      actions: {
        allow_delete: false,
        allow_cancel: false,
        allow_evaluation: false,
        allow_publish: false,
        allow_copy: false,
        allow_submit: false,
      }
    };
  },
  methods: {
    async submitEvent() {
      try {
        this.$swal({
          title: "Submit event",
          text: "Do you want to submit this event for evaluation? After this you cannot make any changes to your event anymore.",
          showCancelButton: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          reverseButtons: false,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            makeRequest("PUT", `/events/${this.event_id}/submit`, {}).then(
              (submitResponse) => {
                console.log("res", submitResponse)
                if (submitResponse.data.status == "submitted") {
                  this.$swal({
                    title: "Event submitted",
                    text: "The event has been submitted for approval",
                    icon: "success",
                    confirmButtonText: "Ok",
                  });
                  this.getEventStatus();
                } else {
                  this.$swal({
                    title: "Your event could not be submitted",
                    text: "If this error persists please call your technical support.",
                    icon: "warning",
                    confirmButtonText: "Ok",
                  });
                }
              }
            ).catch((error) => {
              this.$swal({
                title: "Your event could not be submitted",
                text: "If this error persists please call your technical support.",
                icon: "warning",
                confirmButtonText: "Ok",
              });
            })
          }
        });
      } catch (error) {
        this.$swal({
          title: "Your event could not be submitted",
          text: "If this error persists please call your technical support.",
          icon: "danger",
          confirmButtonText: "Ok",
        });
      }
    },
    async deleteEvent() {
      try {
        this.$swal({
          title: "Delete event",
          text: "Do you want to permanently delete this event? This action cannot be undone.",
          showCancelButton: true,
          confirmButtonText: "Cancel",
          cancelButtonText: "Confirm",
          reverseButtons: false,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (!result.isConfirmed) {
            makeRequest("DELETE", `/events/${this.event_id}`, {}).then(
              (submitResponse) => {
                if (submitResponse.data.status == "submitted") {
                  this.$swal({
                    title: "Event deleted",
                    text: "The event has been successfully deleted!",
                    icon: "success",
                    confirmButtonText: "Ok",
                  });
                } else {
                  this.$swal({
                    title: "The event could not be deleted",
                    text: "If this error persists please call your technical support.",
                    icon: "warning",
                    confirmButtonText: "Ok",
                  });
                }
              }
            ).catch((error) => {
              this.$swal({
                title: "The event could not be deleted",
                text: "If this error persists please call your technical support.",
                icon: "warning",
                confirmButtonText: "Ok",
              });
            })
          }
        });
      } catch (error) {
        this.$swal({
          title: "The event could not be deleted",
          text: "If this error persists please call your technical support.",
          icon: "danger",
          confirmButtonText: "Ok",
        });
      }
    },
    async evaluateEvent(evaluation) {
      if (evaluation) {
        try {
          this.$swal({
            title: "Accepting event",
            text: "Optional Comment:",
            input: "text",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Accept",
            showLoaderOnConfirm: true,
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-secondary ms-2",
            },
            buttonsStyling: false,
            allowOutsideClick: () => !this.$swal.isLoading(),
          }).then((result) => {
            if (result.isConfirmed) {
              makeRequest("PUT", `/events/${this.event_id}/evaluate`, {
                status: "accepted",
                comment: result.value,
              }).then(() => {
                this.getEventStatus();
                this.$swal({
                  title: "Success!",
                  text: "Event has been accepted",
                  icon: "info",
                  customClass: {
                    confirmButton: "btn bg-gradient-success",
                  },
                });
              });
            }
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          this.$swal({
            title: "Rejecting event",
            text: "Please provide a reason here:",
            input: "text",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Reject",
            showLoaderOnConfirm: true,
            customClass: {
              confirmButton: "btn bg-gradient-danger",
              cancelButton: "btn bg-gradient-secondary ms-2",
            },
            buttonsStyling: false,
            allowOutsideClick: () => !this.$swal.isLoading(),
          }).then((result) => {
            if (result.isConfirmed) {
              makeRequest("PUT", `/events/${this.event_id}/evaluate`, {
                status: "rejected",
                comment: result.value,
              }).then(() => {
                this.getEventStatus();
                this.$swal({
                  title: "Success!",
                  text: "The event has been rejected",
                  icon: "info",
                  customClass: {
                    confirmButton: "btn bg-gradient-success",
                  },
                });
              });
            }
          });
        } catch (error) {
          console.log(error);
        }
      }
    },
    async publishEvent() {
      try {
        this.$swal({
          title: "Publish event",
          text: "Do you want to publish the event to all platforms?",
          showCancelButton: true,
          confirmButtonText: "Publish now",
          cancelButtonText: "Cancel",
          reverseButtons: false,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-secondary",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            makeRequest("PUT", `/events/${this.event_id}/publish`, { is_public: true }).then(
              (submitResponse) => {
                if (submitResponse.data.status == "published") {
                  this.$swal({
                    title: "Event published",
                    text: "The event has been successfully published!",
                    icon: "success",
                    confirmButtonText: "Ok",
                  });
                  this.getEventStatus();
                } else {
                  this.$swal({
                    title: "The event could not be published",
                    text: "If this error persists please call your technical support.",
                    icon: "warning",
                    confirmButtonText: "Ok",
                  });
                }
              }
            ).catch((error) => {
              this.$swal({
                title: "The event could not be published",
                text: "If this error persists please call your technical support.",
                icon: "warning",
                confirmButtonText: "Ok",
              });
            })
          }
        });
      } catch (error) {
        this.$swal({
          title: "The event could not be published",
          text: "If this error persists please call your technical support.",
          icon: "danger",
          confirmButtonText: "Ok",
        });
      }
    },
    async getEventStatus() {
      try {
        const response = await makeRequest("GET", `/events/${this.event_id}/status`);
        this.apiData = response.data;

        const statusResponse = response.data;

        this.status = {};

        if (statusResponse.portal?.actions) {
          this.actions = statusResponse.portal.actions;
          console.log(this.actions)
        }

        if (statusResponse.portal.stage == "created") {
          this.status = {
            ...this.status,
            portal: {
              stage: "created",
              displaytext: "created",
              displaycolour: "info",
              displaydialogtext: "The event was created and is lacking information to be ready for evaluation."
            }
          }
        } else if (statusResponse.portal.stage == "ready") {
          this.status = {
            ...this.status,
            portal: {
              stage: "ready",
              displaytext: "ready",
              displaycolour: "info",
              displaydialogtext: "The event is ready to be evaluated. Please use the \"Submit event\" button to hand it in."
            }
          }
        } else if (statusResponse.portal.stage == "pending") {
          this.status = {
            ...this.status,
            portal: {
              stage: "pending",
              displaytext: "pending approval",
              displaycolour: "warning",
              displaydialogtext: "The event is currently under evaluation by the responsible managers."
            }
          }
        } else if (statusResponse.portal.stage == "accepted") {
          this.status = {
            ...this.status,
            portal: {
              stage: "accepted",
              displaytext: "accepted",
              displaycolour: "success",
              displaydialogtext: "The event was accepted and will soon be published." + (statusResponse.portal.evaluated_comment ? "Comment: " + statusResponse.portal.evaluated_comment : "")
            }
          }
        } else if (statusResponse.portal.stage == "rejected") {
          this.status = {
            ...this.status,
            portal: {
              stage: "rejected",
              displaytext: "rejected",
              displaycolour: "danger",
              displaydialogtext: "The event was rejected, with the following reason: " + (statusResponse.portal.evaluated_comment ? statusResponse.portal.evaluated_comment : "No reason provided.")
            }
          }
        } else if (statusResponse.portal.stage == "published") {
          this.status = {
            ...this.status,
            portal: {
              stage: "published",
              displaytext: "published",
              displaycolour: "success",
              displaydialogtext: "The event is published and can be attended via Pretix.",
              url: "http://esn-mp.com"
            }
          }
        } else if (statusResponse.portal.stage == "cancelled") {
          this.status = {
            ...this.status,
            portal: {
              stage: "cancelled",
              displaytext: "cancelled",
              displaycolour: "secondary",
              displaydialogtext: "The event was cancelled due to the following reason: " + (statusResponse.portal.cancelled_comment ? statusResponse.portal.cancelled_comment : "No reason provided."),
              url: "http://esn-mp.com"
            }
          }
        } else if (statusResponse.pretix.stage == "incomplete") {
          this.status = {
            ...this.status,
            portal: {
              stage: "incomplete",
              displaytext: "incomplete",
              displaycolour: "danger",
              displaydialogtext: "The event is lacking information that is required to count as \"finished\"",
              url: "http://esn-mp.com"
            }
          }
        } else if (statusResponse.pretix.stage == "finished") {
          this.status = {
            ...this.status,
            portal: {
              stage: "finished",
              displaytext: "finished",
              displaycolour: "primary",
              displaydialogtext: "The event is finished and all required data was provided.",
              url: "http://esn-mp.com"
            }
          }
        }

        if (statusResponse.pretix.stage == "not ready") {
          this.status = {
            ...this.status,
            pretix: {
              stage: "not ready",
              displaytext: "not ready",
              displaycolour: "info",
              displaydialogtext: "The event is not ready to be registered on Pretix."
            }
          }
        } else if (statusResponse.pretix.stage == "created") {
          this.status = {
            ...this.status,
            pretix: {
              stage: "created",
              displaytext: "created",
              displaycolour: "info",
              displaydialogtext: "The event was created on Pretix and will soon be published.",
              url: statusResponse.pretix.url
            }
          }
        } else if (statusResponse.pretix.stage == "published") {
          this.status = {
            ...this.status,
            pretix: {
              stage: "published",
              displaytext: "published",
              displaycolour: "success",
              displaydialogtext: "The event was published on Pretix and is available for participants.",
              url: statusResponse.pretix.url
            }
          }
        } else if (statusResponse.pretix.stage == "cancelled") {
          this.status = {
            ...this.status,
            pretix: {
              stage: "cancelled",
              displaytext: "cancelled",
              displaycolour: "danger",
              displaydialogtext: "The event was cancelled",
              url: statusResponse.pretix.url
            }
          }
        }

        if (statusResponse.activities.stage == "not ready") {
          this.status = {
            ...this.status,
            activities: {
              stage: "not ready",
              displaytext: "not ready",
              displaycolour: "info",
              displaydialogtext: "The event is not ready to be published on ESN Activites."
            }
          }
        } else if (statusResponse.activities.stage == "ready") {
          this.status = {
            ...this.status,
            activities: {
              stage: "ready",
              displaytext: "ready",
              displaycolour: "info",
              displaydialogtext: "The event is ready and will soon be published on ESN Activities."
            }
          }
        } else if (statusResponse.activities.stage == "published") {
          this.status = {
            ...this.status,
            activities: {
              stage: "published",
              displaytext: "published",
              displaycolour: "success",
              displaydialogtext: "The event was published on ESN Activities.",
              url: "http://esn-mp.com"
            }
          }
        } else if (statusResponse.activities.stage == "incomplete") {
          this.status = {
            ...this.status,
            activities: {
              stage: "incomplete",
              displaytext: "incomplete",
              displaycolour: "danger",
              displaydialogtext: "The event is lacking information to be completed on ESN Activities.",
              url: "http://esn-mp.com"
            }
          }
        } else if (statusResponse.activities.stage == "finished") {
          this.status = {
            ...this.status,
            activities: {
              stage: "finished",
              displaytext: "finished",
              displaycolour: "success",
              displaydialogtext: "The event was successfully registered on ESN Activties and is up to date.",
              url: "http://esn-mp.com"
            }
          }
        }


      } catch (error) {
        console.log(error);
      }
    },

    showPortalStatus() {
      this.$swal({
        title: "Portal Status",
        text: this.status.portal.displaydialogtext,
        icon: this.status.portal.displaycolour,
        confirmButtonText: "Ok",
      });
    },
    showPretixStatus() {
      this.$swal({
        title: "Pretix Status",
        text: this.status.pretix.displaydialogtext,
        icon: this.status.pretix.displaycolour,
        confirmButtonText: "Ok",
      });
    },
    showActivitiesStatus() {
      this.$swal({
        title: "Activities Status",
        text: this.status.activities.displaydialogtext,
        icon: this.status.activities.displaycolour,
        confirmButtonText: "Ok",
      });
    },
  },
  mounted() {
    this.getEventStatus();
  },
};
</script>

<template>
  <div class="container-fluid mt-4">
    <div class="row mb-5">
      <div class="col-lg-3">
        <side-nav />
      </div>
      <div class="col-lg-9 mt-lg-0 mt-4">
        <div id="profile" class="card card-body mt-4">
          <div class="row justify-content-center align-items-center">
            <div class="col-sm-auto col-4">
              <UserAvatar :id="apiData.avatar" alt="bruce" size="xl" shadow="sm" border-radius="lg" />
            </div>
            <div class="col-sm-auto col-8 my-auto">
              <div class="h-100">
                <h5 class="mb-1 font-weight-bolder">{{ apiData.displayname }}</h5>
                <p class="mb-0 font-weight-bold text-sm">{{ apiData.firstname }} {{ apiData.lastname }}</p>
              </div>
            </div>

            <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
              <!--
              <label class="form-check-label mb-0 me-2">
                <small id="profileVisibility">Switch to invisible</small>
              </label>
              <soft-switch id="profile" name="profileVisibility" checked />
              -->
            </div>

          </div>
        </div>

        <BasicInfo :user="apiData" />
        <ChangeAvatar :user="apiData" />
        <!--
        <CV />
        -->
        <ChangePassword :user_id="apiData.id" />
        <!--
        <TwoFactorAuthentication />
        <Notifications />
        <Accounts />
        <Calendars />
        <Sessions />
        <DeleteAccount />
        -->
      </div>
    </div>
  </div>
</template>

<script>
//import NavPill from "./components/NavPill.vue";
import SideNav from "./components/SideNav.vue";
import SideNavItem from "./components/SideNavItem.vue";
import Accounts from "./components/Accounts.vue";
import BasicInfo from "./components/BasicInfo.vue";
import ChangePassword from "./components/ChangePassword.vue";
import Notifications from "./components/Notifications.vue";
import TwoFactorAuthentication from "./components/TwoFactorAuthentication.vue";
import DeleteAccount from "./components/DeleteAccount.vue";
import Calendars from "./components/Calendars.vue";
import Sessions from "./components/Sessions.vue";
import CV from "./components/CV.vue";
import img from "../../../assets/img/bruce-mars.jpg";
import { makeRequest } from "@/api";
import SoftSwitch from "@/components/SoftSwitch.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import ChangeAvatar from "./components/ChangeAvatar.vue";

export default {
  name: "MyProfile",
  components: {
    //NavPill,
    SideNav,
    //Accounts,
    BasicInfo,
    ChangeAvatar,
    ChangePassword,
    //Notifications,
    //TwoFactorAuthentication,
    //DeleteAccount,
    //Calendars,
    //Sessions,
    //CV,
    //SoftSwitch,
    UserAvatar,
  },
  data() {
    return {
      img: img,
      apiData: {},
    };
  },
  methods: {
    async getSelfUser() {
      try {
        const response = await makeRequest("GET", "/users/me");
        this.apiData = response.data;
        console.log(this.apiData);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getSelfUser();
  },
};
</script>

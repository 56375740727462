<template>
  <div class="card multisteps-form__panel p-3 border-radius-xl bg-white" data-animation="FadeIn">
    <h5 class="font-weight-bolder">Location</h5>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <LocationsSelect :id="null" @values-updated="updateLocations" />
      </div>
      <div class="button-row d-flex mt-4">
        <soft-button type="button" color="light" variant="gradient" class="js-btn-prev"
          @click="this.$parent.prevStep">Prev</soft-button>
        <soft-button type="button" color="dark" variant="gradient" class="ms-auto js-btn-next"
          @click="this.$parent.nextStep">Next</soft-button>
      </div>
    </div>
  </div>
</template>

<script>
import LocationsSelect from "@/views/events/components/LocationsSelect.vue";
import SoftButton from "@/components/SoftButton.vue";
import { makeRequest } from "@/api";
//import NewLocation from "../../locations/NewLocation.vue";

export default {
  name: "Location",
  components: {
    //LocationsList,
    SoftButton,
    LocationsSelect,
    //NewLocation,
  },
  data() {
    return {
      headers: {
        name: true,
        description: true,
      },
      locations: [],
      selected_locations: [],
      location_info: {
        name: "...",
        id: String,
      },
      showNewLocation: false,
    }
  },
  created() {
    this.fetchLocations();
  },
  methods: {

    updateLocations(locations) {
      this.selected_locations = locations;
      this.$emit("values-updated", locations);
    },
    async fetchLocations() {
      const response = await makeRequest("GET", "/locations/");
      this.locations = response.data;
    },
    selectLocation(id, name) {
      this.location_info.id = id;
      this.location_info.name = name;
      this.scrollToBottom();
      this.$emit("values-updated", this.location_info);
    },
    createLocation() {
      console.log("Create new location selected...");
      this.showNewLocation = true;
      this.scrollToTop();
    },
    hideCreate(id, name) {
      if (id && name) {
        this.location_info.id = id
        this.location_info.name = name;
        this.scrollToBottom();
        this.fetchLocations();
      } else {
        this.location_info.id = null;
        this.location_info.name = "...";
        this.scrollToTop();
      }
      this.showNewLocation = false;

    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    scrollToBottom() {
      window.scrollTo(0, document.body.scrollHeight);
    },
  },

};
</script>

<template>
    <div class="container-fluid py-4 justify-content-center">
      <div class="row">
        <div class="col-12 col-xxl-8 mx-auto d-block">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center" style="height: 100%;">
                  <h6 class="mb-0">Partner sections</h6>
                  <button class="btn btn-sm bg-gradient-primary my-auto ms-auto" type="button" name="button" @click="$router.push('partner-sections/connect')">Add new</button>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xxl-8 mx-auto d-block">
          
          <div class="mt-4 card card-body">
            <div class="table-responsive">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Section</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">API Keys</th>
                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Added</th>
                    <th class="text-secondary opacity-7"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(section, index) of apiData" :key="index">
                    <td>
                      <div class="d-flex px-2 py-1">
                        <div>
                          <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/team-2.jpg" class="avatar avatar-lg me-3">
                        </div>
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-xs">{{ section.name }}</h6>
                          <p class="text-xs text-secondary mb-0">{{ section.tag }}</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">In / Out</p>
                      <p class="text-xs text-secondary mb-0">{{ section.apikey_in ? '1' : '0' }} / {{ section.apikey_out ? '1' : '0' }}</p>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <span class="badge badge-sm" :class="this.onlineSections.some(
                        (onlineSection) => onlineSection === section.id
                      ) ? 'badge-success' : 'badge-danger'">{{ this.onlineSections.some(
                        (onlineSection) => onlineSection === section.id
                      ) ? "Online" : "Offline"
                       }}</span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold">{{ this.formatDateTime(section.created_at) }}</span>
                    </td>
                    <td class="align-middle">
                      <a href="javascript:;" class="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user">
                        Edit
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  //import Table from "./components/UsersTable.vue";
  import { formatDateTime } from "@/dateAndTimeParsing";
  import { makeRequest } from "@/api";
  
  export default {
    name: "Partner Sections Settings",
    components: {

    },
    data() {
    return {
      apiData: [],
      onlineSections: [],
    };
    },
    mounted() {
      this.getPartnerSections();
    },
    methods: {
    async getPartnerSections() {
      try {
        const response = await makeRequest("GET", "/sections");
        this.apiData = response.data;
        this.apiData.forEach((section) => {
          this.pingSection(section);
        });
      } catch (error) {
        console.log(error);
      }
    },
    pingSection(section) {
      try {
        makeRequest("GET", `/sections/${section.id}/proxy?url=/users/me`).then((response) => {
          console.log(response)
          if (response.status === 200) {
            this.onlineSections.push(section.id);
          }
        });
      } catch (error) {
        return "Error";
      }
    },
    formatDateTime(dateTime) {
      return formatDateTime(dateTime);
    }
  },
  };
  </script>
  
<template>
  <ul class="list-group" v-if="groups.length > 0">
    <li class="list-group-item d-flex justify-content-between align-items-center"
      v-for="(selectedGroup, index) of groups" :key="index">
      <span>{{ this.getGroupFromId(selectedGroup).displayname }}</span>
      <div class="d-flex align-items-center">
        <span class="badge badge-pill me-2"
          :class="'badge-' + (this.getGroupFromId(selectedGroup).hidden_group ? 'secondary' : 'primary')">{{
            this.getGroupFromId(selectedGroup).permissions.length }}</span>
        <button type="button" @click="removeRole(index)" class="btn btn-danger m-0">
          -
        </button>
      </div>
    </li>
  </ul>
  <li class="px-3 list-group-item d-flex align-items-center" v-if="this.groups.length === 0">
    <i>Select a group from the list and add it using "+"</i>
  </li>
  <label class="mt-4" for="group">Choose group</label>
  <div class="d-flex">
    <select id="group" class="multisteps-form__select form-control flex-grow-1 me-2" v-model="selectedRole"
      @change="setCurrentPermissions()">
      <option v-for="(group, index) of apiData" :key="index" :value="group.id">{{ group.displayname }}</option>
    </select>
    <button type="button" class="btn btn-default mb-0 me-2 d-inline-flex" data-bs-toggle="collapse"
      data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
      <span>Permissions</span>
      <span class="badge badge-primary" :class="'badge-' + (this.hidden_group ? 'secondary' : 'primary')">{{
        this.currentPermissionCount > 0 ? this.currentPermissionCount : '' }}</span>
    </button>
    <button type="button" @click="addRole" class="btn btn-primary m-0">
      +
    </button>
  </div>
  <div class="accordion" id="accordionRental">
    <div class="accordion-item mb-3">
      <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
        data-bs-parent="#accordionRental" style="">
        <div class="accordion-body text-sm opacity-8">
          This group is {{ this.hidden_group ? 'hidden' : ' visible' }}.<br><br>
          <div v-for="(permission, index) of currentPermissions" :key="index">{{ permission }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import SoftButton from "@/components/SoftButton.vue";
import { makeRequest } from "@/api";

export default {
  name: "Groups",
  components: {
    //SoftButton,
  },
  mounted() {
    this.getGroups();
  },
  data() {
    return {
      apiData: [],
      groups: [],
      selectedRole: "",
      currentPermissionCount: 0,
      currentPermissions: [],
      hidden_group: false,
    };
  },
  methods: {
    addRole() {
      if (!this.groups.includes(this.selectedRole)) {
        this.groups.push(this.selectedRole);
        this.$emit('values-updated', this.groups);
      } else {
        console.log('This group is already assigned.');
      }
    },
    removeRole(index) {
      if (this.groups.length > 0) {
        this.groups.splice(index, 1);
      }
    },
    async getGroups() {
      try {
        const response = await makeRequest("GET", "/groups?filter=hidden_group:false");
        const response2 = await makeRequest("GET", "/groups?filter=hidden_group:true");

        this.apiData = response.data.data;
        this.apiData = this.apiData.concat(response2.data.data);
      } catch (error) {
        console.log(error);
      }
    },
    getGroupFromId(id) {
      return this.apiData.find((group) => group.id === id);
    },
    setCurrentPermissions() {
      this.currentPermissions = this.getGroupFromId(this.selectedRole).permissions;
      this.currentPermissionCount = this.getGroupFromId(this.selectedRole).permissions.length;
      this.hidden_group = this.getGroupFromId(this.selectedRole).hidden_group;
    },
  },
};
</script>
<template>
  <div class="card mtb-4 mb-4">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">Statements of cost</h6>
        </div>
        <div class="col-6 text-end">
          <button type="button"
            class="mb-0 btn btn-icon-only btn-rounded btn-outline-secondary ms-2 btn-sm d-flex align-items-center justify-content-center ms-auto"
            data-bs-toggle="tooltip" data-bs-placement="top" title="Hand in statements of cost">
            <a href="#" @click="$router.push('/statements-of-cost/new?event_id=' + $route.params.id)">
              <i class="text-sm fas fa-plus text-secondary"></i>
            </a>
          </button>
        </div>
      </div>
    </div>
    <div class="card-body p-3 pb-0 mb-0">
      <ul class="list-group">
        <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg" v-for="(
            soc,
              index
          ) of apiData" :key="index">
          <div class="d-flex flex-column">
            <h6 class="mb-1 text-dark font-weight-bold text-sm">
              {{ soc.name }}
              <span class="badge bg-gradient-success" v-if="soc.status == 'accepted'">APPROVED</span>
              <span class="badge bg-gradient-warning" v-if="soc.status == 'pending'">PENDING APPROVAL</span>
              <span class="badge bg-gradient-danger" v-if="soc.status == 'rejected'">REJECTED</span>
            </h6>
            <span class="text-xs">by {{ soc.created_by.displayname }}</span>
          </div>
          <div class="d-flex align-items-center text-sm">
            € {{ renderMoney(soc.amount) }}
            <button class="btn btn-link text-dark text-sm mb-0 px-0 ms-4"
              @click="$router.push('/statements-of-cost/' + soc.id)">
              <i class="fas fa-file text-lg me-1" aria-hidden="true"></i>
              VIEW
            </button>
          </div>
        </li>
      </ul>
      <PaginationNav :endpoint="'/events/' + event_id + '/statements-of-cost'" :itemsPerPage="5" :visiblePages="5"
        @page-changed="changePage" />
    </div>
  </div>
</template>

<script>
//import SoftButton from "@/components/SoftButton.vue";
import { makeRequest } from '@/api';
import PaginationNav from '@/components/PaginationNav.vue';

export default {
  name: "StatementOfCostCard",
  components: {
    //SoftButton,
    PaginationNav,
  },
  data() {
    return {
      apiData: [],
      sum: 0,
    };
  },
  mounted() {
  },
  props: {
    event_id: {
      type: String,
      default: "",
    },
  },
  methods: {
    renderMoney(amount) {
      return ("" + (amount / 100).toFixed(2)).replace(".", ",");
    },
    changePage(page) {
      this.apiData = page;
    },
  },
};
</script>

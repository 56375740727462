<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <nav
          class="top-0 navbar navbar-expand-lg position-absolute z-index-3 blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow">
          <div class="container">
            <router-link class="navbar-brand font-weight-bolder ms-lg-0 ms-3 text-black" to="/">Management
              Portal</router-link>
            <div class="d-flex justify-content-end">
              <div id="navigation" class="pt-3 pb-2 collapse navbar-collapse py-lg-0">
                ESN Uni Wien
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-5 card card-plain">
                <div class="card-header pb-0 text-left">
                  <h3 class="font-weight-bolder text-success text-gradient">
                    Join us today
                  </h3>
                  <p class="mb-0">Enter your contact details and a short decription about your self and your motivation!
                  </p>
                </div>
                <div class="card-body pb-3">
                  <form role="form">
                    <label>Name</label>
                    <soft-input id="name" type="text" placeholder="Name" aria-label="Name" />
                    <label>Email</label>
                    <soft-input id="email" type="email" placeholder="Email" aria-label="Email" />
                    <label>You & Motivation</label>
                    <soft-textarea id="application" placeholder="Enter your text here" aria-label="You & Motivation" />
                    <soft-checkbox id="flexCheckDefault" name="terms" class="font-weight-light" checked>
                      I agree the
                      <a href="#" class="text-dark font-weight-bolder">Terms and Conditions</a>
                    </soft-checkbox>
                    <div class="text-center">
                      <soft-button color="success" variant="gradient" full-width class="mt-4 mb-0">Sign
                        up</soft-button>
                    </div>
                  </form>
                </div>
                <div class="card-footer text-center pt-0 px-sm-4 px-1">
                  <p class="mb-4 mx-auto">
                    Already have an account?
                    <router-link :to="{ name: 'Signin Cover' }" class="text-success text-gradient font-weight-bold">
                      Sign in
                    </router-link>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8">
                <div class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" :style="{
                  backgroundImage:
                    'url(' +
                    require('@/assets/img/curved-images/curved11.jpg') +
                    ')',
                }"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <app-footer />
</template>

<script>
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftTextarea from "@/components/SoftTextarea.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftButton from "@/components/SoftButton.vue";

const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";
export default {
  name: "Signup Cover",
  components: {
    AppFooter,
    SoftInput,
    SoftCheckbox,
    SoftButton,
    SoftTextarea,
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
  },
};
</script>

<template>
  <div id="sidenav-collapse-main" class="w-auto h-auto collapse navbar-collapse max-height-vh-80">
    <ul class="navbar-nav">
      <li class="nav-item">
        <SidenavMainItem collapse-ref="home" :collapse="false" text="Home"
          :class="getRoute() === 'home' ? 'active' : ''" to="/">
          <template #icon>
            <Shop />
          </template>

        </SidenavMainItem>
      </li>
      <li class="mt-3 nav-item">
        <h6 class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6 ms-2">
          {{ section.section_name }}
        </h6>
      </li>

      <li class="nav-item" v-if="this.views.includes('tmpc')">
        <SidenavMainItem text="Positions & coordinators" :class="getRoute() === 'positions' ? 'active' : ''"
          :to="{ name: 'Positions' }">
          <template #icon>
            <UsersGroup />
          </template>
        </SidenavMainItem>
      </li>

      <li class="nav-item" v-if="this.views.includes('gptm')">
        <sidenav-collapse collapse-ref="teammmembers" nav-text="Team members"
          :class="getRoute() === 'teams' ? 'active' : ''">
          <template #icon>
            <UsersGroup />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item v-for="(value, index) in groups" :key="index"
                :to="{ name: 'TeamView', params: { id: value.id } }" mini-icon="P" :text="value.displayname" />
              <!--
                <sidenav-item :to="{ name: 'New Project' }" mini-icon="P" text="Applications [#only HR]" />
                -->

            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item" v-if="this.views.includes('events')">
        <sidenav-collapse collapse-ref="events" nav-text="Events" :class="getRoute() === 'events' ? 'active' : ''">
          <template #icon>
            <Confetti />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'New Event' }" mini-icon="K" text="Create event" />
              <sidenav-item :to="{ name: 'Events', props: { own: false } }" mini-icon="K" text="Planned events" />
              <sidenav-item :to="{ name: 'Events', props: { own: false } }" mini-icon="K" text="Event drafts" />
              <sidenav-item :to="{ name: 'Events' }" mini-icon="K" text="Past events" />


            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item" v-if="this.views.includes('events')">
        <sidenav-collapse collapse-ref="locations" nav-text="Locations"
          :class="getRoute() === 'locations' ? 'active' : ''">
          <template #icon>
            <Location />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'NewLocation' }" mini-icon="K" text="Create Location" />
              <sidenav-item :to="{ name: 'SavedLocations' }" mini-icon="K" text="Saved Locations" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item" v-if="this.views.includes('esnc')">
        <sidenav-collapse collapse-ref="esncards" nav-text="ESNcards"
          :class="getRoute() === 'ecommerce' ? 'active' : ''">
          <template #icon>
            <Basket />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'ESNcardShopRequests' }" mini-icon="O" text="Requests"
                :badgeNumber="status.menus.notifications.esnc_requests" />
              <sidenav-item :to="{ name: 'ESNcardShopShipments' }" mini-icon="O" text="Shipments"
                :badgeNumber="status.menus.notifications.esnc_shipments" />
              <sidenav-item :to="{ name: 'ESNcardShopPickups' }" mini-icon="O" text="Pick-up" />
              <sidenav-item :to="{ name: 'ESNcardShopStacks' }" mini-icon="O" text="Stacks" />
              <sidenav-item :to="{ name: 'CheckValidityESNcard' }" mini-icon="O" text="Check validity" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item" v-if="this.views.includes('office') && false">
        <sidenav-collapse collapse-ref="office" nav-text="Office" :class="getRoute() === 'ecommerce' ? 'active' : ''">
          <template #icon>
            <Office />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Overview' }" mini-icon="O" text="Shifts" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item" v-if="this.views.includes('tasks')">
        <sidenav-collapse collapse-ref="tasks" nav-text="Tasks" :class="getRoute() === 'ecommerce' ? 'active' : ''">
          <template #icon>
            <Tick />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Overview' }" mini-icon="O" text="General" />
              <sidenav-item :to="{ name: 'Overview' }" mini-icon="O" text="Events" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item" v-if="this.views.includes('fincs')">
        <sidenav-collapse collapse-ref="finances" nav-text="Finances [#Treasurer]"
          :class="getRoute() === 'ecommerce' ? 'active' : ''">
          <template #icon>
            <CreditCard />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Invoices (in)' }" mini-icon="O" text="Invoices (in)" badgeNumber="0" />
              <sidenav-item :to="{ name: 'Statements of Cost' }" mini-icon="O" text="Statements of cost"
                badgeNumber="0" />
              <sidenav-item :to="{ name: 'Invoices (out)' }" mini-icon="O" text="Invoices (out)" badgeNumber="0" />
              <sidenav-item :to="{ name: 'Reimbursements' }" mini-icon="O" text="Reimbursements" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <!--
      <li class="nav-item" v-if="this.views.includes('suce')">
        <sidenav-collapse collapse-ref="supportcenter" nav-text="Support Center"
          :class="getRoute() === 'ecommerce' ? 'active' : ''">
          <template #icon>
            <CustomerSupport />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <sidenav-item :to="{ name: 'Overview' }" mini-icon="O" text="Instagram" badgeNumber="3" />
              <sidenav-item :to="{ name: 'Overview' }" mini-icon="O" text="E-Mail" badgeNumber="4" />
              <sidenav-item :to="{ name: 'Overview' }" mini-icon="O" text="WhatsApp" badgeNumber="10" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      -->

      <li class="nav-item" v-if="this.views.includes('view_supportcenter')">
        <SidenavMainItem text="Support Center"
          :to="{ name: 'CSSO', query: { rdr_url: 'https://support.esnuniwien.com/auth/sso' } }">
          <template #icon>
            <CustomerSupport />
          </template>
        </SidenavMainItem>
      </li>

      <li class="nav-item" v-if="this.views.includes('view_wiki')">
        <sidenav-collapse nav-text="Section Wiki" :collapse="false" url="#" :aria-controls="''"
          collapse-ref="https://wiki.esnuniwien.com/">
          <template #icon>
            <Document />
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item" v-if="this.views.includes('pwds')">
        <sidenav-collapse nav-text="Access & Passwords" :collapse="false" url="#" :aria-controls="''"
          collapse-ref="https://github.com/creativetimofficial/ct-soft-ui-dashboard-pro/blob/main/CHANGELOG.md">
          <template #icon>
            <Key />
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item" v-if="this.views.includes('crts')">
        <SidenavMainItem text="Certificates" :to="{ name: 'MyProfile' }">
          <template #icon>
            <Document />
          </template>
        </SidenavMainItem>
      </li>

      <li class="nav-item" v-if="this.views.includes('stats')">
        <sidenav-collapse collapse-ref="statistics" nav-text="Statistics"
          :class="getRoute() === 'ecommerce' ? 'active' : ''">
          <template #icon>
            <Statistics />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Overview' }" mini-icon="O" text="Overview" />
              <sidenav-item :to="{ name: 'Overview' }" mini-icon="O" text="Specific 1" />
              <sidenav-item :to="{ name: 'Overview' }" mini-icon="O" text="Specific 2" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="mt-3 nav-item">
        <hr class="mt-0 horizontal dark" />
        <h6 class="text-xs ps-4 ms-2 text-uppercase font-weight-bolder opacity-6 ms-2">
          PERSONAL
        </h6>
      </li>
      <li class="nav-item" v-if="this.views.includes('msg')">
        <SidenavMainItem text="My Messages" :to="{ name: 'MyProfile' }" badgeNumber="1">
          <template #icon>
            <Chat />
          </template>
        </SidenavMainItem>
      </li>
      <li class="nav-item" v-if="this.views.includes('events')">
        <SidenavMainItem text="My Events" :to="{ name: 'Events', props: { own: true } }">
          <template #icon>
            <GettingStarted />
          </template>
        </SidenavMainItem>
      </li>
      <li class="nav-item" v-if="this.views.includes('fincs')">
        <sidenav-collapse collapse-ref="myfinances" nav-text="My Finances"
          :class="getRoute() === 'ecommerce' ? 'active' : ''">
          <template #icon>
            <CreditCard />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Statements of Cost' }" mini-icon="O" text="Statements of Cost" />
              <sidenav-item :to="{ name: 'NewStatementOfCost' }" mini-icon="O" text="New Statement of Cost" />
              <sidenav-item :to="{ name: 'NewInvoice' }" mini-icon="O" text="New Invoice" />
              <sidenav-item :to="{ name: 'Overview' }" mini-icon="O" text="Reimbursements" />
              <sidenav-item :to="{ name: 'Overview' }" mini-icon="O" text="Accounts for Payout" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="this.views.includes('tasks')">
        <sidenav-collapse nav-text="My Todos" :collapse="false" url="#" :aria-controls="''"
          collapse-ref="https://github.com/creativetimofficial/ct-soft-ui-dashboard-pro/blob/main/CHANGELOG.md">
          <template #icon>
            <Tick />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <SidenavMainItem text="My Profile" :to="{ name: 'MyProfile' }">
          <template #icon>
            <User />
          </template>
        </SidenavMainItem>
      </li>
      <li class="nav-item" v-if="this.views.includes('crts')">
        <SidenavMainItem text="My Certificates" :to="{ name: 'MyProfile' }">
          <template #icon>
            <Document />
          </template>
        </SidenavMainItem>
      </li>

      <li class="mt-3 nav-item" v-if="this.views.includes('view_admin')">
        <hr class="mt-0 horizontal dark" />
        <h6 class="text-xs ps-4 ms-2 text-uppercase font-weight-bolder opacity-6 ms-2">
          ADMINISTRATION
        </h6>
      </li>

      <li class="nav-item" v-if="this.views.includes('view_admin_soon')">
        <SidenavMainItem text="Settings" :to="{ name: 'AppSettings' }">
          <template #icon>
            <Settings />
          </template>
        </SidenavMainItem>
      </li>

      <li class="nav-item" v-if="this.views.includes('view_admin_soon')">
        <sidenav-collapse collapse-ref="rewards" nav-text="Rewards" :class="getRoute() === 'rewards' ? 'active' : ''">
          <template #icon>
            <GettingStarted />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'UsersList' }" mini-icon="O" text="Certificates" />
              <sidenav-item :to="{ name: 'New User' }" mini-icon="O" text="Reward System" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item" v-if="this.views.includes('view_admin')">
        <sidenav-collapse collapse-ref="manageusers" nav-text="Manage Users"
          :class="getRoute() === 'ecommerce' ? 'active' : ''">
          <template #icon>
            <User />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'UsersList' }" mini-icon="O" text="See all users" />
              <sidenav-item :to="{ name: 'New User' }" mini-icon="O" text="Create new user" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="this.views.includes('view_admin')">
        <sidenav-collapse collapse-ref="managegroups" nav-text="Manage Groups"
          :class="getRoute() === 'ecommerce' ? 'active' : ''">
          <template #icon>
            <UsersGroup />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'GroupsList' }" mini-icon="O" text="See all groups" />
              <sidenav-item :to="{ name: 'Create group' }" mini-icon="O" text="Create new group" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="this.views.includes('view_admin')">
        <sidenav-collapse collapse-ref="externalconnections" nav-text="External access"
          :class="getRoute() === 'ecommerce' ? 'active' : ''">
          <template #icon>
            <Wifi />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Partner Section Settings' }" mini-icon="O" text="Partner sections" />
              <sidenav-item :to="{ name: 'API Keys' }" mini-icon="O" text="API Keys" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="mt-3 nav-item" v-if="this.views.includes('dev')">
        <hr class="mt-0 horizontal dark" />
        <h6 class="text-xs ps-4 ms-2 text-uppercase font-weight-bolder opacity-6 ms-2">
          Development
        </h6>
      </li>
      <li class="nav-item" v-if="this.views.includes('dev')">
        <sidenav-collapse collapse-ref="development" nav-text="Development"
          :class="getRoute() === 'development' ? 'active' : ''">
          <template #icon>
            <Basket />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'DetailsEventPublic' }" mini-icon="O" text="Event Details Public" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>

</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavMainItem from "./SidenavMainItem.vue";
//import SidenavCard from "./SidenavCard.vue";
//import SidenavCollapseItem from "./SidenavCollapseItem.vue";
import Settings from "../../components/Icon/Settings.vue";
import Basket from "../../components/Icon/Basket.vue";
import Shop from "../../components/Icon/Shop.vue";
import Office from "../../components/Icon/Office.vue";
import CreditCard from "../../components/Icon/CreditCard.vue";
import Location from "../../components/Icon/Location.vue";
import CustomerSupport from "../../components/Icon/CustomerSupport.vue";
import Wifi from "../../components/Icon/Wifi.vue";
import GettingStarted from "../../components/Icon/GettingStarted.vue";
import Tick from "../../components/Icon/Tick.vue";
import Confetti from "../../components/Icon/Confetti.vue";
import UsersGroup from "../../components/Icon/UsersGroup.vue";
import Chat from "../../components/Icon/Chat.vue";
import Key from "../../components/Icon/Key.vue";
import Statistics from "../../components/Icon/Statistics.vue";
import User from "../../components/Icon/User.vue";
import Document from "../../components/Icon/Document.vue";
//import Side from "../../components/Icon/Side.vue";
import { makeRequest } from "../../api";

import { mapState } from "vuex";
import { Side } from "three";
import Home from "../../views/Home.vue";
export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,
    SidenavMainItem,
    //SidenavCard,
    //SidenavCollapseItem,
    Settings,
    Basket,
    Shop,
    Office,
    CreditCard,
    Location,
    CustomerSupport,
    Wifi,
    GettingStarted,
    Tick,
    Confetti,
    UsersGroup,
    Chat,
    Key,
    Statistics,
    User,
    Document,
    //Side
  },
  props: {
    cardBg: {
      type: String,
      default: "",
    },
  },
  computed: {
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
  data() {
    return {
      section: [],
      groups: [],
      views: [],
      status: {}
    };
  },
  mounted() {
    makeRequest("get", "/public/").then((response) => {
      this.section = response.data;
      document.title = this.section.section_name + ' - Portal'
    });
    makeRequest("get", "/groups").then((response) => { //TODO filter by displayname
      this.groups = response.data.data;
    });
    makeRequest("get", "/users/me/status").then((response) => { // TODO only filter menu points, or load globally
      this.views = response.data.menus.displayed;
      this.status = response.data;
      console.log(this.views)
    });
  },
};
</script>

<template>
  <div class="card">
    <div class="table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              Code
            </th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
              Section
            </th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
              Active
            </th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              Activation Date
            </th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              TId
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(result, index) of this.history" :key="index">
            <td>
              <div class="d-flex px-2 py-1">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{ result.code }}</h6>
                </div>
              </div>
            </td>
            <td>
              <p class="text-sm text-secondary mb-0">{{ result.section_code }}</p>
            </td>
            <td>
              <span class="badge badge-dot me-4">
                <i class="bg-danger" v-if="result.status == 'expired'"></i>
                <i class="bg-info" v-if="result.status == 'available'"></i>
                <i class="bg-success" v-if="result.status == 'active'"></i>
                <span class="text-dark text-xs">{{ result.status }}</span>
              </span>
            </td>
            <td class="align-middle text-center text-sm">
              <p class="text-secondary mb-0 text-sm">{{ result.activation_date ? this.formatDate(result.activation_date)
                : 'not activated' }}</p>
            </td>
            <td class="align-middle text-center text-sm">
              <p class="text-secondary mb-0 text-sm">{{ result.tid }}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/dateAndTimeParsing";
import { makeRequest } from "@/api";

export default {
  name: "Table",
  components: {
  },

  data() {
    return {
      history: [],
    };
  },

  methods: {
    formatDate,
    async fetchHistory() {
      const response = await makeRequest("GET", "/office/esncards/history");
      this.history = response.data;
    },
  },
  created() {
    this.fetchHistory();
  },


};
</script>

<template>
  {{ message }}
</template>

<script>
import { oidcURL, CSSOAllowedURLs } from "@/api";

export default {
  name: "CSSO",
  components: {
  },
  data() {
    return {
      message: "You will be redirected..."
    }
  },
  created() {
    const rdr_url = this.$route.query.rdr_url;

    if (rdr_url && CSSOAllowedURLs.includes(rdr_url)) {
      window.location.href = `${rdr_url}?user=${localStorage.getItem('jwtToken')}`
    } else {
      this.message = "Invalid parameters."
    }

  },
  beforeUnmount() {
  },
  methods: {

  },
};
</script>

<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <nav
          class="top-0 navbar navbar-expand-lg position-absolute z-index-3 blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow">
          <div class="container">
            <router-link class="navbar-brand font-weight-bolder ms-lg-0 ms-3 text-black" to="/">Management
              Portal</router-link>
            <div class="d-flex justify-content-end">
              <div id="navigation" class="pt-3 pb-2 collapse navbar-collapse py-lg-0">
                ESN Uni Wien
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder text-success text-gradient">
                    Welcome back
                  </h3>
                  <p class="mb-0">Enter your email and password to sign in</p>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start" @submit.prevent="signin()">
                    <label>Email</label>
                    <input type="text" class="form-control" id="email" isRequired="true" placeholder="Email"
                      v-model="formData.email">
                    <label>Password</label>
                    <input type="password" class="form-control mb-4" id="password" isRequired="true"
                      placeholder="Password" v-model="formData.password">
                    <!--
                    <soft-switch id="rememberMe" name="rememberMe" checked>
                      Remember me
                    </soft-switch>
                    -->
                    <div class="text-center">
                      <soft-button class="my-4 mb-2" variant="gradient" color="success" full-width>Sign in
                      </soft-button>
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Don't have an account?
                    <router-link :to="{ name: 'Signup Cover' }" class="text-success text-gradient font-weight-bold">Sign
                      up</router-link>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8">
                <div class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" :style="{
                  backgroundImage:
                    'url(' +
                    require('@/assets/img/curved-images/curved9.jpg') +
                    ')',
                }"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftInput from "@/components/SoftInput.vue";
//import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftButton from "@/components/SoftButton.vue";
const body = document.getElementsByTagName("body")[0];
import { baseURL } from "@/api";

import { mapMutations } from "vuex";


export default {
  name: "SigninCover",
  components: {
    // Navbar,
    AppFooter,
    //SoftInput,
    //SoftSwitch,
    SoftButton,
  },
  data() {
    return {
      formData: {
        email: '',
        password: '',
      },
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async signin() {
      const response = await fetch(baseURL + '/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.formData)
      });

      const data = await response.json();

      if (response.status === 200) {
        localStorage.setItem('jwtToken', data.token);
        localStorage.setItem('jwtTokenExpireTime', new Date().getTime() + (20 * 60 * 1000)); // token expires in 20 minutes
        localStorage.setItem('isAuthenticated', true);
        this.$store.dispatch("checkAuthentication");
        this.$router.push('/home');
      } else {
        this.$swal({
          icon: "warning",
          title: "Cannot login",
          text: "Invalid login credentials. Please try again.",
          type: "warning-message",
        });
      }
    },
  },
};
</script>

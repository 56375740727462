<template>
  <div
    class="card multisteps-form__panel p-3 border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Avatar</h5>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col">
          <label for="avatarUpload">Upload Avatar</label>
          <input
            id="avatarUpload"
            class="form-control"
            type="file"
            accept="image/*"
            @change="previewFile"
          />
        </div>
      </div>
      <hr class="my-4" />
      <div class="row mt-3">
        <div class="col d-flex justify-content-center">
          <div class="image-container">
            <canvas id="avatarCanvas" class="img-fluid" alt="Avatar preview"></canvas>
          </div>
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <soft-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="this.$parent.prevStep"
        >Prev</soft-button>
        <soft-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto js-btn-next"
          @click="this.$parent.nextStep"
        >Next</soft-button>
      </div>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import { makeRequest } from "@/api";

export default {
  name: "Avatar",
  components: {
    SoftButton,
  },
  methods: {
    async previewFile(event) {
      const input = event.target;
      if (input.files && input.files[0]) {
        this.$emit('values-updated', input.files[0]);

        const reader = new FileReader();

        reader.onload = (e) => {
          const image = new Image();
          image.src = e.target.result;

          image.onload = () => {
            const canvas = document.getElementById('avatarCanvas');
            const ctx = canvas.getContext('2d');
            const size = Math.min(image.width, image.height);

            canvas.width = size;
            canvas.height = size;

            ctx.drawImage(
              image,
              (image.width - size) / 2,
              (image.height - size) / 2,
              size,
              size,
              0,
              0,
              size,
              size
            );

            canvas.toBlob((blob) => {
              const croppedFile = new File([blob], input.files[0].name, {
                type: input.files[0].type,
              });

              this.$emit('values-updated', croppedFile);
            }, input.files[0].type);
          };
        };

        reader.readAsDataURL(input.files[0]);
      }
    }
  },
};
</script>

<style>
.image-container {
  overflow: hidden;
}

#avatarCanvas {
  max-height: 200px;
  width: auto;
  height: auto;
  display: block;
  margin: 0 auto;
}
</style>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="mx-auto col-lg-9 col-12">
        <div class="mt-4 card card-body">
          <h6 class="mb-0">ESNcard</h6>
          <p class="mb-0 text-sm">Check the status of an ESNcard</p>
          <hr class="my-3 horizontal dark" />
          <label for="projectName" class="form-label">Enter Number</label>
          <input id="projectName" type="text" class="form-control" v-model="esnnumber" />
          <div class="row mt-4">
            <div class="col-12">
              <Table :key="this.tableKey" />
            </div>
          </div>

          <div class="mt-4 d-flex justify-content-end">
            <button type="button" name="button" class="m-0 btn bg-gradient-success ms-2"
              @click="CheckValidityESNcard()">
              Check Card
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Table from "./components/Table.vue";
import { formatDate } from "@/dateAndTimeParsing";
import { makeRequest } from "@/api";

export default {
  name: "CheckValidityESNcard",
  components: {
    Table
  },
  data() {
    return {
      esnnumber: '',
      tableKey: 0,
    };
  },
  mounted() {

  },

  methods: {
    async CheckValidityESNcard() {
      try {
        const response = await makeRequest("GET", "/office/esncards/status?code=" + this.esnnumber);
        this.tableKey += 1;
      } catch (error) {

      }
    }
  }



};


</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="mx-auto col-lg-9 col-12">
        <div class="mt-0 mb-4 card card-body">
          <h6 class="mb-0">Event</h6>
          <p class="mb-0 text-sm">{{ event_name }}</p>
        </div>
        <div class="mt-0 card card-body">
          <LocationsSelect :id="id" @values-updated="updateLocations" />
          <div class="d-flex justify-content-end mt-4">
            <button type="button" name="cancel" class="m-0 btn btn-light" @click="cancel()">
              Cancel
            </button>
            <button type="button" name="submit" class="m-0 btn bg-gradient-success ms-2" @click="submit">
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { makeRequest } from "@/api";
import LocationsSelect from "./components/LocationsSelect.vue";

export default {
  name: "EditLocations",
  components: {
    LocationsSelect,
  },
  mounted() {
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  },
  data() {
    return {
      windowWidth: 0,
      message: '',
      alertColor: '',
      alertIcon: '',
      currentLocations: [],
      fullView: false,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    event_name: {
      type: String,
      required: false,
    },
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    async submit() {
      const locations = this.currentLocations.map(location => ({
        id: location.id,
        meeting_point: location.meeting_point ? location.meeting_point : false,
        main_location: location.main_location ? location.main_location : false,
        location_details: location.location_details ? location.location_details : "",
      }));
      const request = {
        locations: locations
      };
      const response = await makeRequest("PUT", `/events/${this.id}`, request)
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateLocations(locations) {
      this.currentLocations = locations;
    },
  },
};
</script>
<template>
  <div class="p-3 bg-white card multisteps-form__panel border-radius-xl" data-animation="FadeIn">
    <h5 class="font-weight-bolder">Basic Event Info</h5>
    <div class="multisteps-form__content">
      <div class="mt-3 row">
        <div class="col-12">
          <label>Event Name</label>
          <input class="multisteps-form__input form-control" type="text" placeholder="eg. Hiking with ESN"
            :value="basic_info.name" @input="basic_info.name = $event.target.value; updateValues()" />
          <label class="mt-4">Description</label>
          <p class="text-xs form-text text-muted ms-1 d-inline"></p>
          <div id="edit-description" class="h-100"
            @input="basic_info.description = $event.target.innerHTML; updateValues()">
            <p>Some initial <strong>bold</strong> text</p>
          </div>
        </div>
      </div>
      <div class="mt-10 row">
        <div class="button-row d-flex col-12">
          <soft-button type="button" color="dark" variant="gradient" class="mb-0 ms-auto js-btn-next" title="Next"
            @click="this.nextStep">Next</soft-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Quill from "quill";
import SoftButton from "@/components/SoftButton.vue";

export default {
  name: "BasicInfo",
  components: {
    SoftButton,
  },
  data() {
    return {
      basic_info: {
        name: "",
        description: "",
      },
    };
  },
  mounted() {
    if (document.getElementById("edit-description")) {
      // eslint-disable-next-line no-unused-vars
      var quill = new Quill("#edit-description", {
        theme: "snow", // Specify theme in configuration
      });
    }
  },
  methods: {
    updateValues() {
      this.$emit("values-updated", this.basic_info);
    },
    async nextStep() {
      this.$parent.nextStep();
    },
  },
};
</script>

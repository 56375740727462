<template>
  <div class="card">
    <div class="table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              Name
            </th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
              Visibility
            </th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              Permissions
            </th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              Users
            </th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              API Keys
            </th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              Created at
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(group, index) of apiData" :key="index" @click="editGroup(group.id)" class="cursor-pointer">
            <td>
              <div class="d-flex px-2 py-1">

                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{ group.displayname }}</h6>
                  <div class="d-flex align-items-center">
                    <span class="text-secondary text-xs">{{ group.name }}</span>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <p class="text-sm text-secondary mb-0">{{ group.hidden_group ? "hidden" : "visible" }}</p>
            </td>
            <td class="align-middle text-center text-sm">
              <p class="text-secondary mb-0 text-sm">{{ group.permissions.length }}</p>
            </td>
            <td class="align-middle text-center text-sm">
              <p class="text-secondary mb-0 text-sm">{{ group.user_count }}</p>
            </td>
            <td class="align-middle text-center text-sm">
              <p class="text-secondary mb-0 text-sm">{{ group.apikey_count }}</p>
            </td>
            <td class="align-middle text-center">
              <span class="text-secondary text-sm">{{ formatDateTime(group.created_at) }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { formatDateTime } from "@/dateAndTimeParsing";
import { makeRequest } from "@/api";

export default {
  name: "Table",
  components: {
  },
  data() {
    return {
      apiData: [],
    };
  },
  mounted() {
    this.getGroups();
  },
  methods: {
    async getGroups() {
      try {
        const response = await makeRequest("GET", "/groups?filter=hidden_group:false");
        const response2 = await makeRequest("GET", "/groups?filter=hidden_group:true");

        this.apiData = response.data.data;
        this.apiData = this.apiData.concat(response2.data.data);
      } catch (error) {
        console.log(error);
      }
    },
    editGroup(id) {
      this.$router.push(`/admin/groups/edit/${id}`);
    },
    formatDateTime(dateTime) {
      return formatDateTime(dateTime);
    }
  },
};
</script>
<template>
  <div class="card multisteps-form__panel p-3 border-radius-xl bg-white" data-animation="FadeIn">
    <h5 class="font-weight-bolder">Organisers</h5>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <OrganisersSelect :id="null" @ocs="updateOrganisers" />
      </div>
      <div class="button-row d-flex mt-4">
        <soft-button type="button" color="light" variant="gradient" class="js-btn-prev"
          @click="this.$parent.prevStep">Prev</soft-button>
        <soft-button type="button" color="dark" variant="gradient" class="ms-auto js-btn-next"
          @click="nextStep">Next</soft-button>
      </div>
    </div>
  </div>
</template>

<script>
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import Choices from "choices.js";
import OrganisersSelect from "./OrganisersSelect.vue";

export default {
  name: "DateAndTime",
  components: {
    //SoftInput,
    SoftButton,
    OrganisersSelect,
  },
  data() {
    return {
      organisers: [],
    }
  },
  mounted() {
    if (document.getElementById("choices-state")) {
      var element = document.getElementById("choices-state");
      new Choices(element, {
        searchEnabled: false,
      });
    }
  },
  methods: {
    updateOrganisers(organisers) {
      this.organisers = organisers;
      this.$emit("values-updated", organisers);
    },
    nextStep() {
      if (this.organisers.length > 0) {
        this.$parent.nextStep();
      } else {
        this.$swal({
          title: "Continue without Main OC?",
          text: "If you do not add any Main OC, the event will remain a draft.",
          showCancelButton: true,
          confirmButtonText: "Yes, continue without Main OC",
          cancelButtonText: "No, add Main OC",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn bg-gradient-danger",
            cancelButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$parent.nextStep();
          }
        });
      }
    },
  }
};
</script>

<style></style>

<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-5">
          <div class="row">
            <div class="col-12 col-lg-8 mx-auto my-5">
              <div class="multisteps-form__progress">
                <button class="multisteps-form__progress-btn js-active" type="button" title="Basic Info"
                  :class="activeStep >= 0 ? activeClass : ''" @click="activeStep = 0">
                  <span>Basic Info</span>
                </button>
                <button class="multisteps-form__progress-btn" type="button" title="Date & Time"
                  :class="activeStep >= 1 ? activeClass : ''" @click="activeStep = 1">
                  Organisers
                </button>
                <button class="multisteps-form__progress-btn" type="button" title="Date & Time"
                  :class="activeStep >= 2 ? activeClass : ''" @click="activeStep = 2">
                  Date & Time
                </button>
                <button class="multisteps-form__progress-btn" type="button" title="Location"
                  :class="activeStep >= 3 ? activeClass : ''" @click="activeStep = 3">
                  Location
                </button>
                <button class="multisteps-form__progress-btn" type="button" title="Social Inclusion"
                  :class="activeStep >= 4 ? activeClass : ''" @click="activeStep = 4">
                  Social Inclusion
                </button>
              </div>
            </div>
          </div>
          <!--form panels-->
          <div class="row">
            <div class="col-12 col-lg-8 m-auto">
              <form class="multisteps-form__form mb-5">
                <!--single form panel-->
                <BasicInfo :class="activeStep === 0 ? activeClass : ''" @values-updated="this.handleBasicInfoUpdated" />
                <!--single form panel-->
                <Organisers :class="activeStep === 1 ? activeClass : ''"
                  @values-updated="this.handleOrganisersUpdated" />
                <!--single form panel-->
                <DateAndTime :class="activeStep === 2 ? activeClass : ''"
                  @values-updated="this.handleDateAndTimeUpdated" />
                <!--single form panel-->
                <Location :class="activeStep === 3 ? activeClass : ''" @values-updated="this.handleLocationUpdated" />
                <!--single form panel-->
                <SocialInclusion :class="activeStep === 4 ? activeClass : ''"
                  @values-updated="this.handleSocialInclusionUpdated" @submit="this.submitEvent" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasicInfo from "./components/BasicInfo.vue";
import DateAndTime from "./components/DateAndTime.vue";
import Location from "./components/Location.vue";
import Organisers from "./components/Organisers.vue";
import SocialInclusion from "./components/SocialInclusion.vue";
import { makeRequest } from "@/api";

export default {
  name: "NewEvent",
  components: {
    BasicInfo,
    DateAndTime,
    Location,
    SocialInclusion,
    Organisers,
  },
  data() {
    return {
      showMenu: false,
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 5,
      event: {},
    };
  },
  methods: {
    nextStep() {
      if (this.activeStep < this.formSteps) {
        this.activeStep += 1;
      } else {
        this.activeStep -= 1;
      }
      this.jumpToTop();
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep -= 1;
      }
      this.jumpToTop();
    },
    jumpToTop() {
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    },
    handleBasicInfoUpdated(value) {
      this.event = { ...this.event, BasicInfo: value };
    },
    handleOrganisersUpdated(value) {
      const organisers = value.map(organiser => ({
        id: organiser.id,
        as: organiser.as
      }));
      this.event = { ...this.event, organisers: organisers };
    },
    handleDateAndTimeUpdated(value) {
      this.event = { ...this.event, DateAndTime: value };
    },
    handleLocationUpdated(value) {
      const locations = value.map(location => ({
        id: location.id,
        meeting_point: location.meeting_point ? location.meeting_point : false,
        main_location: location.main_location ? location.main_location : false,
        location_details: location.location_details ? location.location_details : "",
      }));
      this.event = { ...this.event, locations: locations };
      console.log(this.event);
    },
    handleSocialInclusionUpdated(value) {
      this.event = { ...this.event, SocialInclusion: value };
    },
    submitEvent() {
      console.log("Submitting event...");

      const eventObject = {
        name: this.event.BasicInfo.name,
        description: this.event.BasicInfo.description,
        starts_at: this.event.DateAndTime.date_time_info.start_date != "" && this.event.DateAndTime.date_time_info.start_time != "" ? this.event.DateAndTime.date_time_info.start_date + "T" + this.event.DateAndTime.date_time_info.start_time : undefined,
        ends_at: this.event.DateAndTime.date_time_info.end_date != "" && this.event.DateAndTime.date_time_info.end_time ? this.event.DateAndTime.date_time_info.end_date + "T" + this.event.DateAndTime.date_time_info.end_time : undefined,
        presale_starts_at: this.event.DateAndTime.presale_date_time_info.start_date != "" && this.event.DateAndTime.presale_date_time_info.start_time != "" ? this.event.DateAndTime.presale_date_time_info.start_date + "T" + this.event.DateAndTime.presale_date_time_info.start_time : undefined,
        presale_ends_at: this.event.DateAndTime.presale_date_time_info.end_date != "" && this.event.DateAndTime.presale_date_time_info.end_time ? this.event.DateAndTime.presale_date_time_info.end_date + "T" + this.event.DateAndTime.presale_date_time_info.end_time : undefined,
        cancellation_possible_until: this.event.DateAndTime.cancellation_date_time_info.date != "" && this.event.DateAndTime.cancellation_date_time_info.time != "" ? this.event.DateAndTime.cancellation_date_time_info.date + "T" + this.event.DateAndTime.cancellation_date_time_info.time : undefined,
        cancellation_details: this.event.DateAndTime.cancellation_details,
        organisers: this.event.organisers,
        social_inclusion: this.event.SocialInclusion,
        locations: this.event.locations,
        //cancellation_fee: this.event.DateAndTime.cancellation_fee,
      };
      console.log(eventObject)
      makeRequest("POST", "/events", eventObject)
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "Event created!",
            text: "Event has been created successfully!",
            type: "success-message",
          },
            console.log(response)
          ).then(() => {
            this.$router.push("/events/" + response.data.id);
          });
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Cannot create user!",
            text: error.response.data.message || "An error occurred while creating the user!",
            type: "error-message",
          });
        });
    },
  },
};
</script>

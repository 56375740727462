<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <statement-of-cost-list
          title="Events"
          :headers="['Name', 'Amount', 'Created At', 'Status']"
          :lists="statements"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StatementOfCostList from "@/views/statements-of-cost/StatementOfCostList.vue";
import setTooltip from "@/assets/js/tooltip.js";
import { makeRequest } from "@/api";

export default {
  name: "Overview",
  components: {
      StatementOfCostList,
  },
  data() {
    return {
      statements: []
    };
  },
  methods: {
    async fetchStatements() {
      const response = await makeRequest("GET", "/statements-of-cost/");
      this.statements = response.data;
    }
  },
  created() {
    this.fetchStatements();
  },
  mounted() {
    setTooltip(this.$store.state.bootstrap);
  }
};
</script>

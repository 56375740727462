<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12 col-xxl-8 mx-auto d-block">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center" style="height: 100%;">
              <h6 class="mb-0">Events</h6>
              <button class="btn btn-sm bg-gradient-primary my-auto ms-auto" type="button" name="button"
                @click="$router.push('/events/new-event')">Add new</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-12 col-xxl-8 mx-auto d-block">
        <events-list title="Events" :headers="['Name', 'Participants', 'Status']" :lists="events" />
      </div>
    </div>
  </div>
</template>

<script>
import EventsList from "./components/EventsList.vue";
import LocationsList from "@/views/events/components/LocationsList.vue";
import setTooltip from "@/assets/js/tooltip.js";
import { makeRequest } from "@/api";

export default {
  name: "Overview",
  components: {
    EventsList,
  },
  data() {
    return {
      events: []
    };
  },
  props: {
    own: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async fetchEvents() {
      if (this.own) {
        makeRequest("get", "/users/me/")
          .then((response) => {
            this.user = response.data.id;
            makeRequest("GET", "/events/?filter=user_id:" + this.user).then((response) => {
              this.events = response.data;
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const response = await makeRequest("GET", "/events/");
        this.events = response.data;
      }
    }
  },
  created() {
    this.fetchEvents();
  },
  mounted() {
    setTooltip(this.$store.state.bootstrap);
  }
};
</script>
<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-5">
          <div class="row">
            <div class="col-12 col-lg-8 mx-auto my-5">
              <div class="multisteps-form__progress">
                <button
                  class="multisteps-form__progress-btn js-active"
                  type="button"
                  title="Initialise connection"
                  :class="activeStep >= 0 ? activeClass : ''"
                >
                  <span>Initialise connection</span>
                </button>
                <button
                  class="multisteps-form__progress-btn"
                  type="button"
                  title="Avatar"
                  :class="activeStep >= 1 ? activeClass : ''"
                >
                  Create own API Key
                </button>
                <button
                  class="multisteps-form__progress-btn"
                  type="button"
                  title="Role"
                  :class="activeStep >= 2 ? activeClass : ''"
                >
                  Add partners API Key
                </button>
              </div>
            </div>
          </div>
          <!--form panels-->
          <div class="row">
            <div class="col-12 col-lg-8 m-auto">
              <form class="multisteps-form__form mb-5">
                <!--single form panel-->
                <InitialiseConnection :class="activeStep === 0 ? activeClass : ''" @values-updated="this.handleInitalisationUpdated" />
                <!--single form panel-->
                <div class="card multisteps-form__panel p-3 border-radius-xl bg-white" data-animation="FadeIn" :class="activeStep === 1 ? activeClass : ''">
                <CreateAPIKey @values-updated="this.handleCreateUpdated" ref="createAPIKey" :as-form="true"/>
                </div>
                <!--single form panel-->
                <AddAPIKey :class="activeStep === 2 ? activeClass : ''" @values-updated="this.handleAddUpdate" :section="sectionInfo.name" :apikey="apikeyResponse.key" :url="sectionInfo.url" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddAPIKey from "./components/AddAPIKey.vue";
import CreateAPIKey from "./components/CreateAPIKey.vue";
import InitialiseConnection from "./components/InitialiseConnection.vue";
import { makeRequest } from "@/api";

export default {
  name: "Connect Partner Section",
  components: {
    AddAPIKey,
    CreateAPIKey,
    InitialiseConnection,
  },
  data() {
    return {
      sectionInfo: {},
      sectionName: '',
      otherSection: {},
      newAPIKey: {},
      apikeyResponse: {},
      showMenu: false,
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 3,
      message: '',
      alertColor: '',
      alertIcon: '',
    };
  },
  methods: {
    nextStep() {
      if (this.activeStep < 1) {
        this.activeStep += 1;
        this.$refs.createAPIKey.setDescription(this.sectionName);
      } else if (this.activeStep < 2) {
        this.activeStep += 1;
        this.createAPIKey();
      } else {
        this.otherSection.apikey = this.apikeyResponse.key;
        this.createPartnerSection();
      }
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep -= 1;
      }
    },
    handleInitalisationUpdated(value) {
      this.sectionInfo = value;
      this.sectionName = value.name + ' - API Key';
      console.log(this.sectionInfo);
    },
    handleCreateUpdated(value) {
      this.newAPIKey = value;
      console.log(this.newAPIKey);
    },
    handleAddUpdate(value) {
      this.otherSection = value;
      console.log(this.otherSection);
    },
    createAPIKey() {
      makeRequest("post", "/auth/create-api-key", this.newAPIKey).then((response) => {
        this.apikeyResponse = response.data;
        this.otherSection.apikey = this.apikeyResponse.key;
      })
      .catch((error) => {
        console.log(error);
      });
    },
    createPartnerSection() {
      const section = {
        name: this.sectionInfo.name,
        tag: this.sectionInfo.tag,
        url: this.sectionInfo.url,
        apikey_in: this.apikeyResponse.id,
      };
      if (this.otherSection.apikey_out) {
        section.apikey_out = this.otherSection.apikey_out;
      }

      makeRequest("post", "/sections", section)
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "Section created!",
            text: "Section has been created successfully! NOTE: on clicking connect section, the api key is not saved yet to the database!",
            type: "success-message",
          });
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Cannot create section!",
            text: "Section could not be created!",
            type: "error-message",
          });
        });
        console.log(section);
    },
  },
};
</script>

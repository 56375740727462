<template>
  <div class="card multisteps-form__panel p-3 border-radius-xl bg-white" data-animation="FadeIn">
    <h5 class="font-weight-bolder">Date & Time</h5>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col">
          <label>Start date of event (optional)</label>
          <soft-input id="start_date" class="multisteps-form__input" type="date"
            @input="updateStartDate($event.target.value)" />
        </div>
        <div class="col">
          <label>Start time of event (optional)</label>
          <soft-input id="start_time" class="multisteps-form__input" type="time"
            @input="updateStartTime($event.target.value)" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <label>End date of event (optional)</label>
          <soft-input id="end_date" class="multisteps-form__input" type="date"
            @input="updateEndDate($event.target.value)" />
        </div>
        <div class="col">
          <label>End time of event (optional)</label>
          <soft-input id="end_time" class="multisteps-form__input" type="time"
            @input="updateEndTime($event.target.value)" />
        </div>
      </div>
      <hr>
      <div class="row mt-3">
        <div class="col">
          <div class="form-check ms-4">
            <input class="form-check-input" type="checkbox" value="" id="fcustomCheck1" v-model="manual_presale_date">
            <label class="custom-control-label" for="fcustomCheck1">I want to manually select the date of the
              presale</label>
          </div>
        </div>
      </div>
      <div class="row mt-3" v-if="manual_presale_date">
        <div class="col">
          <label>Start date of presale (optional)</label>
          <soft-input id="end_date" class="multisteps-form__input" type="date"
            @input="updatePresaleStartDate($event.target.value)" />
        </div>
        <div class="col">
          <label>Start time of presale (optional)</label>
          <soft-input id="end_time" class="multisteps-form__input" type="time"
            @input="updatePresaleStartTime($event.target.value)" />
        </div>
      </div>
      <div class="row mt-3" v-if="manual_presale_date">
        <div class="col">
          <label>End date of presale (optional)</label>
          <soft-input id="end_date" class="multisteps-form__input" type="date" :value="presale_date_time_info.end_date"
            @input="updatePresaleEndDate($event.target.value)" />
        </div>
        <div class="col">
          <label>End time of presale (optional)</label>
          <soft-input id="end_time" class="multisteps-form__input" type="time" :value="presale_date_time_info.end_time"
            @input="updatePresaleEndTime($event.target.value)" />
        </div>
      </div>
      <hr>
      <div class="row mt-3">
        <div class="col">
          <div class="form-check ms-4">
            <input class="form-check-input" type="checkbox" value="true" id="customCheck1"
              v-model="manual_cancellation">
            <label class="custom-control-label" for="customCheck1">Orders for this event can be cancelled (only paid
              events; Free events can always be cancelled for free)</label>
          </div>
        </div>
      </div>
      <div v-if="manual_cancellation" class="row mt-3">
        <div class="col">
          <label>Last possible cancellation date</label>
          <soft-input id="end_date" class="multisteps-form__input" type="date" :value="cancellation_date_time_info.date"
            @input="updateCancellationDate($event.target.value)" />
        </div>
        <div class="col">
          <label>Last possible cancellation time</label>
          <soft-input id="end_time" class="multisteps-form__input" type="time" :value="cancellation_date_time_info.time"
            @input="updateCancellationTime($event.target.value)" />
        </div>
        <div class="col">
          <div class="form-group">
            <label for="cancellation_fee" class="form-control-label">Keep cancellation fee of %</label>
            <input class="form-control" type="number" id="cancellation_fee"
              @input="updateCancellationAmount($event.target.value)">
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <label>Cancellation policy for participants</label>
          <soft-input id="cancellation_details" class="multisteps-form__input" type="text"
            :value="cancellation_details" />
        </div>
      </div>

      <div class="button-row d-flex mt-4">
        <soft-button type="button" color="light" variant="gradient" class="js-btn-prev"
          @click="this.$parent.prevStep">Prev</soft-button>
        <soft-button type="button" color="dark" variant="gradient" class="ms-auto js-btn-next"
          @click="this.$parent.nextStep">Next</soft-button>
      </div>
    </div>
  </div>
</template>

<script>
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import Choices from "choices.js";

export default {
  name: "DateAndTime",
  components: {
    SoftInput,
    SoftButton,
  },
  data() {
    return {
      date_time_info: {
        start_date: "",
        start_time: "",
        end_date: "",
        end_time: "",
      },
      presale_date_time_info: {
        start_date: "",
        start_time: "",
        end_date: "",
        end_time: "",
      },
      cancellation_date_time_info: {
        date: "",
        time: "",
      },
      manual_presale_date: false,
      manual_cancellation: false,
      presale_dirty: false,
      cancellation_dirty: false,
      cancellation_details: "",
      cancellation_fee: 0,
    }
  },
  mounted() {
    this.renderCancallationPolicy();
    if (document.getElementById("choices-state")) {
      var element = document.getElementById("choices-state");
      new Choices(element, {
        searchEnabled: false,
      });
    }
  },
  methods: {
    updateValues() {
      const date_and_time_info = {
        date_time_info: this.date_time_info,
        presale_date_time_info: this.presale_date_time_info,
        cancellation_date_time_info: this.cancellation_date_time_info,
        cancellation_details: this.cancellation_details,
        cancellation_fee: this.cancellation_fee
      }
      this.$emit("values-updated", date_and_time_info);
    },
    async updateStartDate(value) {
      this.date_time_info.start_date = value;
      if (!this.presale_dirty) {
        this.presale_date_time_info.end_date = value;
      }
      if (!this.cancellation_dirty) {
        this.cancellation_date_time_info.date = value;
      }
      this.renderCancallationPolicy();
      this.updateValues();
    },
    async updateStartTime(value) {
      this.date_time_info.start_time = value;
      if (!this.presale_dirty) {
        this.presale_date_time_info.end_time = value;
      }
      if (!this.cancellation_dirty) {
        this.cancellation_date_time_info.time = value;
      }
      this.updateValues();
    },
    async updateEndDate(value) {
      this.date_time_info.end_date = value;
      this.updateValues();
    },
    async updateEndTime(value) {
      this.date_time_info.end_time = value;
      this.updateValues();
    },
    async updatePresaleStartDate(value) {
      this.presale_date_time_info.start_date = value;
      this.updateValues();
    },
    async updatePresaleStartTime(value) {
      this.presale_date_time_info.start_time = value;
      this.updateValues();
    },
    async updatePresaleEndDate(value) {
      this.presale_date_time_info.end_date = value;
      this.presale_dirty = true;
      this.updateValues();
    },
    async updatePresaleEndTime(value) {
      this.presale_date_time_info.end_time = value;
      this.presale_dirty = true;
      this.updateValues();
    },
    async updateCancellationDate(value) {
      this.cancellation_date_time_info.date = value;
      this.cancellation_dirty = true;
      this.renderCancallationPolicy();
      this.updateValues();
    },
    async updateCancellationTime(value) {
      this.cancellation_date_time_info.time = value;
      this.cancellation_dirty = true;
      this.renderCancallationPolicy();
      this.updateValues();
    },
    async updateCancellationAmount(value) {
      this.cancellation_fee = value;
      this.renderCancallationPolicy();
      this.updateValues();
    },
    async renderCancallationPolicy() {
      if (!this.manual_cancellation || this.cancellation_date_time_info.date == "" || this.cancellation_date_time_info.time == "") {
        this.cancellation_details = "(Default policy) Cancellations are possible for free events but never for paid events.";
      } else {
        console.log(this.cancellation_date_time_info.time);
        const dateDifference = new Date(this.date_time_info.start_date) - new Date(this.cancellation_date_time_info.date);
        const differenceInDays = dateDifference / (1000 * 3600 * 24);
        let differenceString = "";
        if (this.date_time_info.start_date != "") {
          differenceString = differenceInDays > 1 ? ` (${differenceInDays} days before the event)` : ` (${differenceInDays} day before the event)`;
        }
        if (this.cancellation_fee == 0) {
          this.cancellation_details = `Cancellations are possible until ${this.cancellation_date_time_info.date} at ${this.cancellation_date_time_info.time + differenceString}. No cancellation fee will be charged.`;
        } else {
          this.cancellation_details = `Cancellations are possible until ${this.cancellation_date_time_info.date} at ${this.cancellation_date_time_info.time + differenceString}. A cancellation fee of ${this.cancellation_fee}% will be charged.`;
        }
      }
      this.updateValues();
    },
  }
};
</script>

<style></style>

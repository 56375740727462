/**
=========================================================
* Vue Soft UI Dashboard PRO - v3.0.0
=========================================================
* Product Page: https://creative-tim.com/product/vue-soft-ui-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import dotenv from 'dotenv';
import api from './api';

let isDarkMode = false;
const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
darkModeMediaQuery.addListener((e) => {
    isDarkMode = e.matches;
})
isDarkMode = darkModeMediaQuery.matches

let SoftUIDashboard;
if (isDarkMode) {
    import('./soft-ui-dashboard-dark').then(module => {
        SoftUIDashboard = module.default;
    });
} else {
    import('./soft-ui-dashboard').then(module => {
        SoftUIDashboard = module.default;
    });
}

dotenv.config({ path: __dirname + '/.env' });

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);
appInstance.use(SoftUIDashboard);
appInstance.mount("#app");

appInstance.use(api)

// URL for API access
const apiUrl = process.env.VUE_APP_API_URL || 'https://esn-mp-backend.dev.julab.me/api/v1';
const imgCache = {};

// Authentication
function isTokenExpired() {
    const tokenExpireTime = localStorage.getItem('jwtTokenExpireTime');
    if (tokenExpireTime) {
      return (new Date().getTime() > parseInt(tokenExpireTime));
    } else {
      return true;
    }
  }
  
  async function renewToken() {
    const token = localStorage.getItem('jwtToken');
    const response = await fetch(`${apiUrl}/auth/renew-token`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    if (response.ok) {
      const data = await response.json();
      localStorage.setItem('jwtToken', data.token);
      localStorage.setItem('jwtTokenExpireTime', new Date().getTime() + (16 * 60 * 1000));
      store.dispatch("checkAuthentication");
    } else {
      throw new Error('Failed to renew token');
    }
  }

  async function getStatus() {
    const token = localStorage.getItem('jwtToken');
    const response = await fetch(`${apiUrl}/users/me/status`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    if (response.ok) {
      const data = await response.json();
      localStorage.setItem('appstatus', data);
    } else {
      throw new Error('Failed to get status');
    }
  }
  
  setInterval(() => {
    if (!isTokenExpired()) {
      renewToken().catch(error => {
        console.error(error);
        // handle error
      });
    }
  }, 1000 * 60); // check every minute

  getStatus().catch(error => {
    console.error(error);
    // handle error
  });
  setInterval(() => {
    if (!isTokenExpired()) {
      getStatus().catch(error => {
        console.error(error);
        // handle error
      });
    }
  }, 1000 * 10 * 1); // check every 5 minutes
<template>
  <div :class="fullWidth ? 'mb-4 col-12' : 'mb-4 col-xl-3 col-md-6 mb-xl-0'">
    <div class="card card-blog card-plain">
      <div class="position-relative" v-if="apiData.banner">
        <a class="shadow-xl d-block border-radius-xl">
          <img :src="apiData.banner" alt="img-blur-shadow" class="shadow img-fluid border-radius-xl" /> //TODO show
          banner
        </a>
      </div>
      <div class="px-1 pb-0 card-body">
        <p class="mb-2 text-sm text-gradient text-dark">{{ label }}</p>
        <a href="javascript:;">
          <h5>{{ title || apiData.name }}</h5>
        </a>
        <p class="mb-4 text-sm">
          {{ shortenedDescription }}
        </p>
        <div class="d-flex align-items-center justify-content-between">
          <button type="button" class="mb-0 btn btn-sm" :class="`btn-outline-primary`"
            @click="$router.push('/events/' + apiData.id)">
            View Event
          </button>
          <div class="mt-2 avatar-group">
            <a v-for="(organiser, index) of apiData.organisers" :key="index" href="javascript:;"
              class="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom"
              :title="organiser.displayname">
              <img v-if="organiserImages[index]" :alt="authorImage" :src="organiserImages[index]" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { makeRequest } from "@/api";

export default {
  name: "DefaultEventCard",
  props: {
    image: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: "",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      apiData: {},
      organiserImages: [],
    }
  },
  computed: {
    shortenedDescription() {
      if (this.apiData.description && this.apiData.description.length > 300) {
        return this.apiData.description.substring(0, 300) + '...';
      } else {
        return this.apiData.description;
      }
    }
  },
  methods: {
    async fetchEvent() {
      const response = await makeRequest("GET", `/events/${this.id}?fields=id,name,description,starts_at,organisers,banner`);
      this.apiData = response.data;
      this.fetchOrganiserImages(); // Call the method to fetch organiser images
    },
    async fetchOrganiserImages() {
      for (let i = 0; i < this.apiData.organisers.length; i++) {
        const organiser = this.apiData.organisers[i];
        try {
          const response = await makeRequest("get", `/${organiser.avatar}/view`, null, "application/json", "blob");
          const imageUrl = URL.createObjectURL(response.data);
          this.organiserImages[i] = imageUrl;
        } catch (error) {
          console.error(error);
        }
      }
    },
  },
  mounted() {
    this.fetchEvent();
  },
};
</script>

<template>
  <div class="card">
    <div class="table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              Name
            </th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
              Groups
            </th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              Phone
            </th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              Email
            </th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              Account created
            </th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              Last login
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) of apiData" :key="index">
            <td>
              <div class="d-flex px-2 py-1">
                <user-avatar :id="user.avatar" class="me-3" alt="avatar image" border-radius="lg" />
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{ user.displayname }}</h6>
                  <div class="d-flex align-items-center">
                    <span class="text-secondary text-xs">{{ user.firstname }} {{ user.lastname }}</span>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <p class="text-sm text-secondary mb-0">{{ user.groups.length }}</p>
            </td>
            <td class="align-middle text-center text-sm">
              <p class="text-secondary mb-0 text-sm">{{ user.phone }}</p>
            </td>
            <td class="align-middle text-center text-sm">
              <p class="text-secondary mb-0 text-sm">{{ user.email }}</p>
            </td>
            <td class="align-middle text-center">
              <span class="text-secondary text-sm">{{ formatDate(user.created_at) }}</span>
            </td>
            <td class="align-middle text-center">
              <span class="text-secondary text-sm">{{ user.last_login ? formatDateTime(user.last_login) : 'never'
                }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/UserAvatar.vue";
import img1 from "../../../../assets/img/team-1.jpg";
import img2 from "../../../../assets/img/team-2.jpg";
import img3 from "../../../../assets/img/team-3.jpg";
import img4 from "../../../../assets/img/team-4.jpg";
import img5 from "../../../../assets/img/team-5.jpg";

import { makeRequest } from "@/api";
import { formatDate, formatDateTime } from "@/dateAndTimeParsing";

export default {
  name: "Table",
  components: {
    UserAvatar,
  },
  data() {
    return {
      apiData: [],
      img1,
      img2,
      img3,
      img4,
      img5,
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    async getUsers() {
      try {
        const response = await makeRequest("GET", "/users");
        this.apiData = response.data;
      } catch (error) {
        console.log(error);

      }
    },
    formatDate,
    formatDateTime,
  },
};
</script>
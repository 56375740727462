<template>
    <div class="container-fluid py-4">
      <div class="row mt-4">
        <div class="col-12 col-xxl-8 mx-auto d-block">
          <div class="card mt-4">
            <div class="card-body">
              <CreateAPIKey @values-updated="this.updateValues" v-if="!submitted"/>
              <div v-if="submitted">
                <h5 class="font-weight-bolder mb-0">{{ this.newAPIKey.description }}</h5>
                <p class="mb-0 text-sm">New token created</p>
                <div>
                  <div class="row mt-3">
                      <label>Token</label>
                      <soft-input
                        id="description"
                        class="multisteps-form__input"
                        type="text"
                        placeholder="eg. ESN International"
                        :value="token"
                        disabled
                      />
                      <soft-alert
                        class="mt-3 mb-0"
                        color="info"
                        icon="ni-bell ni-lg"
                        >
                          <strong>Please save the key as it is not possible to show it again after you leave the page!</strong>
                      </soft-alert>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  //import Table from "./components/UsersTable.vue";
  import { formatDateTime } from "@/dateAndTimeParsing";
  import { makeRequest } from "@/api";
  import CreateAPIKey from "./components/CreateAPIKey.vue";
  import SoftInput from "@/components/SoftInput.vue";
  import SoftAlert from "@/components/SoftAlert.vue";
  
  export default {
    name: "Create API Key",
    components: {
      CreateAPIKey,
      SoftInput,
      SoftAlert,
    },
    data() {
    return {
      newAPIKey: {},
      token: "",
      submitted: false,
    };
    },
    mounted() {
      
    },
    methods: {
    formatDateTime(dateTime) {
      return formatDateTime(dateTime);
    },
    submitForm() {
      console.log(this.newAPIKey);
      makeRequest("post", "/auth/create-api-key", this.newAPIKey).then((response) => {
        this.submitted = true;
        this.token = response.data.key;
        this.$swal({
            icon: "success",
            title: "API key created!",
            text: "The API key has been created! (but you cannot see it hahahahha)",
            type: "success-message",
          });
      })
      .catch((error) => {
        this.$swal({
            icon: "error",
            title: "Cannot create API key!",
            text: error.response.data.message || "API key could not be created",
            type: "error-message",
          });
      });
    },
    updateValues(values) {
      this.newAPIKey = values;
    },
  },
};
  </script>
  
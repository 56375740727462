<template>
    <h5 class="font-weight-bolder mb-0">Create API Key</h5>
    <p class="mb-0 text-sm">Create new API Key for access to your section</p>
    <div :class="asForm ? 'multisteps-form__content' : ''">
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label>Description</label>
          <soft-input
            id="description"
            class="multisteps-form__input"
            type="text"
            placeholder="eg. ESN International"
            :value="createKey.description"
            @input="createKey.description = $event.target.value; updateValues()"
          />
        </div>
        <div class="col-12 col-sm-6">
          <label>Valid until</label>
          <soft-input
            id="validuntil"
            class="multisteps-form__input"
            type="date"
            placeholder="eg. Prior"
            :value="createKey.valid_until"
            @input="createKey.valid_until = $event.target.value + 'T23:59:59.000Z'; updateValues()"
          />
        </div>
      </div>
      <div class="row mt-3">
        <h5 class="font-weight-bolder">Groups</h5>
        <small class="text-sm mb-4">The owner of the API key can act as user of the selected group(s).</small>
        <Groups @values-updated="this.handleGroupsUpdate" />
      </div>
      <soft-alert
                :color="alertColor"
                :icon="alertIcon + ' ni-lg'"
                dismissible v-if="message"
                >
                  <strong>{{ message }}</strong>
              </soft-alert>
      <div class="button-row d-flex mt-4">
        <soft-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="this.$parent.prevStep"
          v-if="asForm"
        >Prev</soft-button>
        <soft-button
          type="button"
          color="primary"
          variant="gradient"
          class="ms-auto js-btn-next"
          @click="this.$parent.submitForm"
          v-if="!asForm"
        >Create API Key</soft-button>
        <soft-button
          type="button"
          color="primary"
          variant="gradient"
          class="ms-auto js-btn-next"
          @click="this.$parent.nextStep"
          v-if="asForm"
        >Create API Key</soft-button>
      </div>
    </div>
</template>

<script>
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftAlert from "@/components/SoftAlert.vue";
import Groups from "../../groups/components/GroupSelector.vue";

export default {
  name: "Create API Key",
  components: {
    SoftInput,
    SoftButton,
    SoftAlert,
    Groups,
  },
  props: {
    section_name: {
      type: String,
      default: "",
    },
    asForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      createKey: {
        description: '',
        valid_until: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().slice(0, 10),
        groups: [],
      },
      message: '',
      alertColor: '',
      alertIcon: '',
      validPasswords: true,
    };
  },
  computed: {
  },
  methods: {
    updateValues() {
      //this.message = 'ignore this';
      //this.alertColor = 'warning';
      //this.alertIcon = 'ni ni-fat-remove';

      this.$emit("values-updated", this.createKey);
    },
    handleGroupsUpdate(value) {
      this.createKey.groups = value;
      this.$emit("values-updated", this.createKey);
    },
    setDescription(value) {
      this.createKey.description = value;
      this.$emit("values-updated", this.createKey);
    },
  }
};
</script>

<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12 col-xxl-8 mx-auto d-block">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center" style="height: 100%;">
              <h6 class="mb-0">{{ title }}</h6>
              <button class="btn btn-sm bg-gradient-primary my-auto ms-auto" type="button" name="button"
                @click="$router.push({ name: this.newComponentName })">Add new</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 col-xxl-8 mx-auto d-block">
        <div class="col-12">
          <div class="mb-4 card">
            <div class="pb-0 card-header">
              <h6>Overview</h6>
            </div>
            <div class="px-0 pt-0 pb-2 card-body">
              <div class="p-0 table-responsive">
                <FinancialDocumentsTable :headers="headers" :data="data" :detailsComponentName="detailsComponentName" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { makeRequest } from "@/api.js";
import { formatDateTime } from "@/dateAndTimeParsing";
import FinancialDocumentsTable from "@/views/finances/components/FinancialDocumentsTable.vue";

export default {
  name: "FinancialDocuments",
  components: {
    FinancialDocumentsTable,
  },
  methods: {
    formatDateTime(dateTime) {
      return formatDateTime(dateTime);
    },
    rowClicked(id) {
      this.$router.push({ name: detailsComponentName, params: { id: id } });
    },
    async getDataFromApi() {
      try {
        const response = await makeRequest("GET", this.endpoint);
        this.data = response.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      title: {
        'iin': 'Invoices (in)',
        'iout': 'Invoices (out)',
        'soc': 'Statements of cost',
        'reimb': 'Reimbursements'
      }[this.type] || 'Unknown type',
      detailsComponentName: {
        'iin': '',
        'iout': '',
        'soc': 'StatementOfCostDetails',
        'reimb': ''
      }[this.type] || 'Unknown type',
      newComponentName: {
        'iin': 'NewInvoice',
        'iout': '',
        'soc': 'NewStatementOfCost',
        'reimb': ''
      }[this.type] || 'Unknown type',
      headers: {
        'iin': ['Name', 'Amount', 'Related to Event', 'Created at', 'Created by', 'Status'],
        'iout': ['Name', 'Amount', 'Created at', 'Created by', 'Status'],
        'soc': ['Name', 'Amount', 'Related to event', 'Created at', 'Created by', 'Status'],
        'reimb': ['Name', 'Amount', 'Related to Invoice', 'Created at', 'Created by', 'Status'],
      }[this.type] || [],
      endpoint: {
        'iin': '',
        'iout': '',
        'soc': '/statements-of-cost',
        'reimb': ''
      }[this.type] || 'Unknown type',
      data: [],
    };
  },
  mounted() {
    this.getDataFromApi().then(() => {
      console.log(this.data);
      this.data.push({ id: '123', name: '123', amount: 100, created_at: '23', status: 'pending' })
    });
  },
};
</script>
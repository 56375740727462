<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12 col-xxl-8 mx-auto d-block">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center" style="height: 100%;">
              <h6 class="mb-0">ESNcard Shop Pickups</h6>
              <button class="btn btn-link text-dark text-sm mb-0 px-2 my-auto ms-auto" @click="downloadAllImages()"
                v-if="checkedpickups.length > 0">
                <i class="fas fa-image text-lg me-1" aria-hidden="true"></i>
                Images ({{ checkedpickups.length }})
              </button>
              <button class="btn btn-sm bg-gradient-primary my-auto ms-4" type="button" name="button"
                @click="pickupNowMultiple()" v-if="checkedpickups.length > 0">Mark picked up ({{ checkedpickups.length
                }})</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 col-xxl-8 mx-auto d-block">
        <div class="col-12">
          <div class="mb-4 card">
            <div class="pb-0 card-header d-flex">
              <div>
                <h6>Pickups</h6>
              </div>
              <div class="ms-auto d-flex">
                <input v-model="filterText" class="form-control form-control-sm" placeholder="Filter by name..." />
              </div>
            </div>
            <div class="px-0 pt-0 pb-2 card-body">
              <div class="p-0 table-responsive">
                <table class="table mb-0 align-items-center">
                  <thead>
                    <tr>
                      <th>
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="1" id="checkhead"
                            @change="markAll($event.target.checked)">
                        </div>
                      </th>
                      <th v-for="(header, index) of
                      [...['Country', 'Name'], 'University', 'Created at', 'Unit', 'Attachment', 'Actions']"
                        :key="index"
                        class="text-center ps-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        {{ header }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(
                pickup, index
              ) in filteredData" :key="index">
                      <td>
                        <div class="px-3 py-1 d-flex">
                          <div class="d-flex flex-column justify-content-center">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="fcustomCheck1"
                                :checked="checkedpickups.includes(pickup.id)" v-if="pickup.pickup_at === null"
                                @click="mark(pickup.id)">
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="px-3 py-1 d-flex">
                          <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-sm">{{ pickup.nationality ? countryCodeToFlag(pickup.nationality) : ''
                              }}</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p class="text-center mb-0 text-sm font-weight-bold">
                          {{ pickup.first_name }} {{ pickup.last_name }}
                        </p>
                      </td>
                      <td class="text-sm align-middle">
                        <p class="text-center mb-0 text-sm font-weight-bold">
                          NO
                        </p>
                      </td>
                      <td class="text-sm align-middle">
                        <p class="text-center mb-0 text-sm font-weight-bold">
                          {{ formatDateTime(pickup.created_at) }}
                        </p>
                      </td>
                      <td class="text-sm align-middle">
                        <p class="text-center mb-0 text-sm font-weight-bold">
                          {{ pickup.unit }}
                        </p>
                      </td>

                      <td class="text-sm align-middle">
                        <p class="text-center mb-0 text-sm font-weight-bold">
                          <button class="btn btn-link text-dark text-sm mb-0 px-0 ms-4" v-if="pickup.image"
                            @click="download(pickup.image, '' + pickup.unit + '-image')">
                            <i class="fas fa-image text-lg me-1" aria-hidden="true"></i>
                            IMG
                          </button>
                        </p>
                      </td>
                      <td class="text-sm align-middle">
                        <p class="text-center mb-0 text-sm font-weight-bold">
                          <button class="btn btn-primary text-dark btn-link text-sm mb-0 px-2 mx-4 me-1 pb-2 pt-2"
                            @click="pickupNow(pickup.id)" v-if="pickup.pickup_at === null">
                            <i class="fas fa-address-card text-lg me-1" aria-hidden="true"></i>
                            Pick up now
                          </button>
                          <button class="btn text-dark btn-link text-sm mb-0 px-2 mx-4 me-1 pb-2 pt-2"
                            @click="details(pickup.id)" v-if="pickup.pickup_at !== null">
                            <i class="fas fa-address-card text-lg me-1" aria-hidden="true"></i>
                            Picked up
                          </button>
                          <button class="btn btn-secondary text-dark btn-link text-sm mb-0 px-2 ms-1 pb-2 pt-2"
                            @click="edit(pickup.id)">
                            <i class="fas fa-pen text-lg me-1" aria-hidden="true"></i>
                          </button>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="alert alert-info mx-4 mt-4" role="alert" v-if="pickups == null || pickups.length == 0">
                  <strong>So empty here!</strong> There are currently no pick-ups in the database!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateTime } from "@/dateAndTimeParsing";
import { makeRequest } from "@/api";

export default {
  name: "ESNcardShopPickups",
  components: {
  },
  methods: {
    formatDateTime(dateTime) {
      return formatDateTime(dateTime);
    },
    rowClicked() {
    },
    async fetchpickups() {
      const response = await makeRequest("GET", "/office/pickups");
      this.pickups = response.data;
    },
    async details(pickupId) {
      this.$swal({
        title: "Details",
        text: "This card was picked up on the " + formatDateTime(this.pickups.find((pickup) => pickup.id === pickupId).pickup_at) + " and given out by " + this.pickups.find((pickup) => pickup.id === pickupId).pickup_by + "",
        icon: "info",
        customClass: {
          confirmButton: "btn bg-gradient-success",
        },
        buttonsStyling: false,
      });
    },
    async pickupNow(pickupId) {
      this.$swal({
        title: "Pickup now?",
        text: "Please check that the details of the person are correct. This action cannot be undone.",
        showCancelButton: true,
        confirmButtonText: "Pickup now",
        cancelButtonText: "Cancel",
        reverseButtons: false,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.sendpickup(pickupId).then((successful) => {
            console.log(successful);
            if (successful.includes(pickupId)) {
              this.$swal({
                title: "Picked up!",
                text: "The card was marked as picked up.",
                icon: "success",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
              });
            }
            else {
              this.$swal({
                title: "Error!",
                text: "This card could not be picked up.",
                icon: "error",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
              });
            }
          });
        }
      });
    },
    async pickupNowMultiple() {
      this.$swal({
        title: "Mark orders as picked up?",
        text: "After proceeding the pickups, all " + this.checkedpickups.length + " orders will be marked as picked up. This action cannot be undone.",
        showCancelButton: true,
        confirmButtonText: "Pickup now (" + this.checkedpickups.length + ")",
        cancelButtonText: "Cancel",
        reverseButtons: false,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const list1 = this.checkedpickups;
          this.sendMultiplepickups().then((successful) => {
            const list2 = successful;
            console.log(successful);
            console.log(list1)
            const difference = list1.filter(x => !list2.includes(x)).concat(list2.filter(x => !list1.includes(x)));
            console.log(difference)
            if (difference.length === 0) {
              this.$swal({
                title: "Picked up!",
                text: "All " + successful.length + " pickups have been registered.",
                icon: "success",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
              });
            }
            else {
              this.$swal({
                title: "Error!",
                text: difference.length + " out of " + this.checkedpickups.length + " pickups could not be marked as picked up.",
                icon: "warning",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
              });
            }
          });
          this.deselectAll();
        }
      });
    },
    async edit(pickupId) {
      //implement
    },
    async download(id, fname) {
      const response = await makeRequest("GET", '/' + id + '/download', null, 'application/json', true);
      const responseDetails = await makeRequest("GET", '/' + id + '/details');
      const fileExt = responseDetails.data.mimetype.split('/').pop();
      const urlObject = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = urlObject;
      link.setAttribute("download", fname + '.' + fileExt);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    countryCodeToFlag(countryCode) {
      return countryCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397));
    },
    async sendpickup(pickupId) {
      const res = await makeRequest("PUT", `/office/pickups/collect`, { pickups: [pickupId] });
      await this.fetchpickups();
      return res.data.success;
    },
    async sendMultiplepickups() {
      const res = await makeRequest("PUT", `/office/pickups/collect`, { pickups: this.checkedpickups });
      await this.fetchpickups();
      return res.data.success;
    },
    markAll(status) {
      if (status) {
        this.checkedpickups = this.pickups.filter((pickup) => pickup.pickup_at === null).map((pickup) => pickup.id);
      } else {
        this.checkedpickups = [];
      }
    },
    mark(pickupId) {
      if (this.checkedpickups.includes(pickupId)) {
        this.checkedpickups = this.checkedpickups.filter((id) => id !== pickupId);
      } else {
        this.checkedpickups.push(pickupId);
      }
    },
    downloadAllImages() {
      this.checkedpickups.forEach((entry) => {
        const filteredpickups = this.pickups.filter(pickup => pickup.id === entry);
        const imageId = filteredpickups[0].image;
        console.log(imageId);
        this.download(imageId, '' + filteredpickups[0].unit + '-image');
      });
    },
    deselectAll() {
      this.checkedpickups = [];
    },
  },
  data() {
    return {
      pickups: [],
      checkedpickups: [],
      filterText: "",
    };
  },
  computed: {
    filteredData() {
      const searchText = this.filterText.toLowerCase();
      return this.pickups.filter(
        (pickup) =>
          pickup.first_name.toLowerCase().includes(searchText) ||
          pickup.last_name.toLowerCase().includes(searchText)
      );
    },
  },
  created() {
    this.fetchpickups();
  },
};
</script>
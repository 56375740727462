<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="mx-auto col-lg-9 col-12">
        <div class="mt-4 card card-body">
          <h6 class="mb-0">Initialise ESNcard Online Shop</h6>
          <p class="mb-0 text-sm">Initialise ESNcard Online Shop</p>
          <hr class="my-3 horizontal dark" />
          <div>
            <label for="slug" class="form-label">Slug</label>
            <input id="slug" type="text" class="form-control" v-model="init.slug" />
          </div>
          <div class="form-group">
            <label class="mt-4 form-label">Price per ESNcard</label>
            <div class="input-group">
              <span class="input-group-text">€</span>
              <input type="text" class="form-control" aria-label="Amount" v-model="amount_field">
            </div>
          </div>
          <div>
            <label class="mt-4 form-label">Universities</label>
            <ul class="list-group">
              <li class="list-group-item active">Cras justo odio</li>
              <li class="list-group-item">Dapibus ac facilisis in</li>
              <li class="list-group-item">Morbi leo risus</li>
              <li class="list-group-item">Porta ac consectetur ac</li>
              <li class="list-group-item">Vestibulum at eros</li>
            </ul>
          </div>
          <div class="form-check" style="margin-top: 10px">
            <input class="form-check-input" type="checkbox" value="1" id="fcustomCheck1" checked="">
            <label class="custom-control-label" for="customCheck1">ESNcard Shop with Shipping function</label>
          </div>
          <div class="form-check" style="margin-top: 10px">
            <input class="form-check-input" type="checkbox" value="1" id="fcustomCheck2" checked="">
            <label class="custom-control-label" for="customCheck2">ESNcard Shop with Pickup function</label>
          </div>
          <div class="form-check" style="margin-top: 10px">
            <input class="form-check-input" type="checkbox" value="1" id="fcustomCheck3" checked="">
            <label class="custom-control-label" for="customCheck3">ESNcard Shop with Image Printing function</label>
          </div>
          <div>
            <hr>
          </div>
          <h6 class="mb-0">Shipping options</h6>
          <div class="form-group" style="margin-top: 10px;">
            <label for="shippingstack">Stack to use for shipping orders</label>
            <select v-model="statement.event_id" class="form-control" id="shippingstack">
              <option>Not related to any event</option>
              <option v-for="(event, index) in events" :key="index" :selected="event.id == $route.query.event_id"
                :value="event.id">{{
              event.starts_at ? formatDate(event.starts_at) :
                "Draft" }} -
                {{ event.name }}</option>
            </select>
          </div>
          <ul class="list-group">
            <li class="list-group-item active">Cras justo odio</li>
            <li class="list-group-item">Dapibus ac facilisis in</li>
            <li class="list-group-item">Morbi leo risus</li>
            <li class="list-group-item">Porta ac consectetur ac</li>
            <li class="list-group-item">Vestibulum at eros</li>
          </ul>


          <div>
            <hr>
          </div>
          <h6 class="mb-0">Pickup options</h6>
          <div class="form-group" style="margin-top: 10px;">
            <label for="pickupstack">Stack to use for pickup orders</label>
            <select v-model="statement.event_id" class="form-control" id="pickupgstack">
              <option>Not related to any event</option>
              <option v-for="(event, index) in events" :key="index" :selected="event.id == $route.query.event_id"
                :value="event.id">{{
              event.starts_at ? formatDate(event.starts_at) :
                "Draft" }} -
                {{ event.name }}</option>
            </select>
          </div>
          <div style="margin-top: 10px;">
            <label for="ship" class="form-label">Pickup address</label>
            <textarea id="socDescription" class="form-control" v-model="statement.statement"></textarea>
          </div>

          <div>
            <hr>
          </div>
          <h6 class="mb-0">Image printing options</h6>
          <div style="margin-top: 10px;">
            <label for="imageworker" class="form-label">Worker Container URL for dynamic ESNcard image</label>
            <input id="imageworker" type="text" class="form-control" v-model="init.image.worker" />
          </div>

          <div class="row mt-3 mx-1">
            <soft-alert :color="alertColor" :icon="alertIcon + ' ni-lg'" v-if="message">
              <strong>{{ message }}</strong>
            </soft-alert>
          </div>
          <div class="mt-4 d-flex justify-content-end">
            <button v-if="$route.query.event_id" type="button" name="button" class="m-0 btn btn-light"
              @click="$router.push('/events/' + $route.query.event_id)">
              Cancel
            </button>
            <button type="button" name="button" class="m-0 btn bg-gradient-primary ms-2" @click="submit">
              Setup Shop
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import flatPickr from "vue-flatpickr-component";
import Dropzone from "dropzone";
import Choices from "choices.js";
import Quill from "quill";
import SoftAlert from "@/components/SoftAlert.vue";
import { formatDate } from "@/dateAndTimeParsing";
import { makeRequest } from "@/api";

export default {
  name: "InitialiseShop",
  components: {
    SoftAlert,
    //flatPickr,
  },
  data() {
    return {
      statement: {
        name: '',
        statement: '',
        amount: '',
        event_id: this.$route.query.event_id,
        // attachment: '',
      },
      init: {
        slug: '',
        image: {
          worker: '',
        },
      },
      amount_field: '',
      message: '',
      alertColor: '',
      alertIcon: '',
      config: {
        allowInput: true,
      },
      events: [],
    };
  },
  methods: {
    async submit() {
      try {
        if (this.amount_field && !/^[0-9]+(\,[0-9]{2})?$/.test(this.amount_field)) {
          this.message = 'Invalid amount';
          this.alertColor = 'danger';
          this.alertIcon = 'ni ni-fat-remove';
          return;
        }
        this.statement.amount = this.amount_field.replace(',', '.') * 100;

        const response = await makeRequest("POST", "/statements-of-cost/", this.statement);
        if (response.data?.id?.length === 36) {
          this.$swal({
            title: "Success!",
            text: "Statement of cost has been created successfully!",
            icon: "success",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
          });
          this.$router.push('/statements-of-cost/' + response.data.id);
        }
      } catch (error) {
        console.log('Posting SoC failed:', error);
        this.message = 'Submission failed';
        this.alertColor = 'danger';
        this.alertIcon = 'ni ni-fat-remove';
      }
    },
    async getEvents() {
      try {
        const response = await makeRequest("GET", "/events/");
        this.events = response.data;
      } catch (error) {
        console.log('Fetching events failed:', error);
      }
    },
    formatDate(dateTime) {
      return formatDate(dateTime);
    },
  },
  mounted() {
    this.getEvents();
    console.log(this.$route.query)
    if (document.getElementById("editor")) {
      new Quill("#editor", {
        theme: "snow", // Specify theme in configuration
      });
    }

    if (document.getElementById("choices-multiple-remove-button")) {
      var element = document.getElementById("choices-multiple-remove-button");
      const example = new Choices(element, {
        removeItemButton: true,
      });

      example.setChoices(
        [
          {
            value: "One",
            label: "Label One",
            disabled: true,
          },
          {
            value: "Two",
            label: "Label Two",
            selected: true,
          },
          {
            value: "Three",
            label: "Label Three",
          },
        ],
        "value",
        "label",
        false
      );
    }

    Dropzone.autoDiscover = false;
    var drop = document.getElementById("dropzone");
    new Dropzone(drop, {
      url: "/file/post",
      addRemoveLinks: true,
    });
  },
};
</script>

<template>
    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-12 col-xxl-8 mx-auto d-block">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center" style="height: 100%;">
                  <h6 class="mb-0">Users</h6>
                  <button class="btn btn-sm bg-gradient-primary my-auto ms-auto" type="button" name="button" @click="$router.push('new-user')">Add new</button>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12 col-xxl-8 mx-auto d-block">
          <div class="col-12">
            <Table />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Table from "./components/UsersTable.vue";
  
  export default {
    name: "UsersList",
    components: {
      Table,
    },
  };
  </script>
  
<template>
  <div id="globe" class="position-absolute end-0 top-10 mt-sm-3 mt-7 me-lg-7">
    <canvas :width="width" :height="height" class="w-lg-100 h-lg-100 w-75 h-75 me-lg-0 me-n10 mt-lg-5"></canvas>
  </div>
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

export default {
  name: "Globe",
  props: {
    width: {
      type: String,
      default: "700",
    },
    height: {
      type: String,
      default: "600",
    },
  },

  mounted() {
    (function () {
      const container = document.getElementById("globe");
      const canvas = container.getElementsByTagName("canvas")[0];

      const globeRadius = 100;
      const globeWidth = 4098 / 2;
      const globeHeight = 1968 / 2;

      function convertFlatCoordsToSphereCoords(x, y) {
        let latitude = ((x - globeWidth) / globeWidth) * -180;
        let longitude = ((y - globeHeight) / globeHeight) * -90;
        latitude = (latitude * Math.PI) / 180;
        longitude = (longitude * Math.PI) / 180;
        const radius = Math.cos(longitude) * globeRadius;

        return {
          x: Math.cos(latitude) * radius,
          y: Math.sin(longitude) * globeRadius,
          z: Math.sin(latitude) * radius,
        };
      }

      function convertLatLonToSphereCoords(lat, lon) {
        const phi = (90 - lat) * (Math.PI / 180);
        const theta = (lon + 180) * (Math.PI / 180);
        return {
          x: -(globeRadius * Math.sin(phi) * Math.cos(theta)),
          y: globeRadius * Math.cos(phi),
          z: globeRadius * Math.sin(phi) * Math.sin(theta),
        };
      }

      function makeMagic(points) {
        const { width, height } = container.getBoundingClientRect();

        // 1. Setup scene
        const scene = new THREE.Scene();
        // 2. Setup camera
        const camera = new THREE.PerspectiveCamera(45, width / height);
        // 3. Setup renderer
        const renderer = new THREE.WebGLRenderer({
          canvas,
          antialias: true,
        });
        renderer.setSize(width, height);
        // 4. Add points to canvas
        // - Single geometry to contain all points.
        const mergedGeometry = new THREE.Geometry();
        // - Material that the dots will be made of.
        const pointGeometry = new THREE.SphereGeometry(0.5, 1, 1);
        const pointMaterial = new THREE.MeshBasicMaterial({
          color: "#989db5",
        });

        for (let point of points) {
          const { x, y, z } = convertFlatCoordsToSphereCoords(
            point.x,
            point.y
          );

          if (x && y && z) {
            const pointMesh = new THREE.Mesh(pointGeometry, pointMaterial);
            pointMesh.position.set(x, y, z);
            mergedGeometry.mergeMesh(pointMesh);
          }
        }

        const globeShape = new THREE.Mesh(mergedGeometry, pointMaterial);
        scene.add(globeShape);

        const countryPoints = [
          { lat: 41.1533, lng: 20.1683 },
          { lat: 40.0691, lng: 45.0382 },
          { lat: 47.5162, lng: 14.5501 },
          { lat: 40.1431, lng: 47.5769 },
          { lat: 50.5039, lng: 4.4699 },
          { lat: 43.9159, lng: 17.6791 },
          { lat: 42.7339, lng: 25.4858 },
          { lat: 45.1, lng: 15.2 },
          { lat: 35.1264, lng: 33.4299 },
          { lat: 49.8175, lng: 15.473 },
          { lat: 56.2639, lng: 9.5018 },
          { lat: 58.5953, lng: 25.0136 },
          { lat: 61.9241, lng: 25.7482 },
          { lat: 46.6034, lng: 1.8883 },
          { lat: 42.3154, lng: 43.3569 },
          { lat: 51.1657, lng: 10.4515 },
          { lat: 39.0742, lng: 21.8243 },
          { lat: 47.1625, lng: 19.5033 },
          { lat: 53.4129, lng: -8.2439 },
          { lat: 41.8719, lng: 12.5674 },
          { lat: 30.5852, lng: 36.2384 },
          { lat: 48.0196, lng: 66.9237 },
          { lat: 56.8796, lng: 24.6032 },
          { lat: 47.166, lng: 9.5554 },
          { lat: 55.1694, lng: 23.8813 },
          { lat: 49.8153, lng: 6.1296 },
          { lat: 35.9375, lng: 14.3754 },
          { lat: 47.4116, lng: 28.3699 },
          { lat: 42.7087, lng: 19.3744 },
          { lat: 41.6086, lng: 21.7453 },
          { lat: 60.472, lng: 8.4689 },
          { lat: 51.9194, lng: 19.1451 },
          { lat: 39.3999, lng: -8.2245 },
          { lat: 45.9432, lng: 24.9668 },
          { lat: 61.524, lng: 105.3188 },
          { lat: 44.0165, lng: 21.0059 },
          { lat: 48.669, lng: 19.699 },
          { lat: 46.1512, lng: 14.9955 },
          { lat: 40.4637, lng: -3.7492 },
          { lat: 60.1282, lng: 18.6435 },
          { lat: 46.8182, lng: 8.2275 },
          { lat: 52.1326, lng: 5.2913 },
          { lat: 38.9637, lng: 35.2433 },
          { lat: 55.3781, lng: -3.436 },
          { lat: 48.3794, lng: 31.1656 },
        ];

        const countryGeometry = new THREE.Geometry();
        const countryMaterial = new THREE.MeshBasicMaterial({
          color: "white",
        });
        const countryPointGeometry = new THREE.SphereGeometry(1.5, 1, 1); // Bigger size than the other dots


        for (let point of countryPoints) {
          const { x, y, z } = convertLatLonToSphereCoords(point.lat, point.lng);

          if (x && y && z) {
            const pointMesh = new THREE.Mesh(countryPointGeometry, countryMaterial);
            pointMesh.position.set(x, y, z);
            countryGeometry.mergeMesh(pointMesh);
          }
        }

        const countryShape = new THREE.Mesh(countryGeometry, countryMaterial);
        scene.add(countryShape);

        container.classList.add("peekaboo");

        // Setup orbital controls
        camera.orbitControls = new OrbitControls(camera, canvas);
        camera.orbitControls.enableKeys = false;
        camera.orbitControls.enablePan = false;
        camera.orbitControls.enableZoom = false;
        camera.orbitControls.enableDamping = false;
        camera.orbitControls.enableRotate = true;
        camera.orbitControls.autoRotate = true;
        camera.position.z = -265;

        function animate() {
          // orbitControls.autoRotate is enabled so orbitControls.update
          // must be called inside animation loop.
          camera.orbitControls.update();
          requestAnimationFrame(animate);
          renderer.render(scene, camera);
        }
        animate();
      }

      function hasWebGL() {
        const gl =
          canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
        if (gl && gl instanceof WebGLRenderingContext) {
          return true;
        } else {
          return false;
        }
      }

      function init() {
        if (hasWebGL()) {
          window;
          window
            .fetch(
              "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-dashboard-pro/assets/js/points.json"
            )
            .then((response) => response.json())
            .then((data) => {
              makeMagic(data.points);
            });
        }
      }
      init();
    })();
  },
};
</script>

<template>
  <div class="card multisteps-form__panel p-3 border-radius-xl bg-white" data-animation="FadeIn">
    <h5 class="font-weight-bolder mb-0">Initialise connection</h5>
    <p class="mb-0 text-sm">Connect to the backend of the partner section</p>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-12">
          <label>Section API-URL</label>
          <div class="d-flex">
            <input type="text" class="form-control me-2" placeholder="eg. https://esnsection.org/api/v1" aria-label="Section API URL" aria-describedby="button-addon2"
            :value="sectionInfo.url" @input="sectionInfo.url = $event.target.value; updateValues()">
            <button class="btn btn-outline-primary mb-0" type="button" id="button-addon2" @click="pollSectionDetails()">Poll section details</button>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <label>Section name</label>
          <soft-input
            id="displayname"
            class="multisteps-form__input"
            type="text"
            placeholder=""
            :value="sectionInfo.name"
            @input="sectionInfo.name = $event.target.value; updateValues()"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <label>Section location</label>
          <soft-input
            id="phone"
            class="multisteps-form__input"
            type="text"
            placeholder=""
            :value="sectionInfo.location"
            @input="sectionInfo.location = $event.target.value; updateValues()"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label>Section tag</label>
          <soft-input
            id="password"
            class="multisteps-form__input"
            type="text"
            placeholder=""
            :value="sectionInfo.tag"
            @input="sectionInfo.tag = $event.target.value; updateValues()"
          />
        </div>
        <div class="col-12 col-sm-6">
          <label>Section timezone</label>
          <soft-input
            id="confirmPassword"
            class="multisteps-form__input"
            type="text"
            placeholder=""
            :value="sectionInfo.timezone"
            @input="sectionInfo.timezone = $event.target.value; updateValues()"
          />
        </div>
      </div>
      <soft-alert
                :color="alertColor"
                :icon="alertIcon + ' ni-lg'"
                dismissible v-if="message"
                >
                  <strong>{{ message }}</strong>
              </soft-alert>
      <div class="button-row d-flex mt-4">
        <soft-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          @click="this.$parent.nextStep"
          :disabled="!allowNext"
        >Next</soft-button>
      </div>
    </div>
  </div>
</template>

<script>
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftAlert from "@/components/SoftAlert.vue";
import { makeRequest } from "@/api";

export default {
  name: "Initialise Connection",
  components: {
    SoftInput,
    SoftButton,
    SoftAlert,
  },
  data() {
    return {
      sectionInfo: {
        url: '',
        name: '',
        tag: '',
        location: '',
        timezone: '',
      },
      message: '',
      alertColor: '',
      alertIcon: '',
      allowNext: false,
    };
  },
  methods: {
    updateValues() {
      this.$emit("values-updated", this.sectionInfo);
    },
    pollSectionDetails() {
      this.message = 'Polling section details...';
      this.alertColor = 'info';
      this.alertIcon = 'ni ni-bell-55';
      makeRequest('get', '/sections/proxy?url=' + this.sectionInfo.url)
        .then((response) => {
          this.message = 'Section details polled successfully';
          this.alertColor = 'success';
          this.alertIcon = 'ni ni-check-bold';
          this.sectionInfo.name = response.data.section_name;
          this.sectionInfo.tag = response.data.section_tag;
          this.sectionInfo.location = response.data.section_location;
          this.sectionInfo.timezone = response.data.section_timezone;
          this.sectionInfo.contact = response.data.section_contact;
          if (response.data.section_name === undefined || response.data.section_name === '') {
            this.message = 'Section details are empty. Please contact the section administrator.';
            this.alertColor = 'warning';
            this.alertIcon = 'ni ni-bell-55';
          } else {
            this.allowNext = true;
          }
          this.updateValues();
        })
        .catch((error) => {
          this.message = 'Failed to poll section details';
          this.alertColor = 'danger';
          this.alertIcon = 'ni ni-fat-remove';
          this.allowNext = false;
        });
    }
  }
};
</script>

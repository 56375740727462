<template>
  <div class="card">
    <div class="p-3 card-body">
      <div class="d-flex">
        <div class="p-2 avatar avatar-xl border-radius-md" :class="active ? 'bg-gradient-primary' : 'bg-gradient-dark'">
          <i class="fas fa-database text-lg me-1" aria-hidden="true"></i>
        </div>
        <div class="my-auto ms-3">
          <h6>{{ title }}<span v-if="shipping" class="badge bg-gradient-info">Shipping</span><span v-if="pickup"
              class="badge bg-gradient-info">Pick-up</span></h6>
          <div class="avatar-group">
            <div class="progress-wrapper" v-if="total > 0">
              <div class="progress-info">
                <div class="progress-percentage">
                  <span class="text-sm font-weight-bold">{{
                    Math.round((used / total) * 100)
                  }}%</span>
                </div>
              </div>
              <div class="progress">
                <div class="progress-bar"
                  :class="(used / total) < 0.5 ? 'bg-gradient-success' : (used / total) < 0.8 ? 'bg-gradient-warning' : 'bg-gradient-danger'"
                  role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"
                  :style="'width: ' + Math.round((used / total) * 100) + '%'"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="ms-auto">

          <div class="dropdown">
            <button id="navbarDropdownMenuLink" class="btn btn-link text-secondary ps-0 pe-2"
              :class="{ show: showMenu }" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
              @click="showMenu = !showMenu">
              <i class="text-lg fa fa-ellipsis-v"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-end me-sm-n4 me-n3" :class="{ show: showMenu }"
              aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item border-radius-md" @click="useFor('shipping', this.id)">Use for shipping</a>
              <a class="dropdown-item border-radius-md" @click="useFor('pickup', this.id)">Use for pickup</a>
              <a class="dropdown-item border-radius-md" @click="useFor('nothing', this.id)">Do not use</a>
              <!-- <a class="dropdown-item border-radius-md">Delete</a> -->
            </div>
          </div>

        </div>
      </div>
      <p class="mt-3 text-sm">
        {{ description }}
      </p>
      <hr class="horizontal dark" />
      <div class="row">
        <div class="col-6">
          <h6 class="mb-0 text-sm">{{ total - used }} left</h6>
          <p class="mb-0 text-sm text-secondary font-weight-bold">
            {{ used }} used
          </p>
        </div>
        <div class="col-6 text-end">
          <h6 class="mb-0 text-sm">{{ dateTime }}</h6>
          <p class="mb-0 text-sm text-secondary font-weight-bold">Created</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { makeRequest } from "@/api";

export default {
  name: "ComplexProjectCard",
  props: {
    id: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    dateTime: {
      type: String,
      default: "",
    },
    total: {
      type: Number,
      default: 0
    },
    used: {
      type: Number,
      default: 0
    },
    shipping: {
      type: Boolean,
      default: false,
    },
    pickup: {
      type: Boolean,
      default: false,
    },
    dropdown: {
      type: Array,
      default: () => [],
      label: String,
      route: String,
    },
  },
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    async useFor(type, stackId) {
      this.$swal({
        title: "Change stack?",
        text: "After changing the stack, the ESNcards will be used for " + type + ".",
        showCancelButton: true,
        confirmButtonText: "Change now",
        cancelButtonText: "Cancel",
        reverseButtons: false,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          makeRequest("PUT", "/office/stacks/" + stackId, {
            shipping: type === "shipping",
            pickup: type === "pickup",
          }).then((successful) => {
            if (successful.data == '1') {
              this.$swal({
                title: "Changed!",
                text: "The stack has been updated.",
                icon: "success",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
              }).then(() => {
                this.$emit("update");
              });

            }
            else {
              this.$swal({
                title: "Error!",
                text: "The stack could not be updated.",
                icon: "error",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
              });
            }
          });
        }
      });
    },
  },

};
</script>

<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12 col-xxl-8 mx-auto d-block">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center" style="height: 100%;">
              <h6 class="mb-0">ESNcard Shop Shipments</h6>
              <button class="btn btn-link text-dark text-sm mb-0 px-2 my-auto ms-auto" @click="downloadAllLabels()"
                v-if="checkedShipments.length > 0">
                <i class="fas fa-file text-lg me-1" aria-hidden="true"></i>
                Labels ({{ checkedShipments.length }})
              </button>
              <button class="btn btn-link text-dark text-sm mb-0 px-0 ms-4 my-auto" @click="downloadAllImages()"
                v-if="checkedShipments.length > 0">
                <i class="fas fa-image text-lg me-1" aria-hidden="true"></i>
                Images ({{ checkedShipments.length }})
              </button>
              <button class="btn btn-sm bg-gradient-primary my-auto ms-4" type="button" name="button"
                @click="shipNowMultiple()" v-if="checkedShipments.length > 0">Mark shipped ({{ checkedShipments.length
                }})</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 col-xxl-8 mx-auto d-block">
        <div class="col-12">
          <div class="mb-4 card">
            <div class="pb-0 card-header d-flex">
              <div>
                <h6>Shipments</h6>
              </div>
              <div class="ms-auto d-flex">
                <input v-model="filterText" class="form-control form-control-sm" placeholder="Filter by name..." />
              </div>
            </div>
            <div class="px-0 pt-0 pb-2 card-body">
              <div class="p-0 table-responsive">
                <table class="table mb-0 align-items-center">
                  <thead>
                    <tr>
                      <th>
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="1" id="checkhead"
                            @change="markAll($event.target.checked)">
                        </div>
                      </th>
                      <th v-for="(header, index) of
                      [...['Country', 'Name'], 'Partner', 'Created at', 'Unit', 'Attachment', 'Actions']" :key="index"
                        class="text-center ps-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        {{ header }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(
                shipment, index
              ) in filteredData" :key="index">
                      <td>
                        <div class="px-3 py-1 d-flex">
                          <div class="d-flex flex-column justify-content-center">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="fcustomCheck1"
                                :checked="checkedShipments.includes(shipment.id)" v-if="shipment.sent_at === null"
                                @click="mark(shipment.id)">
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="px-3 py-1 d-flex">
                          <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-sm">{{ countryCodeToFlag(shipment.country) }}</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p class="text-center mb-0 text-sm font-weight-bold">
                          {{ shipment.first_name }} {{ shipment.last_name }}
                        </p>
                      </td>
                      <td class="text-sm align-middle">
                        <p class="text-center mb-0 text-sm font-weight-bold">
                          NO
                        </p>
                      </td>
                      <td class="text-sm align-middle">
                        <p class="text-center mb-0 text-sm font-weight-bold">
                          {{ formatDateTime(shipment.created_at) }}
                        </p>
                      </td>
                      <td class="text-sm align-middle">
                        <p class="text-center mb-0 text-sm font-weight-bold">
                          {{ shipment.unit }}
                        </p>
                      </td>

                      <td class="text-sm align-middle">
                        <p class="text-center mb-0 text-sm font-weight-bold">
                          <button class="btn btn-link text-dark text-sm mb-0 px-2 ms-4 pb-2 pt-2"
                            @click="download(shipment.address_label, '' + shipment.unit + '-label')"
                            v-if="shipment.address_label">
                            <i class="fas fa-file text-lg me-1" aria-hidden="true"></i>
                            LABEL
                          </button><br>
                          <button class="btn btn-link text-dark text-sm mb-0 px-0 ms-4"
                            @click="download(shipment.image, '' + shipment.unit + '-image')" v-if="shipment.image">
                            <i class="fas fa-image text-lg me-1" aria-hidden="true"></i>
                            IMG
                          </button>
                        </p>
                      </td>
                      <td class="text-sm align-middle">
                        <p class="text-center mb-0 text-sm font-weight-bold">
                          <button class="btn btn-primary text-dark btn-link text-sm mb-0 px-2 mx-4 me-1 pb-2 pt-2"
                            @click="shipNow(shipment.id)" v-if="shipment.sent_at === null">
                            <i class="fas fa-envelope text-lg me-1" aria-hidden="true"></i>
                            Ship now
                          </button>
                          <button class="btn text-dark btn-link text-sm mb-0 px-2 mx-4 me-1 pb-2 pt-2"
                            @click="details(shipment.id)" v-if="shipment.sent_at !== null">
                            <i class="fas fa-envelope text-lg me-1" aria-hidden="true"></i>
                            Shipped
                          </button>
                          <button class="btn btn-secondary text-dark btn-link text-sm mb-0 px-2 ms-1 pb-2 pt-2"
                            @click="edit(shipment.id)">
                            <i class="fas fa-pen text-lg me-1" aria-hidden="true"></i>
                          </button>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="alert alert-info mx-4 mt-4" role="alert" v-if="shipments == null || shipments.length == 0">
                  <strong>So empty here!</strong> There are currently no shipments in the database!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateTime } from "@/dateAndTimeParsing";
import { makeRequest } from "@/api";

export default {
  name: "ESNcardShopShipments",
  components: {
  },
  methods: {
    formatDateTime(dateTime) {
      return formatDateTime(dateTime);
    },
    rowClicked() {
    },
    async fetchShipments() {
      const response = await makeRequest("GET", "/office/shipments");
      this.shipments = response.data;
    },
    async details(shipmentId) {
      this.$swal({
        title: "Details",
        text: "This shipment has been shipped.",
        icon: "info",
        customClass: {
          confirmButton: "btn bg-gradient-success",
        },
        buttonsStyling: false,
      });
    },
    async shipNow(shipmentId) {
      this.$swal({
        title: "Ship now?",
        text: "After shipping the shipment, it will be marked as sent. This action cannot be undone.",
        showCancelButton: true,
        confirmButtonText: "Ship now",
        cancelButtonText: "Cancel",
        reverseButtons: false,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.sendShipment(shipmentId).then((successful) => {
            console.log(successful);
            if (successful.includes(shipmentId)) {
              this.$swal({
                title: "Shipped!",
                text: "The shipment has been marked as sent.",
                icon: "success",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
              });
            }
            else {
              this.$swal({
                title: "Error!",
                text: "The shipment could not be marked as sent.",
                icon: "error",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
              });
            }
          });
        }
      });
    },
    async shipNowMultiple() {
      this.$swal({
        title: "Ship selected orders now?",
        text: "After shipping the shipments, all " + this.checkedShipments.length + " orders will be marked as sent. This action cannot be undone.",
        showCancelButton: true,
        confirmButtonText: "Ship now (" + this.checkedShipments.length + ")",
        cancelButtonText: "Cancel",
        reverseButtons: false,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const list1 = this.checkedShipments;
          this.sendMultipleShipments().then((successful) => {
            const list2 = successful;
            console.log(successful);
            console.log(list1)
            const difference = list1.filter(x => !list2.includes(x)).concat(list2.filter(x => !list1.includes(x)));
            console.log(difference)
            if (difference.length === 0) {
              this.$swal({
                title: "Shipped!",
                text: "All " + successful.length + " shipments have been marked as sent.",
                icon: "success",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
              });
            }
            else {
              this.$swal({
                title: "Error!",
                text: difference.length + " out of " + this.checkedShipments.length + " shipments could not be marked as sent.",
                icon: "warning",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
              });
            }
          });
          this.deselectAll();
        }
      });
    },
    async edit(shipmentId) {
      //implement
    },
    async download(id, fname) {
      const response = await makeRequest("GET", '/' + id + '/download', null, 'application/json', true);
      const responseDetails = await makeRequest("GET", '/' + id + '/details');
      const fileExt = responseDetails.data.mimetype.split('/').pop();
      const urlObject = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = urlObject;
      link.setAttribute("download", fname + '.' + fileExt);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    countryCodeToFlag(countryCode) {
      if (!countryCode) {
        return "";
      }
      return countryCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397));
    },
    async sendShipment(shipmentId) {
      const res = await makeRequest("PUT", `/office/shipments/send`, { shipments: [shipmentId] });
      await this.fetchShipments();
      return res.data.success;
    },
    async sendMultipleShipments() {
      const res = await makeRequest("PUT", `/office/shipments/send`, { shipments: this.checkedShipments });
      await this.fetchShipments();
      return res.data.success;
    },
    markAll(status) {
      if (status) {
        this.checkedShipments = this.shipments.filter((shipment) => shipment.sent_at === null).map((shipment) => shipment.id);
      } else {
        this.checkedShipments = [];
      }
    },
    mark(shipmentId) {
      if (this.checkedShipments.includes(shipmentId)) {
        this.checkedShipments = this.checkedShipments.filter((id) => id !== shipmentId);
      } else {
        this.checkedShipments.push(shipmentId);
      }
    },
    downloadAllImages() {
      this.checkedShipments.forEach((entry) => {
        const filteredShipments = this.shipments.filter(shipment => shipment.id === entry);
        const imageId = filteredShipments[0].image;
        console.log(imageId);
        this.download(imageId, '' + filteredShipments[0].unit + '-image');
      });
    },
    downloadAllLabels() {
      this.checkedShipments.forEach((entry) => {
        const filteredShipments = this.shipments.filter(shipment => shipment.id === entry);
        const labelId = filteredShipments[0].address_label;
        console.log(labelId);
        this.download(labelId, '' + filteredShipments[0].unit + '-label');
      });
    },
    deselectAll() {
      this.checkedShipments = [];
    },
  },
  data() {
    return {
      shipments: [],
      checkedShipments: [],
      filterText: "",
    };
  },
  computed: {
    filteredData() {
      // Filter data based on the entered text
      const searchText = this.filterText.toLowerCase();
      return this.shipments.filter(
        (shipment) =>
          shipment.first_name.toLowerCase().includes(searchText) ||
          shipment.last_name.toLowerCase().includes(searchText)
      );
    },
  },
  created() {
    this.fetchShipments();
  },
};
</script>
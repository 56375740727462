<template>
  <div class="py-4 container-fluid">
    <div class="row">
        <div class="col-12 col-xxl-8 mx-auto d-block">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center" style="height: 100%;">
                  <h6 class="mb-0">Locations</h6>
                  <button class="btn btn-sm bg-gradient-primary my-auto ms-auto" type="button" name="button" @click="$router.push('new')">Add new</button>
                </div>
              </div>
            </div>
        </div>
    </div>
    <div class="mt-4 row">
      <div class="col-12 col-xxl-8 mx-auto d-block">
        <locations-list
          title="Events"
          :headers="headers"
          :lists="locations"
          :show_create=false
          :select_location="void(0)"
          :create_location="void(0)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LocationsList from "@/views/events/components/LocationsList.vue";
import setTooltip from "@/assets/js/tooltip.js";
import { makeRequest } from "@/api";

export default {
  name: "Overview",
  components: {
      LocationsList,
  },
  data() {
    return {
      locations: [],
      headers: {
        name: true,
        description: true,
      },
    };
  },
  methods: {
    async fetchLocations() {
      const response = await makeRequest("GET", "/locations/");
      this.locations = response.data;
    }
  },
  created() {
    this.fetchLocations();
  },
  mounted() {
    setTooltip(this.$store.state.bootstrap);
  }
};
</script>

<template>
  <div id="avatar" class="card mt-4">
    <div class="card-header">
      <h5>Change Profile Image</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-3">
          <div class="image-container">
            <canvas id="avatarCanvas" class="img-fluid border-radius-lg" alt="Avatar preview"></canvas>
          </div>
        </div>
        <div class="col-9"><label for="avatarUpload">Upload Avatar</label>
          <input id="avatarUpload" class="form-control" type="file" accept="image/*" @change="previewFile" />
        </div>
      </div>
      <div class="alert alert-success mt-4" role="alert" v-if="response_banner.color == 'success'">
        <strong>Avatar updated!</strong> All changes were saved successfully.
      </div>
      <div class="alert alert-danger mt-4" role="alert" v-if="response_banner.color == 'danger'">
        <strong>Could not update avatar!</strong> {{ response_banner.message }}
      </div>
      <soft-button class="float-end mt-6 mb-0" color="dark" variant="gradient" size="sm" @click="updateData()">Update
        avatar</soft-button>
    </div>
  </div>
</template>

<script>
import * as Choices from "choices.js";
import SoftButton from "@/components/SoftButton.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftAlert from "@/components/SoftAlert.vue";
import { makeRequest } from "@/api";
import UserAvatar from "@/components/UserAvatar.vue";

export default {
  name: "SideNavItem",
  components: {
    SoftButton,
    // SoftInput,
    // SoftAlert,
  },
  methods: {
    async previewFile(event) {
      const input = event.target;
      if (input.files && input.files[0]) {
        this.$emit('values-updated', input.files[0]);

        const reader = new FileReader();

        reader.onload = (e) => {
          const image = new Image();
          image.src = e.target.result;

          image.onload = () => {
            const canvas = document.getElementById('avatarCanvas');
            const ctx = canvas.getContext('2d');
            const size = Math.min(image.width, image.height);

            canvas.width = size;
            canvas.height = size;

            ctx.drawImage(
              image,
              (image.width - size) / 2,
              (image.height - size) / 2,
              size,
              size,
              0,
              0,
              size,
              size
            );

            canvas.toBlob((blob) => {
              const croppedFile = new File([blob], input.files[0].name, {
                type: input.files[0].type,
              });

              this.img = URL.createObjectURL(croppedFile);
              this.croppedFile = croppedFile;
              //this.$emit('values-updated', croppedFile);
            }, input.files[0].type);
          };
        };

        reader.readAsDataURL(input.files[0]);
      }
    },
    updateData() {
      // Upload the avatar
      if (!this.croppedFile) {
        this.response_banner.color = "danger";
        this.response_banner.message = "Please select an image to upload";
        return;
      }
      const formData = new FormData();
      formData.append("file", this.croppedFile);
      makeRequest("POST", "/upload", formData, "multipart/form-data")
        .then((response) => {
          this.apiData.avatar = response.data.id;
          makeRequest("PUT", "/users/" + this.user.id, this.apiData).then(() => {
            this.response_banner.color = "success";
            this.response_banner.message = "Avatar updated!";
          }).catch((error) => {
            this.response_banner.color = "danger";
            this.response_banner.message = error.data.message;
          });
        })
        .catch((error) => {
          this.response_banner.color = "danger";
          this.response_banner.message = error.data.message;
        });

    },
  },
  data() {
    return {
      croppedFile: null,
      apiData: {},
      message: '',
      alertColor: '',
      alertIcon: '',
      img: '',
      response_banner: {
        title: "",
        message: "",
        color: "",
      }
    };
  },
  props: {
    user: Object,
  },

};
</script>
<style>
.image-container {
  overflow: hidden;
}

#avatarCanvas {
  max-height: 200px;
  width: auto;
  height: auto;
  display: block;
  margin: 0 auto;
}
</style>
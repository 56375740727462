<template>
  {{ message }}
</template>

<script>
import { oidcURL } from "@/api";

export default {
  name: "OIDC",
  components: {
  },
  data() {
    return {
      message: "You will be redirected to the OIDC server for authentication."
    }
  },
  created() {
    const iid = this.$route.query.iid;
    const regex = new RegExp('^[0-9a-zA-Z\-\_]{21}$');

    if (regex.test(iid)) {
      window.location.href = `${oidcURL}/interaction/${iid}/?user=${localStorage.getItem('jwtToken')}`
    } else {
      console.log(iid)
      this.message = "Invalid interaction id."
    }

  },
  beforeUnmount() {
  },
  methods: {

  },
};
</script>

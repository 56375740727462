<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12 col-xxl-8 mx-auto d-block">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center" style="height: 100%;">
              <div>
                <h6 class="mb-0">{{ groupData.displayname }}</h6>
                <p class="mb-0 text-sm">{{ groupData.user_count }} members</p>
              </div>
              <div class="ms-auto d-flex">
                <input v-model="filterText" class="form-control form-control-sm" placeholder="Filter by name..." />
                <button class="btn btn-sm bg-gradient-secondary my-auto p-3 ms-2" type="button" name="button"
                  @click="changeViewMode('grid')">
                  <i class="fas fa-th"></i>
                </button>
                <button class="btn btn-sm bg-gradient-secondary my-auto ms-2 p-3" type="button" name="button"
                  @click="changeViewMode('list')">
                  <i class="fas fa-list"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 col-xxl-8 mx-auto d-block" v-if="this.viewMode === 'grid'">
        <div class="card-container row">
          <ProfileCardGrid v-for="(user, index) in filteredData" :key="index" :user="user" class="col-3-c mb-4" />
        </div>
      </div>
      <div class="col-12 col-xxl-8 mx-auto d-block" v-if="this.viewMode === 'list'">
        <div class="card-container row">
          <ProfileCardList v-for="(user, index) in filteredData" :key="index" :user="user" class="col-6-c" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileCardGrid from "./components/ProfileCardGrid.vue";
import ProfileCardList from "./components/ProfileCardList.vue";
import { makeRequest } from "@/api";

export default {
  name: "TeamView",
  components: {
    ProfileCardGrid,
    ProfileCardList,
  },
  data() {
    return {
      id: this.$route.params.id,
      apiData: [],
      groupData: [],
      viewMode: "list",
      filterText: "",
    };
  },
  computed: {
    filteredData() {
      // Filter data based on the entered text
      const searchText = this.filterText.toLowerCase();
      return this.apiData.filter(
        (user) =>
          user.displayname.toLowerCase().includes(searchText) ||
          user.firstname.toLowerCase().includes(searchText) ||
          user.lastname.toLowerCase().includes(searchText)
      );
    },
  },
  methods: {
    changeViewMode(mode) {
      this.viewMode = mode;
    },
    fetchData() {
      makeRequest("GET", `/groups/${this.id}`).then((response) => {
        this.groupData = response.data;
        makeRequest("GET", `/users/?filter=groups:${this.groupData.displayname}`).then((response) => {
          this.apiData = response.data;
        });
      });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
<style scoped>
.col-3-c {
  width: calc(25% - 2rem);
  margin-right: 1rem;
  margin-left: 1rem;
}

@media (max-width: 768px) {
  .col-3-c {
    width: calc(50% - 2rem);
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

.col-6-c {
  width: calc(50% - 2rem);
  margin-right: 1rem;
  margin-left: 1rem;
}

@media (max-width: 768px) {
  .col-6-c {
    width: calc(100% - 2rem);
    margin-right: 1rem;
    margin-left: 1rem;
  }
}
</style>
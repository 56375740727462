<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <div class="d-flex">
        <h6>{{ title }} <soft-badge :color="badge.color">{{ badge.text }}</soft-badge></h6>
        <div class="ms-auto">
          
           <button
          type="button"
          class="mb-0 btn btn-icon-only btn-rounded btn-sm d-flex align-items-center justify-content-center"
          :class="editMode ? 'btn-outline-primary' : 'btn-outline-secondary'"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Edit items for event">
        <a href="javascript:;" @click="this.toggleEditMode()">
            <i
              class="text-sm fas fa-edit"
              :class="editMode ? 'text-primary' : 'text-secondary'"
            ></i>
          </a>
        </button>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <p class="mb-0 text-center">
        <img alt="Party" class="p-1" :src="Icon_Party" width="50" height="50"/>
        <img alt="" class="p-1" :src="Icon_Crowded" width="50" height="50"/>
        <img alt="" class="p-1" :src="Icon_Drinking" width="50" height="50"/>
        <img alt="" class="p-1" :src="Icon_Loudness" width="50" height="50"/>
<!--             <img alt="" class="p-1" :src="Icon_Accessible" width="50" height="50"/> -->
        <img alt="" class="p-1" :src="Icon_Not_Accessible" width="50" height="50"/>
        <img alt="" class="p-1" :src="Icon_Strobe_Light" width="50" height="50"/>
      </p>
    </div>
  </div>
</template>

<script>
import SoftBadge from "@/components/SoftBadge.vue";

import Icon_Accessible from "../../../assets/img/icons/Accessible-transparent.png";
import Icon_Crowded from "../../../assets/img/icons/Crowded-transparent.png";
import Icon_Drinking from "../../../assets/img/icons/Drinking-transparent.png";
import Icon_Loudness from "../../../assets/img/icons/Loudness-transparent.png";
import Icon_Not_Accessible from "../../../assets/img/icons/Not_Accessible-transparent.png";
import Icon_Party from "../../../assets/img/icons/Party-transparent.png";
import Icon_Strobe_Light from "../../../assets/img/icons/Strobe_Light-transparent.png";

export default {
  name: "SocialInclusionCard",
  components: {
    SoftBadge,
  },
  data() {
    return {
      Icon_Accessible,
      Icon_Crowded,
      Icon_Drinking,
      Icon_Loudness,
      Icon_Not_Accessible,
      Icon_Party,
      Icon_Strobe_Light,
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    badge: {
      type: Object,
      default: () => ({
        color: "success",
        text: "Moderate",
      }),
      text: String,
      color: String,
    },
    description: {
      type: String,
      default: "",
    },
  },
  computed: {
  },
};
</script>

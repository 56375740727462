<template>
  <div class="avatar" :class="getSize(size)">
    <img :src="img" :alt="alt" :class="[getClasses(shadow, circular, borderRadius)]" />
  </div>
</template>

<script>
import { makeRequest } from "@/api";

export default {
  name: "SoftAvatar",
  props: {
    id: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: null,
    },
    shadow: {
      type: String,
      default: null,
    },
    borderRadius: {
      type: String,
      default: null,
    },
    circular: Boolean,
  },
  data() {
    return {
      img: "",
    };
  },
  watch: {
    id: function (newId, oldId) {
      if (newId !== oldId) {
        this.fetchNewImage();
      }
    },
  },
  methods: {
    getSize: (size) => (size ? `avatar-${size}` : null),
    getClasses: (shadow, circular, borderRadius) => {
      let shadowValue, circularValue, borderRadiusValue;

      if (shadow) {
        shadowValue = shadow === "regular" ? "shadow" : `shadow-${shadow}`;
      } else {
        shadowValue = null;
      }

      circularValue = circular ? "rounded-circle" : null;

      borderRadiusValue = borderRadius ? `border-radius-${borderRadius}` : null;

      return `${shadowValue} ${circularValue} ${borderRadiusValue}`;
    },
    fetchNewImage() {
      if (this.id && this.id !== "null") {
        makeRequest("get", `/${this.id}/view`, null, "application/json", "blob")
          .then((response) => {
            this.img = URL.createObjectURL(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
  created() {
    if (this.id) {
      this.fetchNewImage();
    }
  },
};
</script>
<template>
  <div class="card multisteps-form__panel p-3 border-radius-xl bg-white" data-animation="FadeIn">
    <h5 class="font-weight-bolder mb-0">About me</h5>
    <p class="mb-0 text-sm">Mandatory informations</p>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label>First Name</label>
          <soft-input
            id="firstname"
            class="multisteps-form__input"
            type="text"
            placeholder="eg. Michael"
            :value="userInfo.firstname"
            @input="userInfo.firstname = $event.target.value; updateValues()"
          />
        </div>
        <div class="col-12 col-sm-6">
          <label>Last Name</label>
          <soft-input
            id="lastname"
            class="multisteps-form__input"
            type="text"
            placeholder="eg. Prior"
            :value="userInfo.lastname"
            @input="userInfo.lastname = $event.target.value; updateValues()"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <label>Public Name</label>
          <soft-input
            id="displayname"
            class="multisteps-form__input"
            type="text"
            placeholder="eg. Creative Tim"
            :value="userInfo.displayname"
            @input="userInfo.displayname = $event.target.value; this.displayNameDirty = true; updateValues()"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label>Email Address</label>
          <soft-input
            id="email"
            class="multisteps-form__input"
            type="email"
            placeholder="eg. micheal.prior@gmail.com"
            :value="userInfo.email"
            @input="userInfo.email = $event.target.value; updateValues()"
          />
        </div>
        <div class="col-12 col-sm-6">
          <label>Phone Number</label>
          <soft-input
            id="phone"
            class="multisteps-form__input"
            type="tel"
            placeholder="eg. +4317689891776"
            :value="userInfo.phone"
            @input="userInfo.phone = $event.target.value; updateValues()"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label>Password</label>
          <soft-input
            id="password"
            class="multisteps-form__input"
            type="password"
            placeholder="******"
            :value="userInfo.password"
            @input="userInfo.password = $event.target.value; updateValues()"
          />
        </div>
        <div class="col-12 col-sm-6">
          <label>Repeat Password</label>
          <soft-input
            id="confirmPassword"
            class="multisteps-form__input"
            type="password"
            placeholder="******"
            :error=!validPasswords
            :value="userInfo.confirmPassword"
            @input="userInfo.confirmPassword = $event.target.value; updateValues()"
          />
        </div>
      </div>
      <soft-alert
                :color="alertColor"
                :icon="alertIcon + ' ni-lg'"
                dismissible v-if="message"
                >
                  <strong>{{ message }}</strong>
              </soft-alert>
      <div class="button-row d-flex mt-4">
        <soft-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          @click="this.$parent.nextStep"
        >Next</soft-button>
      </div>
    </div>
  </div>
</template>

<script>
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftAlert from "@/components/SoftAlert.vue";

export default {
  name: "UserInfo",
  components: {
    SoftInput,
    SoftButton,
    SoftAlert,
  },
  data() {
    return {
      userInfo: {
        firstname: '',
        lastname: '',
        displayname: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
      },
      message: '',
      alertColor: '',
      alertIcon: '',
      validPasswords: true,
      displayNameDirty: false,
    };
  },
  methods: {
    updateValues() {
        if (!this.displayNameDirty) {
          this.userInfo.displayname = this.userInfo.firstname;
        }

        if (this.userInfo.confirmPassword.length > 0) {
          if (this.userInfo.password !== this.userInfo.confirmPassword) {
          this.message = 'Passwords do not match';
          this.alertColor = 'warning';
          this.alertIcon = 'ni ni-fat-remove';
          this.validPasswords = false;
        } else {
          this.message = '';
          this.alertColor = '';
          this.alertIcon = '';
        }
      }
      this.$emit("values-updated", this.userInfo);
    }
  }
};
</script>

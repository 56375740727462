<template>
  <div id="notifications" class="card mt-4">
    <div class="card-header">
      <h5>Notifications</h5>
      <p class="text-sm">
        Choose how you receive notifications. These notification settings apply
        to the things you’re watching.
      </p>
    </div>
    <div class="card-body pt-0">
      <div class="table-responsive">
        <table class="table mb-0">
          <thead>
            <tr>
              <th class="ps-1" colspan="4">
                <p class="mb-0">Activity</p>
              </th>
              <th class="text-center">
                <p class="mb-0">Email</p>
              </th>
              <th class="text-center">
                <p class="mb-0">Push</p>
              </th>
              <th class="text-center">
                <p class="mb-0">SMS</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Mentions</span>
                  <span class="text-xs font-weight-normal">Notify when another user mentions you in a comment</span>
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="mentionsEmail" name="mentionsEmail" checked />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="mentionsPush" name="mentionsPush" />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="mentionsSms" name="mentionsSms" />
                </div>
              </td>
            </tr>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Comments</span>
                  <span class="text-xs font-weight-normal">Notify when another user comments your item.</span>
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="commentsEmail" name="commentsEmail" checked />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="commentsPush" name="commentsPush" checked />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="commentsSms" name="commentsSms" />
                </div>
              </td>
            </tr>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Follows</span>
                  <span class="text-xs font-weight-normal">Notify when another user follows you.</span>
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="followsEmail" name="followsEmail" checked />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="followsEmail" name="followsPush" checked />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="followsEmail" name="followsSms" checked />
                </div>
              </td>
            </tr>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <p class="text-sm mb-0">Log in from a new device</p>
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="deviceEmail" name="deviceEmail" checked />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="devicePush" name="devicePush" checked />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="deviceSms" name="deviceSms" checked />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import * as Choices from "choices.js";
import SoftButton from "@/components/SoftButton.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftBadge from "@/components/SoftBadge.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftInput from "@/components/SoftInput.vue";
import { makeRequest } from "@/api";

export default {
  name: "SideNavItem",
  components: {
    SoftSwitch,
  },
  methods: {
    async getSelf() {
      try {
        const response = await makeRequest("GET", "/users/me");
        this.apiData = response.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  data() {
    return {
      apiData: [],
    };
  },
  mounted() {
    if (document.getElementById("choices-gender")) {
      var gender = document.getElementById("choices-gender");
      new Choices(gender);
      this.getSelf();
    }

    if (document.getElementById("choices-language")) {
      var language = document.getElementById("choices-language");
      new Choices(language);
    }

    if (document.getElementById("choices-month")) {
      var month = document.getElementById("choices-month");
      setTimeout(function () {
        new Choices(month);
      }, 1);

      // eslint-disable-next-line no-unused-vars
      var d = new Date();
      var monthArray = new Array();
      monthArray[0] = "January";
      monthArray[1] = "February";
      monthArray[2] = "March";
      monthArray[3] = "April";
      monthArray[4] = "May";
      monthArray[5] = "June";
      monthArray[6] = "July";
      monthArray[7] = "August";
      monthArray[8] = "September";
      monthArray[9] = "October";
      monthArray[10] = "November";
      monthArray[11] = "December";
      for (let m = 0; m <= 11; m++) {
        var optn = document.createElement("OPTION");
        optn.text = monthArray[m];
        // server side month start from one\
        optn.value = m + 1;
        // if june selected
        if (m == 1) {
          optn.selected = true;
        }
        month.options.add(optn);
      }
    }

    if (document.getElementById("choices-day")) {
      var day = document.getElementById("choices-day");
      setTimeout(function () {
        new Choices(day);
      }, 1);

      for (let y = 1; y <= 31; y++) {
        var optn1 = document.createElement("OPTION");
        optn1.text = y;
        optn1.value = y;

        if (y == 1) {
          optn1.selected = true;
        }

        day.options.add(optn1);
      }
    }

    if (document.getElementById("choices-year")) {
      var year = document.getElementById("choices-year");
      setTimeout(function () {
        new Choices(year);
      }, 1);

      for (let y = 1900; y <= 2020; y++) {
        var optn2 = document.createElement("OPTION");
        optn2.text = y;
        optn2.value = y;

        if (y == 2020) {
          optn.selected = true;
        }

        year.options.add(optn2);
      }
    }

    if (document.getElementById("choices-skills")) {
      var skills = document.getElementById("choices-skills");
      new Choices(skills, {
        delimiter: ",",
        editItems: true,
        maxItemCount: 5,
        removeItemButton: true,
        addItems: true,
      });
    }
  },
};
</script>

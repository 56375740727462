<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-lg-8 mx-auto">
        <div class="card mb-4">
          <div class="card-header p-3 pb-0">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h6>Statement of cost details</h6>
                <p class="text-sm mb-0">
                  Created by
                  <b>{{ apiData.created_by?.displayname }}</b> on
                  <b>{{ apiData.created_at ? formatDateTime(apiData.created_at) : '' }}</b>
                </p>
                <p class="text-sm">

                </p>
              </div>
              <div>
                <soft-button color="danger" variant="gradient" class="ms-auto mb-0" @click="deleteStatement()"
                  v-if="apiData.status === 'pending'">DELETE</soft-button> <soft-button color="danger"
                  variant="gradient" class="ms-auto mb-0" @click="rejectStatement()"
                  v-if="apiData.status === 'pending'">REJECT STATEMENT</soft-button>
                <soft-button color="success" variant="gradient" class="ms-1 mb-0" @click="acceptStatement()"
                  v-if="apiData.status === 'pending'">APPROVE STATEMENT</soft-button>
                <soft-button color="primary" variant="gradient" class="ms-auto mb-0" @click="createInvoice()"
                  v-if="apiData.status === 'accepted'">CREATE INVOICE</soft-button>
                <soft-button color="primary" variant="gradient" class="ms-auto mb-0" @click="copyAndModify()"
                  v-if="apiData.status === 'rejected'">COPY AND MODIFY</soft-button>
              </div>
            </div>
          </div>
          <div class="card-body p-3 pt-0">
            <hr class="horizontal dark mt-0 mb-4" />
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12">
                <h4 class="text-dark">{{ apiData.name }}</h4>
                {{ apiData.statement }}
                <br><br>
                <blockquote class="blockquote text-white mb-0" v-if="apiData.comment && apiData.comment.length > 0">
                  <p class="text-dark ms-3">{{ apiData.comment }}</p>
                  <footer class="blockquote-footer text-gradient text-info text-sm ms-3">{{
                    apiData.evaluated_by?.displayname }}</footer>
                </blockquote>
                <div class="alert alert-success mt-4" role="alert" v-if="apiData.status === 'accepted'">
                  <span><strong>Approved</strong> This statement of cost has been approved by {{
                    apiData.evaluated_by?.displayname }}</span>
                </div>
                <div class="alert alert-warning mt-4" role="alert" v-if="apiData.status === 'pending'">
                  <span><strong>Pending</strong> This statement of cost has not been approved yet</span>
                </div>
                <div class="alert alert-danger mt-4" role="alert" v-if="apiData.status === 'rejected'">
                  <span><strong>Rejected</strong> This statement of cost has been rejected by {{
                    apiData.evaluated_by?.displayname }}</span>
                </div>
              </div>
            </div>
            <hr class="horizontal dark mt-4 mb-4" />
            <div class="row">
              <div class="col-lg-3 col-md-6 col-12">
                <h6 class="mb-3">Track order</h6>
                <div class="timeline timeline-one-side">
                  <div class="timeline-block mb-3">
                    <span class="timeline-step">
                      <i class="ni ni-single-copy-04 text-secondary"></i>
                    </span>
                    <div class="timeline-content">
                      <h6 class="text-dark text-sm font-weight-bold mb-0">
                        Statement created
                      </h6>
                      <p class="text-secondary font-weight-bold text-xs mt-1 mb-0">
                        22 DEC 7:20 AM
                      </p>
                    </div>
                  </div>
                  <div class="timeline-block mb-3">
                    <span class="timeline-step">
                      <i class="ni ni-send text-secondary"></i>
                    </span>
                    <div class="timeline-content">
                      <h6 class="text-dark text-sm font-weight-bold mb-0">
                        Statement submitted
                      </h6>
                      <p class="text-secondary font-weight-bold text-xs mt-1 mb-0">
                        22 DEC 7:21 AM
                      </p>
                    </div>
                  </div>
                  <div class="timeline-block mb-3">
                    <span class="timeline-step">
                      <i class="ni ni-single-02 text-secondary"></i>
                    </span>
                    <div class="timeline-content">
                      <h6 class="text-dark text-sm font-weight-bold mb-0" data-bs-toggle="tooltip"
                        data-bs-placement="top" title="You">
                        Statement re-evaluated
                      </h6>
                      <p class="text-secondary font-weight-bold text-xs mt-1 mb-0">
                        22 DEC 4:54 PM
                      </p>
                    </div>
                  </div>
                  <div class="timeline-block mb-3">
                    <span class="timeline-step">
                      <i class="ni ni-fat-delete text-danger"></i>
                    </span>
                    <div class="timeline-content">
                      <h6 class="text-dark text-sm font-weight-bold mb-0">
                        Statement rejected
                      </h6>
                      <p class="text-secondary font-weight-bold text-xs mt-1 mb-0">
                        22 DEC 8:10 AM
                      </p>
                    </div>
                  </div>
                  <div class="timeline-block mb-3">
                    <span class="timeline-step">
                      <i class="ni ni-check-bold text-success text-gradient"></i>
                    </span>
                    <div class="timeline-content">
                      <h6 class="text-dark text-sm font-weight-bold mb-0">
                        Statement approved
                      </h6>
                      <p class="text-secondary font-weight-bold text-xs mt-1 mb-0">
                        22 DEC 4:54 PM
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6 col-12">
                <file-attachments></file-attachments>
                <hr>
                <h6 class="mb-3">Invoices</h6>
                add invoices here
              </div>
              <div class="col-lg-3 col-12 ms-auto">
                <div class="row">
                  <h6 class="mb-3">Planned expense</h6>
                  <div class="d-flex justify-content-between mt-4">
                    <span class="mb-2 text-lg">Total:</span>
                    <span class="text-dark text-lg ms-2 font-weight-bold">€ {{ ("" + (apiData.amount /
                    100)).replace('.', ',')
                      }}</span>
                  </div>
                </div>
                <div class="row mt-4" v-if="apiData.event_id">
                  <h6 class="mb-3">For Event</h6>
                  <DefaultEventCard :id="apiData.event_id" :fullWidth="true"></DefaultEventCard>
                  <div class="d-flex justify-content-between">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import FileAttachments from "@/components/FileAttachments.vue";
import DefaultEventCard from "../profile/components/DefaultEventCard.vue";
import { formatDateTime } from "@/dateAndTimeParsing.js";
import { makeRequest } from "@/api.js";
//import SoftBadge from "@/components/SoftBadge.vue";

export default {
  name: "StatementOfCostDetails",
  components: {
    SoftButton,
    //SoftBadge,
    FileAttachments,
    DefaultEventCard,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      apiData: {},
    };
  },
  methods: {
    formatDateTime(dateTime) {
      return formatDateTime(dateTime);
    },
    async getStatementOfCost() {
      try {
        const response = await makeRequest("GET", `/statements-of-cost/${this.id}`);
        this.apiData = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async deleteStatement() {
      try {
        await makeRequest("DELETE", `/statements-of-cost/${this.id}`);
        this.$router.push("/statements-of-cost");
      } catch (error) {
        console.log(error);
      }
    },
    async rejectStatement() {
      try {
        this.$swal({
          title: "Rejecting statement of cost over " + this.apiData.amount / 100 + " €",
          text: "Please provide a reason here:",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Reject",
          showLoaderOnConfirm: true,
          customClass: {
            confirmButton: "btn bg-gradient-info",
            cancelButton: "btn bg-gradient-secondary ms-2",
          },
          buttonsStyling: false,
          allowOutsideClick: () => !this.$swal.isLoading(),
        }).then((result) => {
          if (result.isConfirmed) {
            makeRequest("PUT", `/statements-of-cost/${this.id}/evaluate`, {
              status: "rejected",
              comment: result.value,
            }).then(() => {
              this.getStatementOfCost();
              this.$swal({
                title: "Success!",
                text: "Statement of cost has been rejected",
                icon: "info",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
              });
            });
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    async acceptStatement() {
      try {
        this.$swal({
          title: "Accepting statement of cost over " + this.apiData.amount / 100 + " €",
          text: "You can enter an optional comment here:",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Approve",
          showLoaderOnConfirm: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
          allowOutsideClick: () => !this.$swal.isLoading(),
        }).then((result) => {
          if (result.isConfirmed) {
            makeRequest("PUT", `/statements-of-cost/${this.id}/evaluate`, {
              status: "accepted",
              comment: result.value,
            }).then(() => {
              this.getStatementOfCost();
              this.$swal({
                title: "Success!",
                text: "Statement of cost has been accepted",
                icon: "success",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
              });
            });
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    async createInvoice() {
      try {
        this.$router.push({
          name: "NewInvoice",
          query: {
            event_id: this.apiData.event_id,
            statement_of_cost_id: this.id,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getStatementOfCost();
  },
};
</script>

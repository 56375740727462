<template>
  <div class="container-fluid col-12 col-md-12 col-xl-8">
    <div class="mt-4 page-header min-height-300 border-radius-xl" :style="{
      backgroundImage:
        'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
      backgroundPositionY: '50%'
    }">
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">{{ apiData.name }}</h5>
            <p class="mb-0 text-sm font-weight-bold">Public Event</p>
          </div>
        </div>
        <div class="mx-auto mt-3 col-lg-5 col-md-6 my-sm-auto ms-sm-auto me-sm-0">
          <div class="wrapper text-end">
            <img alt="Party" class="p-1" :src="Icon_Party" width="50" height="50" />
            <img alt="" class="p-1" :src="Icon_Crowded" width="50" height="50" />
            <img alt="" class="p-1" :src="Icon_Drinking" width="50" height="50" />
            <img alt="" class="p-1" :src="Icon_Loudness" width="50" height="50" />
            <!--             <img alt="" class="p-1" :src="Icon_Accessible" width="50" height="50"/> -->
            <img alt="" class="p-1" :src="Icon_Not_Accessible" width="50" height="50" />
            <img alt="" class="p-1" :src="Icon_Strobe_Light" width="50" height="50" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-4 container-fluid col-12 col-md-12 col-xl-8">
    <div class="mt-4 row">
      <div class="mt-4 col-12 col-md-6 col-xl-8 mt-md-0">
        <event-info-card title="Event Details" :description=apiData.description
          :presale_starts_at=apiData.presale_starts_at :presale_ends_at=apiData.presale_ends_at
          :cancellation_details=apiData.cancellation_details />
        <div class="overflow-scroll card mt-4">
          <div class="card-header pb-0">
            <h6>Organisers</h6> <!-- TODO: put this directly under event details in column -->
          </div>
          <div class="card-body d-flex">
            <OrganiserList v-for="({ displayname, avatar, as }, index) of apiData.organisers" :key="index"
              :image="avatar" :name="displayname" :role="as" />
          </div>
        </div>
        <div class="card mt-4">
          <div class="card-header pb-0">
            <h6>Tickets</h6>
          </div>
          <div class="card-body d-flex">
            <pretix-widget event="https://pretix.dev.julab.me/esnmpdev/8jb9r/"></pretix-widget>
            <noscript>
              <div class="pretix-widget">
                <div class="pretix-widget-info-message">
                  JavaScript is disabled in your browser. To access our ticket shop without JavaScript, please <a
                    target="_blank" rel="noopener" href="https://pretix.dev.julab.me/esnmpdev/8jb9r/">click here</a>.
                </div>
              </div>
            </noscript>
          </div>
        </div>
      </div>
      <div class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
        <div class="mb-4 card">
          <div class="p-3 pb-0 card-header">
            <h6 class="mb-0">Date and Time</h6>
          </div>
          <div class="p-3 card-body">
            <ul class="list-group">
              <li class="pt-0 text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Start:</strong> &nbsp;
                {{ formatTimestamp(apiData.starts_at, true) }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">End:</strong> &nbsp; {{ formatTimestamp(apiData.ends_at, false) || "open end"
                }}
              </li>
              <hr v-if="presale_starts_at || presale_ends_at">
              <li class="text-sm border-0 list-group-item ps-0" v-if="presale_starts_at">
                <strong class="text-dark">Presale starts:</strong> &nbsp; Tuesday, 12/12/2023, 2 hours and 17 minutes{{
                  formatTimestamp(presale_starts_at) }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0" v-if="presale_ends_at">
                <strong class="text-dark">Presale ends:</strong> &nbsp; Tuesday, 12/12/2023, 2 hours and 17 minutes{{
                presale_ends_at }}

              </li>
              <li class="pb-0 text-sm border-0 list-group-item ps-0" v-if="cancellation_details">
                <strong class="text-sm text-dark">Cancellation Details:</strong> &nbsp; {{ cancellation_details }}
              </li>
            </ul>
          </div>
        </div>
        <div class="mb-4 card">
          <div class="p-3 pb-0 card-header">
            <h6 class="mb-0">Meeting Point</h6>
          </div>
          <div class="p-3 card-body"><iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21282.194003324534!2d16.3506368!3d48.18206719999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476da823da14afdb%3A0x7857288d43752863!2sMargareteng%C3%BCrtel!5e0!3m2!1sde!2sat!4v1702408870818!5m2!1sde!2sat"
              width="100%" height="300" style="border:0;" allowfullscreen="" loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"></iframe></div>

          <div class="p-3 pb-0 card-header">
            <h6 class="mb-0">Main Location</h6>
          </div>
          <div class="p-3 card-body">{{ apiData.locations[0].name }} </div>
        </div>
        <div class="mb-4 card">
          <div class="p-3 pb-0 card-header">
            <h6 class="mb-0">Weather information</h6>
          </div>
          <div class="p-3 card-body">Add weather (two columns if meeting point and main location are set)</div>
        </div>
        <div class="mb-4 card">
          <div class="p-3 pb-0 card-header">
            <h6 class="mb-0">Section</h6>
          </div>
          <div class="p-3 card-body">[add image of section]</div>
        </div>
      </div>

    </div>
    <div class="mt-4 row">
      <div class="col-12 col-lg-12">

      </div>

    </div>
    <div class="mt-4 row">
      <div class="col-12 col-lg-12">


      </div>
    </div>

  </div>
</template>
<!--
<style>
@import 'https://pretix.dev.julab.me/esnmpdev/8jb9r/widget/v1.css';
</style>
-->

<script>
import kal from "@/assets/img/kal-visuals-square.jpg";
import marie from "@/assets/img/marie.jpg";
import ivana from "@/assets/img/ivana-square.jpg";
import bruce from "../../assets/img/bruce-mars.jpg";
import emma from "../../assets/img/ivana-squares.jpg";
import nick from "@/assets/img/team-3.jpg";
import team1 from "../../assets/img/team-1.jpg";
import team2 from "../../assets/img/team-2.jpg";
import team3 from "../../assets/img/team-3.jpg";
import team4 from "../../assets/img/team-4.jpg";
import team5 from "../../assets/img/team-5.jpg";
import Icon_Accessible from "../../assets/img/icons/Accessible-transparent.png";
import Icon_Crowded from "../../assets/img/icons/Crowded-transparent.png";
import Icon_Drinking from "../../assets/img/icons/Drinking-transparent.png";
import Icon_Loudness from "../../assets/img/icons/Loudness-transparent.png";
import Icon_Not_Accessible from "../../assets/img/icons/Not_Accessible-transparent.png";
import Icon_Party from "../../assets/img/icons/Party-transparent.png";
import Icon_Strobe_Light from "../../assets/img/icons/Strobe_Light-transparent.png";
import EventInfoCard from "./components/EventInfoCard.vue";
import slackLogo from "../../assets/img/small-logos/logo-slack.svg";
import invisionLogo from "../../assets/img/small-logos/logo-invision.svg";
import OrganiserList from "./components/OrganiserList.vue";


import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";

import { makeRequest } from '@/api';

export default {
  name: "DetailsEventPublic",
  components: {
    OrganiserList,
    EventInfoCard,
  },
  data() {
    return {
      apiData: [],
      showMenu: false,
      stories: [
        {
          name: "Abbie W",
          image: team1
        },
        {
          name: "Boris U",
          image: team2
        },
        {
          name: "Kay R",
          image: team3
        },
        {
          name: "Tom M",
          image: team4
        },
      ],
      nick,
      slackLogo,
      invisionLogo,
      kal,
      marie,
      ivana,
      bruce,
      emma,
      team1,
      team2,
      team3,
      team4,
      team5,
      Icon_Accessible,
      Icon_Crowded,
      Icon_Drinking,
      Icon_Loudness,
      Icon_Not_Accessible,
      Icon_Party,
      Icon_Strobe_Light,
    };
  },
  mounted() {
    this.$store.state.showSidenav = false;
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = false;
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    this.getEvent();
    let pretixScript = document.createElement('script')
    pretixScript.setAttribute('src', 'https://pretix.dev.julab.me/widget/v1.en.js')
    document.head.appendChild(pretixScript)
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    async getEvent() {
      try {
        const response = await makeRequest("GET", "/events/00000000-0000-0000-0004-000000000001");
        console.log(response);
        this.apiData = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    formatTimestamp(timestamp, extended = false) {
      const eventDate = new Date(timestamp);
      const currentDate = new Date();

      const daysDifference = Math.floor((eventDate - currentDate) / (24 * 60 * 60 * 1000));
      const hoursDifference = Math.floor((eventDate - currentDate) / (60 * 60 * 1000)) % 24;
      const minutesDifference = Math.floor((eventDate - currentDate) / (60 * 1000)) % 60;

      const dayOfWeek = eventDate.toLocaleDateString('en-AT', { weekday: 'long' });
      const formattedDate = eventDate.toLocaleDateString('en-AT', { month: 'numeric', day: 'numeric', year: 'numeric' });
      const formattedTime = eventDate.toLocaleTimeString('en-AT', { hour: 'numeric', minute: 'numeric' });

      let output = `${dayOfWeek}, ${formattedDate}, from ${formattedTime}`
      if (extended) output += ` (in ${daysDifference} days, ${hoursDifference} hours and ${minutesDifference} minutes)`;

      return output;
    },
  },
};
</script>

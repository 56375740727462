<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-5">
          <div class="row">
            <div class="col-12 col-lg-8 mx-auto my-5">
              <div class="multisteps-form__progress">
                <button
                  class="multisteps-form__progress-btn js-active"
                  type="button"
                  title="User Info"
                  :class="activeStep >= 0 ? activeClass : ''"
                  @click="activeStep = 0"
                >
                  <span>User Info</span>
                </button>
                <button
                  class="multisteps-form__progress-btn"
                  type="button"
                  title="Avatar"
                  :class="activeStep >= 1 ? activeClass : ''"
                  @click="activeStep = 1"
                >
                  Avatar
                </button>
                <button
                  class="multisteps-form__progress-btn"
                  type="button"
                  title="Role"
                  :class="activeStep >= 2 ? activeClass : ''"
                  @click="activeStep = 2"
                >
                  Groups
                </button>
              </div>
            </div>
          </div>
          <!--form panels-->
          <div class="row">
            <div class="col-12 col-lg-8 m-auto">
              <form class="multisteps-form__form mb-5">
                <!--single form panel-->
                <user-info :class="activeStep === 0 ? activeClass : ''" @values-updated="this.handleUserInfoUpdated" />
                <!--single form panel-->
                <Avatar :class="activeStep === 1 ? activeClass : ''" @values-updated="this.handleAvatarUpdated"/>
                <!--single form panel-->
                <div
                  class="card multisteps-form__panel p-3 border-radius-xl bg-white" :class="activeStep === 2 ? activeClass : ''"
                  data-animation="FadeIn">
                  <h5 class="font-weight-bolder">Groups</h5>
                  <Groups @values-updated="this.handleGroupsUpdate" />
                  <div class="button-row d-flex mt-4">
                    <soft-button
                      type="button"
                      color="light"
                      variant="gradient"
                      class="js-btn-prev"
                      @click="this.prevStep"
                    >Prev</soft-button>
                    <soft-button
                      type="button"
                      color="dark"
                      variant="gradient"
                      class="ms-auto js-btn-next"
                      @click="this.nextStep"
                    >Submit</soft-button>
                </div>
              </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserInfo from "./components/UserInfo.vue";
import Avatar from "./components/Avatar.vue";
import Groups from "../groups/components/GroupSelector.vue";
import SoftButton from "@/components/SoftButton.vue";
import { makeRequest } from "@/api";

export default {
  name: "New User",
  components: {
    UserInfo,
    Avatar,
    Groups,
    SoftButton,
  },
  data() {
    return {
      userInfo: {},
      avatar: {},
      groups: [],
      showMenu: false,
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 2, // Adjusted to reflect the current number of steps
      message: '',
      alertColor: '',
      alertIcon: '',
    };
  },
  methods: {
    nextStep() {
      if (this.activeStep < this.formSteps) {
        this.activeStep += 1;
      } else {
        console.log("here")
        if (this.userInfo.password !== this.userInfo.confirmPassword) {
          this.$swal({
          icon: "warning",
          title: "Cannot create user!",
          text: "Passwords do not match!",
          type: "warning-message",
        });
          return;
        }
        delete this.userInfo.confirmPassword;

        // Upload the avatar
        const formData = new FormData();
        formData.append("file", this.avatar);
        makeRequest("POST", "/upload", formData, "multipart/form-data")
          .then((response) => {
            console.log(response)
            this.userInfo.avatar = response.data.id;
            this.createUser();
          })
          .catch((error) => {
            this.$swal({
              icon: "error",
              title: "Cannot create user!",
              text: "Your picture could not be uploaded. Please try again!",
              type: "error-message",
            });
          });

        }
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep -= 1;
      }
    },
    handleUserInfoUpdated(value) {
      this.userInfo = value;
    },
    handleAvatarUpdated(value) {
      this.avatar = value;
    },
    handleGroupsUpdate(value) {
      this.groups = value;
    },
    createUser() {
      const user = {
        ...this.userInfo,
        groups: this.groups,
      };
      makeRequest("POST", "/users", user)
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "User created!",
            text: "User has been created successfully!",
            type: "success-message",
          }).then(() => {
            this.$router.push("/admin/users/list");
          });
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Cannot create user!",
            text: error.response.data.message || "An error occurred while creating the user!",
            type: "error-message",
          });
        });
    },
  },
};
</script>

<template>
  <div class="container-fluid col-xxl-8">
    <div class="mt-4 page-header min-height-300 border-radius-xl" :style="{
      backgroundImage:
        'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
      backgroundPositionY: '50%'
    }">
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <UserAvatar :id="apiData.avatar" alt="profile_image" border-radius="lg" shadow="sm" size="xl" />
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">{{ apiData.displayname }}</h5>
            <p class="mb-0 text-sm font-weight-bold">{{ apiData.firstname }} {{ apiData.lastname }}</p>
          </div>
        </div>
        <div class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0">
          <div class="nav-wrapper position-relative end-0">
            <!--- end of bar for achievements -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-4 container-fluid col-xxl-8">
    <div class="mt-3 row">
      <div class="mt-4 col-12 col-md-6 col-xl-8 mt-md-0">
        <profile-info-card title="Profile Information" description="<add description>" :info="{
      fullName: apiData.firstname + ' ' + apiData.lastname,
      mobile: apiData.phone,
      email: apiData.email,
      languages: '<add languages>'
    }" />
      </div>
      <div class=" col-12 col-md-6 col-xl-4" v-if="false">
        <div class="card h-100">
          <div class="p-3 pb-0 card-header">
            <h6 class="mb-0">User Statistics (for Treasurer, etc. only)</h6>
          </div>
          <div class="p-3 card-body">
            <h6 class="text-xs text-uppercase text-body font-weight-bolder">
              Account
            </h6>
            <ul class="list-group">
              <li class="px-0 border-0 list-group-item">
                <soft-switch id="flexSwitchCheckDefault" name="email" class="ps-0 ms-auto"
                  label-class="mb-0 text-body ms-3 text-truncate w-80" checked>Email me when someone follows
                  me</soft-switch>
              </li>
              <li class="px-0 border-0 list-group-item">
                <soft-switch id="flexSwitchCheckDefault1" name="Email"
                  label-class="mb-0 text-body ms-3 text-truncate w-80" class="ps-0 ms-auto">Email me when someone
                  answers on my post</soft-switch>
              </li>

              <li class="px-0 border-0 list-group-item">
                <soft-switch id="flexSwitchCheckDefault2" name="Email"
                  label-class="mb-0 text-body ms-3 text-truncate w-80" class="ps-0 ms-auto" checked>Email me when
                  someone mentions me</soft-switch>
              </li>
            </ul>
            <h6 class="mt-4 text-xs text-uppercase text-body font-weight-bolder">
              Application
            </h6>
            <ul class="list-group">
              <li class="px-0 border-0 list-group-item">
                <soft-switch id="flexSwitchCheckDefault3" name="Project Launch" class="ps-0 ms-auto"
                  label-class="mb-0 text-body ms-3 text-truncate w-80">New launches and projects</soft-switch>
              </li>
              <li class="px-0 border-0 list-group-item">
                <soft-switch id="flexSwitchCheckDefault4" name="Product Update" class="ps-0 ms-auto"
                  label-class="mb-0 text-body ms-3 text-truncate w-80" checked>Monthly product updates</soft-switch>
              </li>
              <li class="px-0 pb-0 border-0 list-group-item">
                <soft-switch id="flexSwitchCheckDefault5" name="Newsletter" class="ps-0 ms-auto"
                  label-class="mb-0 text-body ms-3 text-truncate w-80">Subscribe to newsletter</soft-switch>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mt-4 col-12 col-md-6 col-xl-4 mt-xl-0">
        <div class="card h-100">
          <div class="p-3 pb-0 card-header">
            <h6 class="mb-0">Groups</h6>
          </div>
          <div class="p-3 card-body">
            <ul class="list-group">
              <li v-for="( group, index ) of  apiData.groups " :key="index" @click="$router.push('/teams/' + group.id)"
                class="mb-2 border-0 list-group-item d-flex justify-content-between border-radius-lg ps-0 cursor-pointer">
                <div class="d-flex align-items-center">
                  <div class="d-flex flex-column">
                    <h6 class="mb-1 text-sm text-dark">{{ group.displayname }}</h6>
                    <!-- eslint-disable vue/no-v-html -->
                    <span class="text-xs">since {{ group.since.substring(0, 10) }}</span>
                  </div>
                </div>
                <div class="d-flex">
                  <button class="my-auto btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right">
                    <i class="ni ni-bold-right" aria-hidden="true"></i>
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-12">
        <div class="mb-4 card">
          <div class="p-3 pb-0 card-header">
            <h6 class="mb-1">Events</h6>
            <p class="text-sm">Events organised by {{ apiData.displayname }}</p>
          </div>
          <div class="p-3 card-body">
            <div class="row">
              <DefaultEventCard v-for="( event, index ) in  eventData " :key="index" :id="event.id" :title="event.name"
                :image="img1" label="Project #2"
                description="As Uber works through a huge amount of internal management turmoil." />
              <div class="mb-4 col-xl-3 col-md-6 mb-xl-0">
                <place-holder-card :title="{ text: 'Plan with ' + apiData.displayname, variant: 'h5' }" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftSwitch from "@/components/SoftSwitch.vue";
import ProfileInfoCard from "./components/ProfileInfoCard.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import DefaultEventCard from "./components/DefaultEventCard.vue";
import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { formatDate } from "@/dateAndTimeParsing";

import { makeRequest } from "@/api";

export default {
  name: "ProfileOverview",
  components: {
    SoftSwitch,
    ProfileInfoCard,
    UserAvatar,
    DefaultEventCard,
    PlaceHolderCard
  },
  data() {
    return {
      showMenu: false,
    };
  },
  data() {
    return {
      id: this.$route.params.id,
      apiData: [],
      eventData: [],
      profileImage: "",
    };
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    getProfileInfo() {
      makeRequest("GET", `/users/${this.id}`)
        .then((response) => {
          this.apiData = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getEvents() {
      makeRequest("GET", `/events?filter=user_id:${this.id}`)
        .then((response) => {
          this.eventData = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.getProfileInfo();
    this.getEvents();
  }
};
</script>./components/DefaultEventCard.vue

<template>
  <div class="mb-4 card">
    <div class="pb-0 card-header">
      <h6>Events</h6>
    </div>
    <div class="px-0 pt-0 pb-2 card-body">
      <div class="p-0 table-responsive">
        <table class="table mb-0 align-items-center">
          <thead>
            <tr>
              <th v-for="(header, index) of headers" :key="index" :class="index >= 1 ? 'text-center ps-2' : ''"
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(
                  { id, name, starts_at, ends_at, max_participants, status }, index
                ) in lists" :key="index" class="cursor-pointer" @click="rowClicked(id)">
              <td>
                <div class="px-3 py-1 d-flex">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ name }}</h6>
                    <p class="mb-0 text-sm font-weight-bold text-secondary">{{ starts_at }} - {{ ends_at }}</p>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-center mb-0 text-sm font-weight-bold">
                  {{ max_participants }}
                </p>
              </td>
              <td class="text-sm align-middle">
                <p class="text-center mb-0 text-sm font-weight-bold">
                  <span class="badge bg-gradient-success" v-if="status === 'accepted'">ACCEPTED</span>
                  <span class="badge bg-gradient-warning" v-if="status === 'pending'">PENDING</span>
                  <span class="badge bg-gradient-danger" v-if="status === 'rejected'">REJECTED</span>
                  <span class="badge bg-gradient-primary" v-if="status === 'published'">PUBLISHED</span>
                  <span class="badge bg-gradient-secondary" v-if="status === 'created'">DRAFT</span>
                  <span class="badge bg-gradient-secondary" v-if="status === 'cancelled'">CANCELLED</span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventsList",
  components: {
  },
  methods: {
    rowClicked(id) {
      this.$router.push({ name: 'Event', params: { id: id } });
    }
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    lists: {
      type: Array,
      default: () => [],
      id: String,
      name: String,
      starts_at: String,
      ends_at: String,
      max_participants: Number,
      status: String,
    },
  },
};
</script>
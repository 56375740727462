<template>
  <router-link class="nav-link" :to="to">
    <div
      class="text-center bg-white shadow icon icon-shape icon-sm border-radius-md d-flex align-items-center justify-content-center me-2">
      <slot name="icon"></slot>
    </div>
    <span class="nav-link-text ms-1">{{ text }}</span>
    <span v-if="badgeNumber" class="badge">{{ badgeNumber }}</span>
  </router-link>

</template>
<script>
export default {
  name: "SidenavMainItem",
  props: {
    to: {
      type: [Object, String],
      required: true,
    },
    miniIcon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    badgeNumber: {
      type: Number,
      default: null,
    },
  },
};
</script>
<style>
.badge {
  background-color: rgb(0, 153, 255);
  color: white;
  border-radius: 75%;
  padding: 2px 8px;
  font-size: 12px;
  margin-left: 10px;
  /* Additional styling as needed */
}
</style>

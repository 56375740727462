<template>
  <div class="text-center col-lg-1 col-md-2 col-sm-3 col-4">
    <a href="javascript:;" class="border avatar avatar-lg rounded-circle"
      :class="role == 'mainoc' ? 'border-primary' : ''">
      <img :alt="name" class="p-1" :src="image" />
    </a>
    <p class="mb-0 text-sm">{{ name }}</p>
  </div>
</template>

<script>
import { makeRequest } from "@/api";

export default {
  name: "OrganiserList",
  props: {
    id: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    role: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      image: "",
    };
  },
  methods: {
    fetchNewImage() {
      makeRequest("get", `/${this.id}/view`, null, "application/json", "blob")
        .then((response) => {
          this.image = URL.createObjectURL(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  created() {
    if (this.id) {
      this.fetchNewImage();
    }
  },
};
</script>

<template>
  <div class="card multisteps-form__panel p-3 border-radius-xl bg-white" data-animation="FadeIn">
    <h5 class="font-weight-bolder mb-0">Social Inclusion</h5>
    <div class="multisteps-form__content mt-3">
      <div class="mx-auto col-lg-9 col-12">
        <div class="mt-4 row">

          <div class="col-sm-4 ms-auto">
            <input id="btncheck6" type="checkbox" class="btn-check" @click="toggleParty()" />
            <label class="px-4 py-3 border-2 btn btn-lg btn-outline-secondary d-flex align-items-center"
              for="btncheck6">
              <img src="@/assets/img/icons/Party-dark.png" contain width="50" height="50" />
              <div class="ms-2">Party</div>
            </label>
          </div>
          <div class="col-sm-8" v-if="social_inclusion_info.party">
            The event is a party or has a party-like atmosphere, which includes loud music, dancing, and drinking.
          </div>
          <div class="col-sm-8" v-if="!social_inclusion_info.party">
            The event is not a party or has a party-like atmosphere.
          </div>
        </div>

        <div class="mt-4 row">
          <div class="col-sm-4">
            <input id="btncheck2" type="checkbox" class="btn-check" @click="toggleCrowded()" />
            <label class="px-4 py-3 border-2 btn btn-lg btn-outline-secondary d-flex align-items-center"
              for="btncheck2">
              <img src="@/assets/img/icons/Crowded-dark.png" contain width="50" height="50" />
              <div class="ms-2">Crowded</div>
            </label>
          </div>
          <div class="col-sm-8" v-if="social_inclusion_info.crowded">
            Your event is exptected to have a high number of participants in a limited space.
          </div>
          <div class="col-sm-8" v-if="!social_inclusion_info.crowded">
            Your event is does either have not many participants or takes place in an open or wide area or with plenty
            of space for each person.
          </div>
        </div>
        <div class="mt-4 row">
          <div class="col-sm-4 me-auto">
            <input id="btncheck3" type="checkbox" class="btn-check" @click="toggleDrinking()" />
            <label class="px-4 py-3 border-2 btn btn-lg btn-outline-secondary d-flex align-items-center"
              for="btncheck3">
              <img src="@/assets/img/icons/Drinking-dark.png" contain width="50" height="50" />
              <div class="ms-2">Drinking</div>
            </label>
          </div>
          <div class="col-sm-8" v-if="social_inclusion_info.drinking">
            Alcohol is served or being consumed at the venue/occasion. There are alcohol-free alternatives available
            (mandatory).
          </div>
          <div class="col-sm-8" v-if="!social_inclusion_info.drinking">
            The event is alcohol-free.
          </div>
        </div>
        <div class="mt-4 row">
          <div class="col-sm-4 ms-auto">
            <input id="btncheck4" type="checkbox" class="btn-check" @click="toggleLoudness()" />
            <label class="px-4 py-3 border-2 btn btn-lg btn-outline-secondary d-flex align-items-center"
              for="btncheck4">
              <img src="@/assets/img/icons/Loudness-dark.png" contain width="50" height="50" />
              <div class="ms-2">Loudness</div>
            </label>
          </div>
          <div class="col-sm-8" v-if="social_inclusion_info.loudness">
            Loud music or noise is expected at the event, which might be disturbing for some people.
          </div>
          <div class="col-sm-8" v-if="!social_inclusion_info.loudness">
            The event is quiet or has a moderate noise level.
          </div>
        </div>
        <div class="mt-4 row">
          <div class="col-sm-4">
            <input id="btncheck1" type="checkbox" class="btn-check" @click="toggleAccessible()" />
            <label class="px-4 py-3 border-2 btn btn-lg btn-outline-secondary d-flex align-items-center"
              for="btncheck1">
              <img src="@/assets/img/icons/Not_Accessible-dark.png" contain width="50" height="50"
                v-if="!social_inclusion_info.accessible" />
              <img src="@/assets/img/icons/Accessible-dark.png" contain width="50" height="50"
                v-if="social_inclusion_info.accessible" />
              <div class="ms-2">Accessible</div>
            </label>
          </div>
          <div class="col-sm-8" v-if="social_inclusion_info.accessible">
            The event is accessible for people with disabilities. There are no barriers for people with disabilities.
          </div>
          <div class="col-sm-8" v-if="!social_inclusion_info.accessible">
            The event is not accessible for people with disabilities.
          </div>
        </div>


        <div class="mt-4 row">
          <div class="col-sm-4 ms-auto">
            <input id="btncheck7" type="checkbox" class="btn-check" @click="toggleStrobeLight()" />
            <label class="px-4 py-3 border-2 btn btn-lg btn-outline-secondary d-flex align-items-center"
              for="btncheck7">
              <img src="@/assets/img/icons/Strobe_Light-dark.png" contain width="50" height="50" />
              <div class="ms-2">Strobe Light</div>
            </label>
          </div>
          <div class="col-sm-8" v-if="social_inclusion_info.strobe_light">
            Strobe lights are used at the event, which might be disturbing for some people or cause health issues under
            certain conditions.
          </div>
          <div class="col-sm-8" v-if="!social_inclusion_info.strobe_light">
            Strobe lights and flashing lights are not used at the event.
          </div>
        </div>
        <div class="button-row d-flex mt-4">
          <soft-button type="button" color="light" variant="gradient" class="js-btn-prev"
            @click="this.$parent.prevStep">Prev</soft-button>
          <soft-button type="button" color="dark" variant="gradient" class="ms-auto" @click="submit">Send</soft-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
//import SoftTextarea from "@/components/SoftTextarea.vue";

export default {
  name: "SocialInclusion",
  components: {
    //    SoftInput,
    SoftButton,
    //    SoftTextarea,
  },
  data() {
    return {
      social_inclusion_info: {
        accessible: false,
        crowded: false,
        drinking: false,
        loudness: false,
        party: false,
        strobe_light: false,
      }
    }
  },
  methods: {
    updateValues() {
      this.$emit("values-updated", this.social_inclusion_info);
    },
    async toggleAccessible() {
      this.social_inclusion_info.accessible = !this.social_inclusion_info.accessible;
      this.updateValues();
    },
    async toggleCrowded() {
      this.social_inclusion_info.crowded = !this.social_inclusion_info.crowded;
      this.updateValues();
    },
    async toggleDrinking() {
      this.social_inclusion_info.drinking = !this.social_inclusion_info.drinking;
      this.updateValues();
    },
    async toggleLoudness() {
      this.social_inclusion_info.loudness = !this.social_inclusion_info.loudness;
      this.updateValues();
    },
    async toggleParty() {
      this.social_inclusion_info.party = !this.social_inclusion_info.party;
      this.updateValues();
    },
    async toggleStrobeLight() {
      this.social_inclusion_info.strobe_light = !this.social_inclusion_info.strobe_light;
      this.updateValues();
    },
    async submit() {
      this.$emit("submit");
    }
  }
};
</script>

<template>
  <div id="password" class="card mt-4">
    <div class="card-header">
      <h5>Change Password</h5>
    </div>
    <div class="card-body pt-0">
      <label class="form-label">Current password</label>
      <soft-input id="currentPassword" type="password" placeholder="Current Password" :value="apiData.old_password"
        @input="apiData.old_password = $event.target.value" />
      <label class="form-label">New password</label>
      <soft-input id="newPassword" type="password" placeholder="New Password" :value="apiData.new_password"
        @input="apiData.new_password = $event.target.value; updateValues()" />
      <label class="form-label">Confirm new password</label>
      <soft-input id="confirmPassword" type="password" placeholder="Confirm password" :value="apiData.confirm_password"
        @input="apiData.confirm_password = $event.target.value; updateValues()" />

      <soft-alert :color="alertColor" :icon="alertIcon + ' ni-lg'" dismissible v-if="message">
        <strong>{{ message }}</strong>
      </soft-alert>
      <h5 class="mt-5">Password requirements</h5>
      <p class="text-muted mb-2">
        Please follow this guide for a strong password:
      </p>
      <ul class="text-muted ps-4 mb-0 float-start">
        <li>
          <span class="text-sm">Min 8 characters</span>
        </li>
        <li>
          <span class="text-sm">Min one special character</span>
        </li>
        <li>
          <span class="text-sm">Min one number</span>
        </li>
        <li>
          <span class="text-sm">Change it frequently</span>
        </li>
      </ul>
      <soft-button class="float-end mt-6 mb-0" color="dark" variant="gradient" size="sm" @click="this.changePassword()"
        :disabled="this.alertColor == 'warning' || this.alertColor == 'success'">Update password</soft-button>
    </div>
  </div>
</template>

<script>
import * as Choices from "choices.js";
import SoftButton from "@/components/SoftButton.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftAlert from "@/components/SoftAlert.vue";
import { makeRequest } from "@/api";

export default {
  name: "SideNavItem",
  components: {
    SoftButton,
    SoftInput,
    SoftAlert,
  },
  methods: {
    async changePassword() {
      try {
        const data = {
          old_password: this.apiData.old_password,
          new_password: this.apiData.new_password,
        };
        const response = await makeRequest("PUT", "/users/" + this.user_id + "/password", data);
        this.message = 'Password successfully changed';
        this.alertColor = 'success';
        this.alertIcon = 'ni ni-fat-remove';
        this.validPasswords = false;
        console.log(response);
      } catch (error) {
        this.message = 'Error: ' + error.response.data.message;
        this.alertColor = 'danger';
        this.alertIcon = 'ni ni-fat-remove';
        console.log(error);
      }
    },
    updateValues() {
      if (this.apiData.confirm_password.length > 0) {
        if (this.apiData.new_password !== this.apiData.confirm_password) {
          this.message = 'Passwords do not match';
          this.alertColor = 'warning';
          this.alertIcon = 'ni ni-fat-remove';
          this.validPasswords = false;
        } else {
          this.message = '';
          this.alertColor = '';
          this.alertIcon = '';
        }
      }
    }
  },
  data() {
    return {
      apiData: {
        old_password: "",
        new_password: "",
        confirm_password: "",
      },
      message: '',
      alertColor: '',
      alertIcon: '',
      validPasswords: true,
    };
  },
  props: {
    user_id: String,
  },

};
</script>

<template>
  <div id="basic-info" class="card mt-4">
    <div class="card-header">
      <h5>Basic Info</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-6">
          <label class="form-label">First Name</label>
          <soft-input id="firstName" type="text" :value="user.firstname"
            @input="apiData.firstname = $event.target.value;" disabled />
        </div>
        <div class="col-6">
          <label class="form-label">Last Name</label>
          <soft-input id="lastName" type="text" :value="user.lastname" @input="apiData.lastname = $event.target.value;"
            disabled />
        </div>
      </div>
      <!--
      <div class="row">
        <div class="col-sm-4 col-6">
          <label class="form-label mt-2">I'm</label>
          <select id="choices-gender" class="form-control" name="choices-gender">
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
        <div class="col-sm-8">
          <div class="row">
            <div class="col-sm-5 col-5">
              <label class="form-label mt-2">Birth Date</label>
              <select id="choices-day" class="form-control" name="choices-day"></select>
            </div>
            <div class="col-sm-4 col-3">
              <label class="form-label mt-2">&nbsp;</label>
              <select id="choices-month" class="form-control" name="choices-month"></select>
            </div>
            <div class="col-sm-3 col-4">
              <label class="form-label mt-2">&nbsp;</label>
              <select id="choices-year" class="form-control" name="choices-year"></select>
            </div>
          </div>
        </div>
      </div>
      -->
      <div class="row">
        <div class="col-6">
          <label class="form-label mt-2">Public Name</label>
          <soft-input id="displayname" type="email" :value="user.displayname"
            @input="apiData.displayname = $event.target.value;" />
        </div>
        <div class="col-6">
          <label class="form-label mt-2">Email (Can only be changed by board)</label>
          <soft-input id="email" type="email" :value="user.email" @input="apiData.email = $event.target.value;" />
        </div>
      </div>
      <div class="row">
        <!--
        <div class="col-6">
          <label class="form-label mt-2">Languages</label>
          <select id="choices-language" class="form-control" name="choices-language">
            <option value="English">English</option>
            <option value="French">French</option>
            <option value="Spanish">Spanish</option>
          </select>
        </div> -->
        <div class="col-6">
          <label class="form-label mt-2">Phone Number</label>
          <soft-input id="phoneNumber" type="phone" :value="user.phone" @input="apiData.phone = $event.target.value;" />
        </div>
      </div>
      <div class="alert alert-success mt-4" role="alert" v-if="response_banner.color == 'success'">
        <strong>Basic Info updated!</strong> All changes were saved successfully.
      </div>
      <div class="alert alert-danger mt-4" role="alert" v-if="response_banner.color == 'danger'">
        <strong>Could not update information!</strong> {{ response_banner.message }}
      </div>
      <soft-button class="float-end mt-6 mb-0" color="dark" variant="gradient" size="sm" @click="updateData()">Save
        changes</soft-button>
    </div>
  </div>
</template>

<script>
import * as Choices from "choices.js";
import SoftButton from "@/components/SoftButton.vue";
import SoftInput from "@/components/SoftInput.vue";
import { makeRequest } from "@/api";

export default {
  name: "SideNavItem",
  components: {
    SoftButton,
    SoftInput,
  },
  methods: {

  },
  data() {
    return {
      apiData: {},
      response_banner: {
        title: "",
        message: "",
        color: "",
      }
    };
  },
  methods: {
    updateData() {
      makeRequest("PUT", "/users/" + this.user.id, this.apiData).then(() => {
        this.response_banner.color = "success";
        this.response_banner.message = "Basic Info updated!";
      }).catch((error) => {
        this.response_banner.color = "danger";
        this.response_banner.message = error.data.message;
      });
    },
  },
  mounted() {
    if (document.getElementById("choices-gender")) {
      var gender = document.getElementById("choices-gender");
      new Choices(gender);
    }

    if (document.getElementById("choices-language")) {
      var language = document.getElementById("choices-language");
      new Choices(language);
    }

    if (document.getElementById("choices-month")) {
      var month = document.getElementById("choices-month");
      setTimeout(function () {
        new Choices(month);
      }, 1);

      // eslint-disable-next-line no-unused-vars
      var d = new Date();
      var monthArray = new Array();
      monthArray[0] = "January";
      monthArray[1] = "February";
      monthArray[2] = "March";
      monthArray[3] = "April";
      monthArray[4] = "May";
      monthArray[5] = "June";
      monthArray[6] = "July";
      monthArray[7] = "August";
      monthArray[8] = "September";
      monthArray[9] = "October";
      monthArray[10] = "November";
      monthArray[11] = "December";
      for (let m = 0; m <= 11; m++) {
        var optn = document.createElement("OPTION");
        optn.text = monthArray[m];
        // server side month start from one\
        optn.value = m + 1;
        // if june selected
        if (m == 1) {
          optn.selected = true;
        }
        month.options.add(optn);
      }
    }

    if (document.getElementById("choices-day")) {
      var day = document.getElementById("choices-day");
      setTimeout(function () {
        new Choices(day);
      }, 1);

      for (let y = 1; y <= 31; y++) {
        var optn1 = document.createElement("OPTION");
        optn1.text = y;
        optn1.value = y;

        if (y == 1) {
          optn1.selected = true;
        }

        day.options.add(optn1);
      }
    }

    if (document.getElementById("choices-year")) {
      var year = document.getElementById("choices-year");
      setTimeout(function () {
        new Choices(year);
      }, 1);

      for (let y = 1900; y <= 2020; y++) {
        var optn2 = document.createElement("OPTION");
        optn2.text = y;
        optn2.value = y;

        if (y == 2020) {
          optn.selected = true;
        }

        year.options.add(optn2);
      }
    }

    if (document.getElementById("choices-skills")) {
      var skills = document.getElementById("choices-skills");
      new Choices(skills, {
        delimiter: ",",
        editItems: true,
        maxItemCount: 5,
        removeItemButton: true,
        addItems: true,
      });
    }
  },
  props: {
    user: Object,
  },
};
</script>

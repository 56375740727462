<template>
  <h6 class="mb-0">Locations</h6>
  <p class="mb-0 text-sm">Edit Locations for event</p>
  <hr class="my-3 horizontal dark" />
  <div class="row">
    <div class="form-group">
      <input type="text" class="form-control" aria-label="Search for users" aria-describedby="inputGroup-sizing-default"
        v-model="searchQuery" @input="search" placeholder="Search for location">
    </div>
    <div class="col mb-4">
      <p class="mb-2 text-sm">Search in known locations</p>
      <ul class="list-group">
        <li class="px-3 mb-2 list-group-item d-flex align-items-center" v-if="currentSearch.length === 0">
          <i>Type in the search bar for locations to appear</i>
        </li>
        <li class="px-3 mb-2 border-3 list-group-item d-flex align-items-center"
          v-for="(location, index) of currentSearch" :key="index">
          <div class="d-flex align-items-start flex-column justify-content-center">
            <h6 class="mb-0 text-sm">{{ location.name }}</h6>
            <p class="mb-0 text-xs">{{ location.street }}, {{ location.zipcode }} {{ location.city }}, {{
          location.country }}</p>
          </div>
          <div class="d-flex align-items-center ms-auto">
            <a class="mb-0 btn btn-success" :class="{ 'btn-sm ps-3 pe-3': isSmallScreen }" href="javascript:;"
              v-if="!currentLocations.some(selected_location => selected_location.id === location.id)"
              @click="currentLocations.push(location); details(location); handleChange()">{{ isSmallScreen ? '+' : 'Add'
              }}</a>
            <a class="mb-0 btn btn-link pe-3 ps-0" :class="{ 'btn-sm ps-3': isSmallScreen }" href="javascript:;"
              v-if="currentLocations.some(selected_location => selected_location.id === location.id)">
              {{ isSmallScreen ? 'Added' : 'Already added' }}</a>
          </div>
        </li>
      </ul>
    </div>
    <div class="col">
      <p class="mb-2 text-sm">Selected locations</p>
      <ul class="list-group">
        <li class="px-3 mb-2 border-3 list-group-item d-flex align-items-center"
          :class="location.meeting_point == true ? 'border-primary' : ''" v-for="(location, index) of currentLocations"
          :key="index">
          <div class="d-flex align-items-start flex-column justify-content-center">
            <h6 class="mb-0 text-sm">{{ location.name }}</h6>
            <p class="mb-0 text-xs mt-1">
              <span class="badge bg-gradient-primary me-1" v-if="location.meeting_point">MP</span>
              <span class="badge bg-gradient-secondary me-1" v-if="location.main_location">ML</span>
              {{ trimText(location.location_details) }}
            </p>
          </div>
          <div class="d-flex align-items-center ms-auto">
            <div class="d-flex flex-column">
              <a class="mb-0 btn btn-sm btn-info ms-2" :class="{ 'btn-sm ps-3 pe-3 btn-inner--icon': isSmallScreen }"
                href="javascript:;" @click="details(location)">
                <i class="ni ni-bold-up" v-if="isSmallScreen"></i>
                {{ !isSmallScreen ? 'Details' : '' }}
              </a>
              <a class="mb-0 btn btn-sm btn-danger ms-2 mt-2" :class="{ 'btn-sm ps-3 pe-3': isSmallScreen }"
                href="javascript:;"
                @click="this.currentLocations.splice(this.currentLocations.findIndex(plocation => plocation.id == location.id), 1); handleChange()">
                {{ isSmallScreen ? 'X' : 'Remove' }}
              </a>
            </div>
          </div>

        </li>
      </ul>
    </div>

  </div>
  <div class="row mx-1">
    <soft-alert :color="alertColor" :icon="alertIcon + ' ni-lg'" v-if="message">
      <strong>{{ message }}</strong>
    </soft-alert>
  </div>
</template>

<script>
import SoftAlert from "@/components/SoftAlert.vue";
import { makeRequest } from "@/api";

export default {
  name: "LocationsSelect",
  components: {
    SoftAlert,
  },
  mounted() {
    console.log(this.id)
    this.getLocations();
    this.getCurrentLocations();
    this.windowWidth = window.innerWidth;

    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  },
  data() {
    return {
      windowWidth: 0,
      message: '',
      alertColor: '',
      alertIcon: '',
      currentLocations: [],
      fullView: false,
      currentSearch: [],
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    trimText(text) {
      if (text && text.length > 15) {
        return text.substring(0, 15) + "...";
      }
      return text;
    },
    cancel() {
      this.$router.go(-1);
    },
    search() {
      if (this.searchQuery.length > 2) {
        this.getLocations(this.searchQuery);
      } else {
        this.currentSearch = [];
      }
    },
    async getLocations(query) {
      const response = await makeRequest("GET", "/locations/lookup?query=" + query + "&limit=8");
      this.currentSearch = response.data;
    },
    async getCurrentLocations() {
      if (this.id === null) return;
      try {
        const response = await makeRequest("GET", `/events/${this.id}/locations`);
        this.currentLocations = response.data;
        this.handleChange();
      } catch (error) {
        console.log(error);
      }
    },
    details(location) {
      this.$swal({
        icon: "info",
        title: "<strong>" + location.name + "</strong>",
        html:
          'Address: <br />' + location.street + '<br />' + location.zipcode + ' ' + location.city + '<br />' + location.country + '<br /><hr>' +
          'Meeting Point: <input type="checkbox" id="checkbox1"' + (location.meeting_point ? ' checked="true"' : ' ') + '" /><br />' +
          'Main Location: <input type="checkbox" id="checkbox2"' + (location.main_location ? ' checked="true"' : ' ') + '" /><br /><hr>' +
          'Details for participants:<br><input type="text" id="textInput" value="' + (location.location_details ? location.location_details : '') + '" />',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Save changes',
        confirmButtonAriaLabel: "Thumbs up, great!",
        cancelButtonText: 'Cancel',
        cancelButtonAriaLabel: "Thumbs down",
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const checkbox1 = document.getElementById("checkbox1").checked;
          const checkbox2 = document.getElementById("checkbox2").checked;
          const textInputValue = document.getElementById("textInput").value;

          location.meeting_point = checkbox1;
          location.main_location = checkbox2;
          location.location_details = textInputValue;
          this.handleChange();
        }
      });

    },
    handleChange() {
      this.$emit('values-updated', this.currentLocations);
    }
  },
  computed: {
    isSmallScreen() {
      return this.windowWidth < 768;
    }
  },
};
</script>